import ExcelJs from 'exceljs';
import saveAs from 'file-saver';
import _ from 'lodash';

import { capitalize, columnAutoWidth, computeHousehold4TierType, urlToImageFile } from '../other';
import { normalizeAccountExport } from './normalizeAccountExport';
import { normalizeExecutiveExport } from './normalizeExecutiveExport';
import { normalizeMedicalExport } from '../medical';
import { normalizeDentalExport } from '../dental';
import { normalizeVisionExport } from '../vision';
import { normalizeLifeExport } from '../life';
import { normalizeDisabilityExport } from '../disability';
import { normalizeAccidentExport } from '../accident';
import { normalizeHospitalExport } from '../hospital';
import { normalizeCriticalExport } from '../critical';

export const onDownloadBrokerRequestedProposalsXlsx = async (
  accountData,
  proposalData,
  brokerData,
  members,
  rfpQuotes,
) => {
  const medicalQuotes = rfpQuotes?.medical;
  const dentalQuotes = rfpQuotes?.dental;
  const visionQuotes = rfpQuotes?.vision;
  const lifeQuotes = rfpQuotes?.life;
  const disabilityQuotes = rfpQuotes?.disability;
  const accidentQuotes = rfpQuotes?.accident;
  const hospitalQuotes = rfpQuotes?.hospital;
  const criticalQuotes = rfpQuotes?.critical;

  const quoteNames = {
    medicalQuotes,
    dentalQuotes,
    visionQuotes,
    lifeQuotes,
    disabilityQuotes,
    accidentQuotes,
    hospitalQuotes,
    criticalQuotes,
  };

  let summaryQuoteNames = {};

  for (const quoteName in quoteNames) {
    const updatedName = capitalize(quoteName.replace('Quotes', ''));

    let counter = 1;
    for (const quoteId in quoteNames[quoteName]) {
      summaryQuoteNames = {
        ...summaryQuoteNames,
        [quoteId]: `${updatedName} RFP Quote ${counter++}`,
      };
    }
  }

  let executiveSummary = {};

  for (const quoteName in quoteNames) {
    const updatedName = quoteName.replace('Quotes', '');
    const executiveSummaryPayload = [];
    for (const quoteId in quoteNames[quoteName]) {
      for (const quote of quoteNames[quoteName][quoteId]) {
        const payload = {
          ...quote,
          config_id: quoteId,
          status: 'selected',
          name: 'Broker',
        };
        executiveSummaryPayload.push(payload);
      }
    }
    executiveSummary = {
      ...executiveSummary,
      [updatedName]: executiveSummaryPayload,
    };
  }

  const ExcelJSWorkbook = new ExcelJs.Workbook();

  const accountWorkSheet = ExcelJSWorkbook.addWorksheet('Account', {
    properties: { defaultColWidth: 40 },
  });

  normalizeAccountExport(accountWorkSheet, accountData, brokerData);

  const image_file = await urlToImageFile(accountData.logo_url);
  const image_id = ExcelJSWorkbook.addImage({
    base64: image_file,
    extension: 'jpeg',
  });

  accountWorkSheet.spliceRows(1, 0, []);

  accountWorkSheet.addImage(image_id, {
    tl: { col: 0, row: 0 },
    ext: { width: 128, height: 128 },
  });

  accountWorkSheet.getRow(1).height = 128;

  const groupMembersWorkSheet = ExcelJSWorkbook.addWorksheet('Group Members', {
    properties: { defaultColWidth: 20 },
  });

  groupMembersWorkSheet.addRow([
    'Employee ID',
    'First Name',
    'Last Name',
    'Gender',
    'Relationship',
    'Zip Code',
    'Date of Birth',
    'Medical enrollment',
    'Current Medical Plan',
    'Dental enrollment',
    'Current Dental',
    'Viison enrollment',
    'Current Vision',
    'Current Life',
    'Tobacco',
    'Salary',
    'Job',
  ]).font = { bold: true };

  let householdRoles = {};

  for (const member of members) {
    if (householdRoles[member.family_id]) {
      householdRoles[member.family_id].push(member.relationship);
    } else {
      householdRoles[member.family_id] = [member.relationship];
    }
  }
  const household4TierTypes = {};

  for (const eid in householdRoles) {
    const roles = householdRoles[eid];
    household4TierTypes[eid] = computeHousehold4TierType(roles);
  }

  for (const member of members) {
    const payload = [
      member?.family_id,
      member?.first_name,
      member?.last_name,
      member?.gender,
      member?.relationship,
      member?.zip_code,
      member?.date_of_birth,
      member?.relationship === 'employee' && member?.current_medical_plan_name
        ? household4TierTypes[member?.family_id]?.type
        : '',
      member?.current_medical_plan_name,
      member?.relationship === 'employee' && member?.dental_plan_name
        ? household4TierTypes[member?.family_id]?.type
        : '',
      member?.dental_plan_name,
      member?.relationship === 'employee' && member?.vision_plan_name
        ? household4TierTypes[member?.family_id]?.type
        : '',
      member?.vision_plan_name,
      member?.life_benefit_amount,
      member?.is_tobacco_user ? 'yes' : 'no',
      member?.salary_amount,
      member?.job_title,
    ];

    groupMembersWorkSheet.addRow(payload);
  }

  normalizeExecutiveExport(
    ExcelJSWorkbook,
    executiveSummary,
    members,
    summaryQuoteNames,
    'Proposals Summary',
    'approved',
  );

  normalizeMedicalExport(ExcelJSWorkbook, medicalQuotes, members, 'all');
  normalizeDentalExport(ExcelJSWorkbook, dentalQuotes, members, 'all');
  normalizeVisionExport(ExcelJSWorkbook, visionQuotes, members, 'all');
  normalizeLifeExport(ExcelJSWorkbook, lifeQuotes, members, 'all');
  normalizeDisabilityExport(ExcelJSWorkbook, disabilityQuotes, members, 'all');
  normalizeAccidentExport(ExcelJSWorkbook, accidentQuotes, members, 'all');
  normalizeHospitalExport(ExcelJSWorkbook, hospitalQuotes, members, 'all');
  normalizeCriticalExport(ExcelJSWorkbook, criticalQuotes, members, 'all');

  for (let rowIndex = 1; rowIndex <= accountWorkSheet.rowCount; rowIndex++) {
    accountWorkSheet.getRow(rowIndex).alignment = { vertical: 'top', wrapText: true };
  }

  for (let rowIndex = 1; rowIndex <= groupMembersWorkSheet.rowCount; rowIndex++) {
    groupMembersWorkSheet.getRow(rowIndex).alignment = { vertical: 'top', wrapText: true };
  }

  ExcelJSWorkbook.xlsx.writeBuffer().then((buffer) => {
    saveAs(
      new Blob([buffer], { type: 'application/octet-stream' }),
      `${accountData?.company_name}-${proposalData?.name}-Broker Requsted RFPs.xlsx`,
    );
  });
};

export const onDownloadCarrierProposalXlsx = async (
  accountData,
  proposalData,
  brokerData,
  members,
  rfpQuotes,
) => {
  const groupMembers = members;

  const medicalQuotes = rfpQuotes?.medical;
  const dentalQuotes = rfpQuotes?.dental;
  const visionQuotes = rfpQuotes?.vision;
  const lifeQuotes = rfpQuotes?.life;
  const disabilityQuotes = rfpQuotes?.disability;
  const accidentQuotes = rfpQuotes?.accident;
  const hospitalQuotes = rfpQuotes?.hospital;
  const criticalQuotes = rfpQuotes?.critical;

  const ExcelJSWorkbook = new ExcelJs.Workbook();
  const accountWorkSheet = ExcelJSWorkbook.addWorksheet('Account', {
    properties: { defaultColWidth: 40 },
  });
  const groupMembersWorkSheet = ExcelJSWorkbook.addWorksheet('Group Members', {
    properties: { defaultColWidth: 20 },
  });

  normalizeAccountExport(accountWorkSheet, accountData, brokerData);

  const image_file = await urlToImageFile(accountData.logo_url);
  const image_id = ExcelJSWorkbook.addImage({
    base64: image_file,
    extension: 'jpeg',
  });

  accountWorkSheet.spliceRows(1, 0, []);

  accountWorkSheet.addImage(image_id, {
    tl: { col: 0, row: 0 },
    ext: { width: 128, height: 128 },
  });

  accountWorkSheet.getRow(1).height = 128;

  groupMembersWorkSheet.addRow([
    'Employee ID',
    'First Name',
    'Last Name',
    'Gender',
    'Relationship',
    'Zip Code',
    'Date of Birth',
    'Medical enrollment',
    'Current Medical Plan',
    'Dental enrollment',
    'Current Dental',
    'Vision enrollment',
    'Current Vision',
    'Current Life',
    'Tobacco',
    'Salary',
    'Job',
  ]).font = { bold: true };

  let householdRoles = {};

  for (const member of members) {
    if (householdRoles[member.family_id]) {
      householdRoles[member.family_id].push(member.relationship);
    } else {
      householdRoles[member.family_id] = [member.relationship];
    }
  }
  const household4TierTypes = {};

  for (const eid in householdRoles) {
    const roles = householdRoles[eid];
    household4TierTypes[eid] = computeHousehold4TierType(roles);
  }

  for (const member of groupMembers) {
    const payload = [
      member?.family_id,
      member?.first_name,
      member?.last_name,
      member?.gender,
      member?.relationship,
      member?.zip_code,
      member?.date_of_birth,
      member?.relationship === 'employee' && member?.current_medical_plan_name
        ? household4TierTypes[member?.family_id]?.type
        : '',
      member?.current_medical_plan_name,
      member?.relationship === 'employee' && member?.dental_plan_name
        ? household4TierTypes[member?.family_id]?.type
        : '',
      member?.dental_plan_name,
      member?.relationship === 'employee' && member?.vision_plan_name
        ? household4TierTypes[member?.family_id]?.type
        : '',
      member?.vision_plan_name,
      member?.life_benefit_amount,
      member?.is_tobacco_user ? 'yes' : 'no',
      member?.salary_amount,
      member?.job_title,
    ];

    groupMembersWorkSheet.addRow(payload);
  }

  for (let columnIndex = 2; columnIndex <= groupMembersWorkSheet.columnCount; columnIndex++) {
    groupMembersWorkSheet.getColumn(columnIndex).numFmt =
      '[$$-1] #,##########0.00;[$$-1][Red]-#,##########0.00';
  }

  for (let rowIndex = 1; rowIndex <= groupMembersWorkSheet.rowCount; rowIndex++) {
    groupMembersWorkSheet.getRow(rowIndex).alignment = { vertical: 'top', wrapText: true };
  }

  columnAutoWidth(groupMembersWorkSheet);

  if (!_.isEmpty(dentalQuotes)) {
    normalizeMedicalExport(ExcelJSWorkbook, medicalQuotes, members);
  }

  if (!_.isEmpty(dentalQuotes)) {
    normalizeDentalExport(ExcelJSWorkbook, dentalQuotes, groupMembers);
  }

  if (!_.isEmpty(visionQuotes)) {
    normalizeVisionExport(ExcelJSWorkbook, visionQuotes, groupMembers);
  }

  if (!_.isEmpty(lifeQuotes)) {
    normalizeLifeExport(ExcelJSWorkbook, lifeQuotes, groupMembers);
  }

  if (!_.isEmpty(disabilityQuotes)) {
    normalizeDisabilityExport(ExcelJSWorkbook, disabilityQuotes, groupMembers);
  }

  if (!_.isEmpty(accidentQuotes)) {
    normalizeAccidentExport(ExcelJSWorkbook, accidentQuotes, groupMembers);
  }

  if (!_.isEmpty(hospitalQuotes)) {
    normalizeHospitalExport(ExcelJSWorkbook, hospitalQuotes, groupMembers);
  }

  if (!_.isEmpty(criticalQuotes)) {
    normalizeCriticalExport(ExcelJSWorkbook, criticalQuotes, groupMembers);
  }

  for (let rowIndex = 1; rowIndex <= accountWorkSheet.rowCount; rowIndex++) {
    accountWorkSheet.getRow(rowIndex).alignment = { vertical: 'top', wrapText: true };
  }

  for (let rowIndex = 1; rowIndex <= groupMembersWorkSheet.rowCount; rowIndex++) {
    groupMembersWorkSheet.getRow(rowIndex).alignment = { vertical: 'top', wrapText: true };
  }

  ExcelJSWorkbook.xlsx.writeBuffer().then((buffer) => {
    saveAs(
      new Blob([buffer], { type: 'application/octet-stream' }),
      `${accountData?.company_name}-${proposalData?.name}-Carrier Proposal.xlsx`,
    );
  });
};
