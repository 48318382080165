import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { Paper, Table, TableBody, TableCell, TableContainer, TableRow } from '@mui/material';

import { groupDataSelector } from 'src/store/groupMembers';

import { currencyFormatter } from 'src/constants/currency';

import { selectedDecisionTableRows } from '../instantDecisionQuote/table';

export const DecisionPlansTable = (props) => {
  const { selectedPlans, mode = 'default' } = props;

  const groupData = useSelector(groupDataSelector);

  const totalMembers = groupData?.membersMeta?.totalEmployees;

  const renderTableRow = useCallback(
    (props) => {
      const { title, key, value, type = 'default' } = props;

      return (
        <TableRow key={key}>
          <TableCell sx={{ fontWeight: 'bold' }}>{title}</TableCell>
          {selectedPlans.map((item) => (
            <TableCell sx={{ minWidth: 250 }} key={item.id}>
              {type === 'image' && (
                <img style={{ height: '40px' }} src={item[key]} alt="selected-pet-plan" />
              )}
              {type === 'default' && item[key]}
              {type === 'currency' && currencyFormatter(item[key])}
              {type === 'boolean' && `${item[key] ? 'Yes' : 'No'}`}
              {type === 'custom' ? value({ ...item, employees: totalMembers }) : null}
            </TableCell>
          ))}
        </TableRow>
      );
    },
    [selectedPlans, totalMembers],
  );

  return (
    <Paper sx={{ margin: '15px 0 15px 0' }} elevation={mode !== 'default' ? 0 : 1}>
      <TableContainer>
        <Table sx={{ minWidth: 650 }} size="small">
          <TableBody>{selectedDecisionTableRows.map((item) => renderTableRow(item))}</TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
};
