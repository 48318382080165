import _ from 'lodash';

import { currencyFormatter } from 'src/constants/currency';

import { normalizeFundingType } from 'src/constants/proposalFormField';
import {
  normalizeCompensationAmount,
  normalizeOrthodonticsValue,
  normalizeRateTierType,
} from 'src/constants/requestProposal';
import { capitalize } from '../other';

export const normalizeDentalMemberExport = (worksheet, dentalQuotes, member, groupMembers) => {
  worksheet.addRow(['Dental']).fill = {
    type: 'pattern',
    pattern: 'solid',
    fgColor: {
      argb: 'e6b8b7',
    },
  };

  for (const quote in dentalQuotes) {
    const isOnly3Tier = _.isEmpty(
      dentalQuotes[quote].find((item) => item.rate_tier_type === '4tier'),
    );
    const isOnly4Tier = _.isEmpty(
      dentalQuotes[quote].find((item) => item.rate_tier_type === '3tier'),
    );
    const isTierTypes = !isOnly3Tier && !isOnly4Tier;
    const isOrthodontics = !_.isEmpty(
      dentalQuotes[quote].find((item) => item.orthodontics !== 'none'),
    );

    const isPPO = !_.isEmpty(dentalQuotes[quote].find((item) => item.plan_type === 'PPO'));
    const isHMO = !_.isEmpty(dentalQuotes[quote].find((item) => item.plan_type === 'HMO'));

    let carrierNameRow = ['Carrier'];
    let contributionEeRow = ['Contribution EE'];
    let contributionDepRow = ['Contribution DEP'];
    let fundingTypeRow = ['Funding Type'];
    let planTypesRow = ['Plan Type'];
    let waitingPeriodRow = ['Waiting Period'];
    let dppoOutOfNetworkRow = ['DPPO: Out of Network Coverage'];
    let dppoAnnualMaxRow = ['DPPO: Annual Max'];
    let dppoDeductibleRow = ['DPPO: Deductible'];
    let dppoPreventativeRow = ['DPPO: Preventative'];
    let dppoBasicRow = ['DPPO: Basic'];
    let dppoMajorRow = ['DPPO: Major'];
    let orthodonticsRow = ['Orthodontics'];
    let orthodonticsCoverageRow = ['Orthodontics Coverage'];
    let implantCoverageRow = ['Implant Coverage'];
    let compMethodRow = ['Compensation Method'];
    let compAmountRow = ['Compensation Amount'];
    let currentlyOfferedRow = ['Currently Offered Benefit'];
    let selectedPlanRow = ['Selected Plan Name'];

    let quoteRows = [
      carrierNameRow,
      contributionEeRow,
      contributionDepRow,
      fundingTypeRow,
      planTypesRow,
      waitingPeriodRow,
    ];

    let orthodonticsRows = [];

    if (isOrthodontics) {
      orthodonticsRows = [orthodonticsRow, orthodonticsCoverageRow];
    }

    if (!isOrthodontics) {
      orthodonticsRows = [orthodonticsRow];
    }

    if (isPPO) {
      quoteRows = [
        ...quoteRows,
        dppoOutOfNetworkRow,
        dppoAnnualMaxRow,
        dppoDeductibleRow,
        dppoPreventativeRow,
        dppoBasicRow,
        dppoMajorRow,
        ...orthodonticsRows,
        implantCoverageRow,
        compMethodRow,
        compAmountRow,
      ];
    }

    if (isHMO) {
      quoteRows = [
        ...quoteRows,
        ...orthodonticsRows,
        implantCoverageRow,
        compMethodRow,
        compAmountRow,
      ];
    }

    let rateTierTypeRow = ['Rate Tier Type'];
    let eeRow = ['EE'];
    let eeOneRow = ['EE + 1'];
    let eeSpRow = ['EE + SP'];
    let eeChRow = ['EE + CH'];
    let famRow = ['FAM'];

    let rateRows = [];

    for (const dentalQuote of dentalQuotes[quote]) {
      carrierNameRow.push(dentalQuote.issuer_name);
      contributionEeRow.push(
        dentalQuote.contribution_type === 'percent'
          ? `${dentalQuote.contribution_ee}%`
          : `${currencyFormatter(dentalQuote.contribution_ee)}`,
      );
      contributionDepRow.push(
        dentalQuote.contribution_type === 'percent'
          ? `${dentalQuote.contribution_dep}%`
          : `${currencyFormatter(dentalQuote.contribution_dep)}`,
      );
      fundingTypeRow.push(normalizeFundingType(dentalQuote.funding_type));
      planTypesRow.push(dentalQuote.plan_type);
      waitingPeriodRow.push(dentalQuote.waiting_perid ? 'Yes' : 'No');

      if (dentalQuote.plan_type === 'PPO') {
        dppoOutOfNetworkRow.push(dentalQuote.dppo_out_coverage);
        dppoAnnualMaxRow.push(currencyFormatter(dentalQuote.dppo_annual_max));
        dppoDeductibleRow.push(currencyFormatter(dentalQuote.dppo_deductible));
        dppoPreventativeRow.push(`${dentalQuote.dppo_preventative}%`);
        dppoBasicRow.push(`${dentalQuote.dppo_basic}%`);
        dppoMajorRow.push(`${dentalQuote.dppo_major}%`);
      }

      orthodonticsRow.push(normalizeOrthodonticsValue(dentalQuote.orthodontics));

      if (dentalQuote.orthodontics !== 'none') {
        orthodonticsCoverageRow.push(currencyFormatter(dentalQuote.orthodontics_coverage));
      }

      implantCoverageRow.push(dentalQuote.implant_coverage ? 'Yes' : 'No');
      compMethodRow.push(capitalize(dentalQuote.comp_method));
      compAmountRow.push(normalizeCompensationAmount(dentalQuote));
      currentlyOfferedRow.push(dentalQuote.is_current_rate ? 'Yes' : 'No');
      selectedPlanRow.push(dentalQuote.selected_plan_name);
      rateTierTypeRow.push(normalizeRateTierType(dentalQuote.rate_tier_type));
      eeRow.push(currencyFormatter(dentalQuote.rate_ee));

      if (dentalQuote.rate_tier_type === '3tier') {
        eeOneRow.push(dentalQuote.rate_ee_1 ? currencyFormatter(dentalQuote.rate_ee_1) : '');
        eeSpRow.push('');
        eeChRow.push('');
      }

      if (dentalQuote.rate_tier_type === '4tier') {
        eeSpRow.push(dentalQuote.rate_ee_sp ? currencyFormatter(dentalQuote.rate_ee_sp) : '');
        eeChRow.push(dentalQuote.rate_ee_ch ? currencyFormatter(dentalQuote.rate_ee_ch) : '');
        eeOneRow.push('');
      }

      famRow.push(dentalQuote.rate_fam ? currencyFormatter(dentalQuote.rate_fam) : '');

      if (isOnly3Tier && dentalQuote.rate_tier_type === '3tier') {
        rateRows = [rateTierTypeRow, eeRow, eeOneRow, famRow];
      }

      if (isOnly4Tier && dentalQuote.rate_tier_type === '4tier') {
        rateRows = [rateTierTypeRow, eeRow, eeSpRow, eeChRow, famRow];
      }

      if (isTierTypes) {
        rateRows = [rateTierTypeRow, eeRow, eeOneRow, eeSpRow, eeChRow, famRow];
      }
    }

    quoteRows = [...quoteRows, currentlyOfferedRow, selectedPlanRow, []];

    for (const row of quoteRows) {
      if (row.find((item) => item === 'Carrier')) {
        worksheet.addRow(row).font = { bold: true };
      } else {
        worksheet.addRow(row).getCell(1).font = { bold: true };
      }
    }

    for (const row of rateRows) {
      worksheet.addRow(row).getCell(1).font = { bold: true };
    }

    worksheet.addRow([]);

    const filteredMembers = groupMembers?.filter((item) => item.family_id === member.family_id);

    const updatedQuotes = [];

    for (const item of dentalQuotes[quote]) {
      let payload = { ...item };

      let totalEmployee = 0;
      let totalEmployer = 0;
      let total = 0;

      for (const groupMember of item.calculation[member.family_id]) {
        if (groupMember.rate_employee) {
          totalEmployee += groupMember.rate_employee;
        }
        if (groupMember.rate_employer) {
          totalEmployer += groupMember.rate_employer;
        }
        if (groupMember.rate_total) {
          total += groupMember.rate_total;
        }
      }

      payload.calculation = {
        ...item.calculation,
        totalEmployee,
        totalEmployer,
        total,
      };

      updatedQuotes.push(payload);
    }

    const addSection = (sectionTitle, sectionValue, sectionRate) => {
      let sectionRow = [sectionTitle];

      for (const item of updatedQuotes) {
        sectionRow.push(currencyFormatter(item.calculation[sectionValue]));
      }

      const sectionRowStyle = worksheet.addRow(sectionRow);
      sectionRowStyle.eachCell(
        (cell) =>
          (cell.fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: {
              argb: 'ffff00',
            },
          }),
      );
      sectionRowStyle.font = { bold: true };

      const rates = [];

      for (const groupMember of filteredMembers) {
        let updatedMember = [`${groupMember.first_name} ${groupMember.last_name}`];

        for (const item of updatedQuotes) {
          const quoteMember = item.calculation[groupMember.family_id].find(
            (item) => item.id === groupMember.id,
          );

          updatedMember.push(currencyFormatter(quoteMember[sectionRate]));
        }

        rates.push(updatedMember);
      }

      for (const rate of rates) {
        worksheet.addRow(rate).getCell(1).font = { bold: true };
      }
    };

    addSection('Employee', 'totalEmployee', 'rate_employee');
    addSection('Employer', 'totalEmployer', 'rate_employer');
    addSection('Total', 'total', 'rate_total');

    for (let rowIndex = 2; rowIndex <= worksheet.rowCount; rowIndex++) {
      worksheet.getRow(rowIndex).alignment = { horizontal: 'left', wrapText: true };
    }

    worksheet.getRow(1).getCell(1).fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: {
        argb: 'e6b8b7',
      },
    };

    worksheet.addRow([]);
  }
};
