import _ from 'lodash';
import { currencyFormatter } from 'src/constants/currency';
import { normalizeFundingType } from 'src/constants/proposalFormField';
import { normalizeCompensationAmount, normalizeRateTierType } from 'src/constants/requestProposal';

import { allAges, ALL_AGES_NAMES } from 'src/features/medical/medicalAgeBandedRates/medicalAllAges';
import {
  fiveYearAges,
  FIVE_YEAR_NAMES,
} from 'src/features/medical/medicalAgeBandedRates/medicalFiveYearAges';
import {
  tenYearAges,
  TEN_YEAR_NAMES,
} from 'src/features/medical/medicalAgeBandedRates/medicalTenYearAges';

import { capitalize } from '../other';
import { prepareCustomFields } from '../../prepareCustomFields';

export const normalizeMedicalExport = (ExcelJSWorkbook, medicalQuotes, groupMembers, type) => {
  let medicalCounter = 1;

  for (const quote in medicalQuotes) {
    const isAllAgesRate = !_.isEmpty(
      medicalQuotes[quote].find((item) => item?.rate_tier_type === 'all_ages'),
    );
    const isFiveAgesRate = !_.isEmpty(
      medicalQuotes[quote].find((item) => item?.rate_tier_type === 'banded_5'),
    );
    const isTenAgesRate = !_.isEmpty(
      medicalQuotes[quote].find((item) => item?.rate_tier_type === 'banded_10'),
    );

    const preparedCustomFields = prepareCustomFields(medicalQuotes[quote]);

    const medicalQuoteWorkSheet = ExcelJSWorkbook.addWorksheet(
      type === 'all'
        ? `Medical RFP Quote ${medicalCounter++}`
        : `Sold Medical Quote ${medicalCounter++}`,
      {
        properties: { defaultColWidth: 40 },
      },
    );

    // const isInpatientPercent = medicalQuotes[quote]?.find(
    //   (item) => item.inpatient_facility_is_percent,
    // );

    let offerRow = [''];
    let carrierRow = ['Carrier'];
    let contributionEe = ['Contribution EE'];
    let contributionDep = ['Contribution DEP'];
    let fundingTypeRow = ['Funding Type'];
    let planTypeRow = ['Plan Type'];
    let networkSizeRow = ['Network Size'];
    let tierRow = ['Tier'];
    let primaryCareRow = ['Primary Care Physician Copay'];
    let specialistCopayRow = ['Specialist Copay'];
    let individualDeductibleRow = ['Individual Deductible'];
    let familyDeductibleRow = ['Family Deductible'];

    let inpatientFacilityRow = ['Inpatient Facility'];
    let inpatientFacilityPercent = ['']; // % Per day
    let inpatientFacilityCost = ['']; // Inpatient cost per day
    // let inpatientFacilityDays = ['Inpatient number of days'];

    let coinsuranceRow = ['Coinsurance'];
    let emergencyRoomRow = ['Emergency Room'];
    let urgentCareRow = ['Urgent Care'];
    let outOfPocketRow = ['Max Out-of-Pocket'];
    let compMethodRow = ['Compensation Method'];
    let compAmountRow = ['Compensation Amount'];

    let currentlyOfferedRow = ['Currently Offered Benefit'];
    let selectedPlanRow = ['Selected Plan Name'];

    let rateTierTypeRow = ['Rate Tier Type'];

    for (const medicalQuote of medicalQuotes[quote]) {
      if (medicalQuote.issuer_name === 'broker') {
        offerRow.push('Current Offer');
      } else {
        offerRow.push('Carrier Offer');
      }
    }

    let quoteRows = [
      offerRow,
      carrierRow,
      contributionEe,
      contributionDep,
      fundingTypeRow,
      planTypeRow,
      networkSizeRow,
      tierRow,
      primaryCareRow,
      specialistCopayRow,
      individualDeductibleRow,
      familyDeductibleRow,
      inpatientFacilityRow,
    ];

    for (const medicalQuote of medicalQuotes[quote]) {
      carrierRow.push(capitalize(medicalQuote.issuer_name));
      contributionEe.push(
        medicalQuote.contribution_type === 'percent'
          ? `${medicalQuote.contribution_ee}%`
          : `${currencyFormatter(medicalQuote.contribution_ee)}`,
      );
      contributionDep.push(
        medicalQuote.contribution_type === 'percent'
          ? `${medicalQuote.contribution_dep}%`
          : `${currencyFormatter(medicalQuote.contribution_dep)}`,
      );
      fundingTypeRow.push(normalizeFundingType(medicalQuote.funding_type));
      planTypeRow.push(medicalQuote.plan_type);
      networkSizeRow.push(medicalQuote.network_size);
      tierRow.push(medicalQuote.level);
      primaryCareRow.push(currencyFormatter(medicalQuote.primary_care_physician));
      specialistCopayRow.push(currencyFormatter(medicalQuote.specialist));
      individualDeductibleRow.push(currencyFormatter(medicalQuote.individual_medical_deductible));
      familyDeductibleRow.push(currencyFormatter(medicalQuote.family_medical_deductible));

      if (medicalQuote.inpatient_facility_is_percent) {
        inpatientFacilityRow.push('Percent');
      }

      if (!medicalQuote.inpatient_facility_is_percent) {
        inpatientFacilityRow.push('$ Per Day');
      }

      inpatientFacilityPercent.push(
        medicalQuote.inpatient_facility_is_percent
          ? medicalQuote.inpatient_facility_percent_value
          : currencyFormatter(medicalQuote.inpatient_facility_dollar_amount),
      );
      inpatientFacilityCost.push(
        medicalQuote.inpatient_facility_is_percent
          ? ''
          : `${medicalQuote.inpatient_facility_dollar_days} Days`,
      );
      // inpatientFacilityDays.push(`${medicalQuote.inpatient_facility_dollar_days} Days`);
      coinsuranceRow.push(`${medicalQuote.plan_coinsurance}%`);
      emergencyRoomRow.push(medicalQuote.emergency_room);
      urgentCareRow.push(medicalQuote.urgent_care);
      outOfPocketRow.push(currencyFormatter(medicalQuote.individual_medical_moop));
      compMethodRow.push(capitalize(medicalQuote.comp_method));
      compAmountRow.push(normalizeCompensationAmount(medicalQuote));

      currentlyOfferedRow.push(medicalQuote.is_current_rate ? 'Yes' : 'No');
      selectedPlanRow.push(medicalQuote.selected_plan_name);
      rateTierTypeRow.push(normalizeRateTierType(medicalQuote.rate_tier_type));
    }

    // if (isInpatientPercent) {
    quoteRows = [...quoteRows, inpatientFacilityPercent, inpatientFacilityCost];
    // }

    // if (!isInpatientPercent) {
    //   quoteRows = [...quoteRows, inpatientFacilityCost, inpatientFacilityDays];
    // }

    quoteRows = [
      ...quoteRows,
      coinsuranceRow,
      emergencyRoomRow,
      urgentCareRow,
      outOfPocketRow,
      [],
      currentlyOfferedRow,
      selectedPlanRow,
      [],
    ];

    for (const row of quoteRows) {
      if (row.find((item) => item === 'Carrier')) {
        medicalQuoteWorkSheet.addRow(row).font = { bold: true };
      } else {
        medicalQuoteWorkSheet.addRow(row).getCell(1).font = { bold: true };
      }
    }

    const customFields = ['Custom Fields'];
    medicalQuoteWorkSheet.addRow(customFields).getCell(1).font = { bold: true };

    let customRows = [];
    for (const medicalQuote of preparedCustomFields) {
      for (let [index, field] of medicalQuote?.custom_fields.entries()) {
        if (!customRows[index]) {
          customRows[index] = [field.title, field.value];
        } else {
          customRows[index].push(field.value || '');
        }
      }
    }

    customRows.push([]);

    for (const row of customRows) {
      medicalQuoteWorkSheet.addRow(row).font = { bold: true };
    }

    medicalQuoteWorkSheet.addRow(rateTierTypeRow).getCell(1).font = { bold: true };

    let rateRows = [];

    for (let i = 1; i <= 64; i++) {
      rateRows.push(['']);
    }

    for (const medicalQuote of medicalQuotes[quote]) {
      let ageBandedRates = {};

      if (
        medicalQuote.rate_tier_type === 'all_ages' ||
        medicalQuote.rate_tier_type === 'banded_5' ||
        medicalQuote.rate_tier_type === 'banded_10'
      ) {
        for (const item of Object.keys(medicalQuote)) {
          if (item.includes('age')) {
            ageBandedRates = {
              ...ageBandedRates,
              [item]: medicalQuote[item],
            };
          }
        }
      }

      let allAgesRatesPayload = allAges(ageBandedRates);
      let fiveYearsRatesPayload = fiveYearAges(ageBandedRates);
      let tenYearRatesPayload = tenYearAges(ageBandedRates);

      if (medicalQuote.rate_tier_type === '3tier' || medicalQuote.rate_tier_type === '4tier') {
        rateRows[0].push(`${currencyFormatter(medicalQuote.rate_ee) || '--'} (EE)`);
        if (medicalQuote.rate_tier_type === '3tier') {
          rateRows[1].push(`${currencyFormatter(medicalQuote.rate_ee_1) || '--'} (EE + 1)`);
          rateRows[2].push(`${currencyFormatter(medicalQuote.rate_fam) || '--'} (FAM)`);
          if (isAllAgesRate) {
            // 51 - because range for all ages is from 0 to 14
            for (let i = 3; i <= 51; i++) {
              rateRows[i]?.push('');
            }
          } else {
            if (isFiveAgesRate && !isTenAgesRate) {
              for (let i = 3; i <= 10; i++) {
                rateRows[i]?.push('');
              }
            }

            if (!isFiveAgesRate && isTenAgesRate) {
              for (let i = 3; i <= 5; i++) {
                rateRows[i]?.push('');
              }
            }

            if (isFiveAgesRate && isTenAgesRate) {
              for (let i = 3; i <= 10; i++) {
                rateRows[i]?.push('');
              }
            }
          }
        }
        if (medicalQuote.rate_tier_type === '4tier') {
          rateRows[1].push(`${currencyFormatter(medicalQuote.rate_ee_sp) || '--'} (EE + SP)`);
          rateRows[2].push(`${currencyFormatter(medicalQuote.rate_ee_ch) || '--'} (EE + CH)`);
          rateRows[3].push(`${currencyFormatter(medicalQuote.rate_fam) || '--'} (FAM)`);
          if (isAllAgesRate) {
            for (let i = 4; i <= 51; i++) {
              rateRows[i]?.push('');
            }
          } else {
            if (isFiveAgesRate && !isTenAgesRate) {
              for (let i = 4; i <= 10; i++) {
                rateRows[i]?.push('');
              }
            }

            if (!isFiveAgesRate && isTenAgesRate) {
              for (let i = 4; i <= 5; i++) {
                rateRows[i]?.push('');
              }
            }

            if (isFiveAgesRate && isTenAgesRate) {
              for (let i = 4; i <= 10; i++) {
                rateRows[i]?.push('');
              }
            }
          }
        }
      }

      if (medicalQuote?.rate_tier_type === 'all_ages') {
        rateRows[0].push(
          `${currencyFormatter(allAgesRatesPayload.age_0_14) || '--'} (${ALL_AGES_NAMES.age_0_14})`,
        );
        for (let i = 1; i < 51; i++) {
          rateRows[i].push(
            `${currencyFormatter(allAgesRatesPayload[`age_${i + 14}`]) || '--'} (${
              ALL_AGES_NAMES[`age_${i + 14}`]
            })`,
          );
        }
      }

      if (medicalQuote?.rate_tier_type === 'banded_5') {
        rateRows[0].push(
          `${currencyFormatter(fiveYearsRatesPayload.age_0_17) || '--'} (${
            FIVE_YEAR_NAMES.age_0_17
          })`,
        );
        rateRows[1].push(
          `${currencyFormatter(fiveYearsRatesPayload.age_18_24) || '--'} (${
            FIVE_YEAR_NAMES.age_18_24
          })`,
        );
        rateRows[2].push(
          `${currencyFormatter(fiveYearsRatesPayload.age_25_29) || '--'} (${
            FIVE_YEAR_NAMES.age_25_29
          })`,
        );
        rateRows[3].push(
          `${currencyFormatter(fiveYearsRatesPayload.age_30_34) || '--'} (${
            FIVE_YEAR_NAMES.age_30_34
          })`,
        );
        rateRows[4].push(
          `${currencyFormatter(fiveYearsRatesPayload.age_35_39) || '--'} (${
            FIVE_YEAR_NAMES.age_35_39
          })`,
        );
        rateRows[5].push(
          `${currencyFormatter(fiveYearsRatesPayload.age_40_44) || '--'} (${
            FIVE_YEAR_NAMES.age_40_44
          })`,
        );
        rateRows[6].push(
          `${currencyFormatter(fiveYearsRatesPayload.age_45_49) || '--'} (${
            FIVE_YEAR_NAMES.age_45_49
          })`,
        );
        rateRows[7].push(
          `${currencyFormatter(fiveYearsRatesPayload.age_50_54) || '--'} (${
            FIVE_YEAR_NAMES.age_50_54
          })`,
        );
        rateRows[8].push(
          `${currencyFormatter(fiveYearsRatesPayload.age_55_59) || '--'} (${
            FIVE_YEAR_NAMES.age_55_59
          })`,
        );
        rateRows[9].push(
          `${currencyFormatter(fiveYearsRatesPayload.age_60_64) || '--'} (${
            FIVE_YEAR_NAMES.age_60_64
          })`,
        );
        rateRows[10].push(
          `${currencyFormatter(fiveYearsRatesPayload.age_65) || '--'} (${FIVE_YEAR_NAMES.age_65})`,
        );
        if (isAllAgesRate) {
          for (let i = 11; i <= 51; i++) {
            rateRows[i]?.push('');
          }
        }
      }

      if (medicalQuote?.rate_tier_type === 'banded_10') {
        rateRows[0].push(
          `${currencyFormatter(tenYearRatesPayload.age_0_17) || '--'} (${TEN_YEAR_NAMES.age_0_17})`,
        );
        rateRows[1].push(
          `${currencyFormatter(tenYearRatesPayload.age_18_29) || '--'} (${
            TEN_YEAR_NAMES.age_18_29
          })`,
        );
        rateRows[2].push(
          `${currencyFormatter(tenYearRatesPayload.age_30_39) || '--'} (${
            TEN_YEAR_NAMES.age_30_39
          })`,
        );
        rateRows[3].push(
          `${currencyFormatter(tenYearRatesPayload.age_40_49) || '--'} (${
            TEN_YEAR_NAMES.age_40_49
          })`,
        );
        rateRows[4].push(
          `${currencyFormatter(tenYearRatesPayload.age_50_59) || '--'} (${
            TEN_YEAR_NAMES.age_50_59
          })`,
        );
        rateRows[5].push(
          `${currencyFormatter(tenYearRatesPayload.age_60_65) || '--'} (${
            TEN_YEAR_NAMES.age_60_65
          })`,
        );
        if (isAllAgesRate) {
          for (let i = 6; i <= 51; i++) {
            rateRows[i]?.push('');
          }
        } else {
          if (isFiveAgesRate) {
            for (let i = 6; i <= 10; i++) {
              rateRows[i]?.push('');
            }
          }
        }
      }
    }

    for (const row of [...rateRows.filter((e) => e.length !== 1), []]) {
      medicalQuoteWorkSheet.addRow(row).getCell(1).font = { bold: true };
    }

    const employee = ['Employee'];

    for (const medicalQuote of medicalQuotes[quote]) {
      employee.push(currencyFormatter(medicalQuote?.calculation?.totalEmployee) || '--');
    }

    const employeeRowStyle = medicalQuoteWorkSheet.addRow(employee);
    employeeRowStyle.eachCell(
      (cell) =>
        (cell.fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: {
            argb: 'ffff00',
          },
        }),
    );
    employeeRowStyle.font = { bold: true };

    const employer = ['Employer'];

    for (const medicalQuote of medicalQuotes[quote]) {
      employer.push(currencyFormatter(medicalQuote?.calculation?.totalEmployer) || '--');
    }

    const employerRowStyle = medicalQuoteWorkSheet.addRow(employer);
    employerRowStyle.eachCell(
      (cell) =>
        (cell.fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: {
            argb: 'ffff00',
          },
        }),
    );
    employerRowStyle.font = { bold: true };

    const totalRows = ['Total'];

    for (const medicalQuote of medicalQuotes[quote]) {
      totalRows.push(currencyFormatter(medicalQuote?.calculation?.total));
    }

    const totalRowStyle = medicalQuoteWorkSheet.addRow(totalRows);
    totalRowStyle.eachCell(
      (cell) =>
        (cell.fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: {
            argb: 'ffff00',
          },
        }),
    );
    totalRowStyle.font = { bold: true };

    for (let rowIndex = 2; rowIndex <= medicalQuoteWorkSheet.rowCount; rowIndex++) {
      medicalQuoteWorkSheet.getRow(rowIndex).alignment = { horizontal: 'left', wrapText: true };
    }
  }
};
