import { createSelector } from '@reduxjs/toolkit';

const getState = (state) => state.decisionSupport;

export const decisionSupportPlansSelector = createSelector(
  getState,
  (state) => state.decisionSupportPlans,
);

export const isLoadingDecisionSupportSelector = createSelector(
  getState,
  (state) => state.isLoadingDecisionSupport,
);

export const isLoadingDecisionSupportCreateQuoteSelector = createSelector(
  getState,
  (state) => state.isLoadingCreateQuote,
);

export const decisionSupportDataSelector = createSelector(
  getState,
  (state) => state.decisionSupportData,
);
export const isLoadingDecisionSupportDataSelector = createSelector(
  getState,
  (state) => state.isLoadingDecisionSupportData,
);
