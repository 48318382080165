import React, { useState, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { isEmpty } from 'lodash';
import { toast } from 'react-toastify';
import { ToggleButtonGroup, ToggleButton, Tooltip, TextField, InputAdornment } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import moment from 'moment';
import _ from 'lodash';

import {
  fetchQuotesRates,
  setQuotesRates,
  getCarrierIssuers,
  getCarrierIssuersSelector,
  getIsLoadingQutesRates,
  recalculate,
  setSelectedQuotes,
  setMedicalPlanType,
  setSelectedMedicalPlans,
  getSelectedMedicalPlans,
  setSelectedEditQuote,
  getSelectedEditQuote,
  setBasePlanQuote,
  setBasePlanName,
  getAccountProposalSelector,
  getQuotesRates,
} from 'src/store/proposals';
import { groupMembersSelector } from 'src/store/groupMembers';
import { getAccountData } from 'src/store/broker';
import { normalizePlanLevel, normalizePlanType } from 'src/constants/coveragePlans';

import { Button, RangeInput, MUISelect } from 'src/components';

import classes from './coverageForm.module.scss';

export const CoverageForm = (props) => {
  const {
    isVirginCoverage,
    setIsVirginCoverage,
    setQuotesMetaData,
    currentPage,
    setCurrentPage,
    quoteType,
    setQuoteType,
  } = props;

  const dispatch = useDispatch();
  const params = useParams();
  const accountId = params?.id;

  const quotesRates = useSelector(getQuotesRates);
  const carrierIssuers = useSelector(getCarrierIssuersSelector);
  const isLoadingQuotesRates = useSelector(getIsLoadingQutesRates);
  const selectedMedicalPlans = useSelector(getSelectedMedicalPlans);
  const accountData = useSelector(getAccountData);
  const selectedEditQuote = useSelector(getSelectedEditQuote);
  const accountProposalData = useSelector(getAccountProposalSelector);

  const isDisabled = carrierIssuers?.length === 0;

  const quotesLength = accountProposalData?.groups[0]?.quotes?.length;

  const members = useSelector(groupMembersSelector);

  const [contributionType, setContributonType] = useState(
    !isEmpty(selectedEditQuote) ? selectedEditQuote.contribution_type : 'percent',
  );
  const [contributionPayer, setContributionPayer] = useState(
    !isEmpty(selectedEditQuote) ? selectedEditQuote.contribution_source : 'employer',
  );

  const [quoteName, setQuoteName] = useState(
    !isEmpty(selectedEditQuote) ? selectedEditQuote.name : '',
  );

  // const [carrierData, setCarrierData] = useState([]);
  const [selectedCarrierName, setSelectedCarrierName] = useState('');

  const [effectiveDate, setEffectiveDate] = useState(
    !isEmpty(selectedEditQuote)
      ? moment(selectedEditQuote.effective_date).format('YYYY-MM-DD')
      : '',
  );

  const [employeeValue, setEmployeeValue] = useState(
    !isEmpty(selectedEditQuote) ? selectedEditQuote.contribution_ee : 50,
  );
  const [dependentValue, setDependentValue] = useState(
    !isEmpty(selectedEditQuote) ? selectedEditQuote.contribution_dep : 0,
  );

  const [carrier, setCarrier] = useState();
  const [planType, setPlanType] = useState('');
  const [planLevel, setPlanLevel] = useState('');
  const [metaData, setMetaData] = useState({});
  const [selectedPlan, setSelectedPlan] = useState('');
  const [basePlan, setBasePlan] = useState('');

  const planTypeData = [
    { title: 'Empty', value: '' },
    { title: 'HMO', value: 'HMO' },
    { title: 'PPO', value: 'PPO' },
    { title: 'EPO', value: 'EPO' },
  ];

  const planLevelData = [
    { title: 'Empty', value: '' },
    { title: 'Bronze', value: 'bronze' },
    { title: 'Silver', value: 'silver' },
    { title: 'Gold', value: 'gold' },
    { title: 'Platinum', value: 'platinum' },
  ];

  useEffect(() => {
    return () => {
      setCurrentPage(1);
      dispatch(setMedicalPlanType(''));
      dispatch(setSelectedMedicalPlans({}));
      dispatch(setBasePlanName(''));
      dispatch(setBasePlanQuote({}));
      // setCarrierData([]);
      setSelectedCarrierName('');
    };
  }, [dispatch, setCurrentPage]);

  useEffect(() => {
    if (!carrierIssuers?.length) {
      // setCarrierData([]);
    }
  }, [carrierIssuers]);

  useEffect(() => {
    if (carrierIssuers?.length) {
      const carrierPayload = [];

      for (const item of carrierIssuers) {
        const payload = {
          value: item?.name,
          logo: item?.logo_url,
          title: `${item?.name} (${item?.plan_count})`,
        };

        carrierPayload.push(payload);
      }

      // setCarrierData(carrierPayload);
    }
  }, [carrierIssuers]);

  useEffect(() => {
    if (!selectedCarrierName) {
      setCarrier({});
    }
  }, [selectedCarrierName]);

  useEffect(() => {
    if (!_.isEmpty(carrierIssuers) && selectedCarrierName) {
      const selectedCarrier = carrierIssuers?.find((item) => item?.value === selectedCarrierName);

      setCarrier(selectedCarrier);
    }
  }, [carrierIssuers, selectedCarrierName]);

  useEffect(() => {
    if (isEmpty(selectedEditQuote)) {
      let accountRenewalDate = accountProposalData?.effective_date;
      let currentDate = moment().format('YYYY-MM-DD');
      const yearsDiff = moment(currentDate).diff(accountRenewalDate, 'Y');

      for (let i = 0; i < yearsDiff + 1; i++) {
        if (moment(accountRenewalDate).isBefore(currentDate)) {
          accountRenewalDate = moment(accountRenewalDate).add(1, 'Y').format('YYYY-MM-DD');
        }
      }

      setEffectiveDate(accountRenewalDate);
    }
  }, [selectedEditQuote, accountProposalData]);

  useEffect(() => {
    setEmployeeValue(!isEmpty(selectedEditQuote) ? selectedEditQuote.contribution_ee : 50);
    setDependentValue(!isEmpty(selectedEditQuote) ? selectedEditQuote.contribution_dep : 0);
  }, [selectedEditQuote]);

  useEffect(() => {
    if (isVirginCoverage) {
      if (effectiveDate && accountData?.address_zip_code) {
        const payload = {
          effective_date: effectiveDate,
          account_zip_code: accountData?.address_zip_code,
          coverage_type: 'medical',
        };

        dispatch(getCarrierIssuers(payload));
      }
    }
  }, [dispatch, isVirginCoverage, accountData, effectiveDate]);

  useEffect(() => {
    if (isEmpty(selectedEditQuote)) {
      setQuoteName(`Quote ${quotesLength + 1}`);
    }
  }, [selectedEditQuote, quotesLength]);

  useEffect(() => {
    let payload = {
      filters: {
        hios_issuer_id: carrier?.ids ?? [],
        effective_date: effectiveDate,
        level: normalizePlanLevel(planLevel),
        plan_type: normalizePlanType(planType),
      },
      page: currentPage,
      per_page: 20,
    };

    for (const item in payload.filters) {
      if (_.isEmpty(payload.filters[item])) {
        delete payload.filters[item];
      }
    }

    const quote = {
      quote_name: quoteName,
      contribution_ee: employeeValue ? Number(employeeValue) : 0,
      contribution_dep: dependentValue ? Number(dependentValue) : 0,
      contribution_type: contributionType,
      contribution_source: contributionPayer,
      effective_date: effectiveDate,
      quote_type: quoteType,
    };

    if (quoteType === 'advanced') {
      const currentEnrolledPlans = {};

      quote.mapping = {};

      for (const member of members) {
        quote.mapping[member.current_medical_plan_name] = {};

        if (currentEnrolledPlans[member.current_medical_plan_name]) {
          currentEnrolledPlans[member.current_medical_plan_name]++;
        } else {
          currentEnrolledPlans[member.current_medical_plan_name] = 1;
        }
      }

      const quoteMappingKeys = Object.keys(quote.mapping);

      for (const key of quoteMappingKeys) {
        if (!key) {
          delete quote.mapping[key];
        }

        if (key.toLowerCase() === 'null') {
          delete quote.mapping[key];
        }

        if (key.toLowerCase() === 'waive') {
          delete quote.mapping[key];
        }

        if (key.toLowerCase() === 'waived') {
          delete quote.mapping[key];
        }
      }

      setMetaData(quote.mapping);
    }

    const metaData = {
      payload,
      quote,
    };

    dispatch(recalculate({ quote, planToMap: selectedPlan }));

    setQuotesMetaData(metaData);
  }, [
    dispatch,
    setQuotesMetaData,
    contributionType,
    contributionPayer,
    quoteName,
    carrier,
    effectiveDate,
    planLevel,
    planType,
    currentPage,
    employeeValue,
    dependentValue,
    quoteType,
    members,
    selectedPlan,
  ]);

  useEffect(() => {
    if (!isEmpty(selectedEditQuote)) {
      const selectedPlanNameForEdit = Object.keys(selectedMedicalPlans).find(
        (item) => selectedMedicalPlans[item].id === selectedEditQuote.base_plan_id,
      );

      setBasePlan(selectedPlanNameForEdit);
      dispatch(setBasePlanName(selectedPlanNameForEdit));
      const updatedPlan = { ...selectedMedicalPlans[selectedPlanNameForEdit] };
      delete updatedPlan.total_premium;
      delete updatedPlan.employer_cost;
      delete updatedPlan.employee_cost;

      dispatch(setBasePlanQuote(updatedPlan));
    }
  }, [dispatch, selectedEditQuote, selectedMedicalPlans]);

  useEffect(() => {
    if (!isEmpty(selectedEditQuote)) {
      let updatedPlans = {};
      for (const item of selectedEditQuote?.plans) {
        updatedPlans = {
          ...updatedPlans,
          [item.current_plan_name]: {
            ...item,
          },
        };

        dispatch(setSelectedMedicalPlans({ ...updatedPlans }));
      }
    }
  }, [dispatch, selectedEditQuote]);

  const onChangeQuoteName = useCallback((event) => {
    setQuoteName(event.target.value);
  }, []);

  const onChangeEffectiveDate = useCallback(
    (value) => {
      setEffectiveDate(moment(value).format('YYYY-MM-DD'));

      if (quotesRates?.length) {
        dispatch(setQuotesRates([]));
      }
    },
    [dispatch, quotesRates],
  );

  const onChangeEmployeeValue = useCallback((event) => {
    setEmployeeValue(event.target.value);
  }, []);

  const onChangeDependentValue = useCallback((event) => {
    setDependentValue(event.target.value);
  }, []);

  const onSelectCarrier = useCallback((event) => {
    if (!event.target.value) {
      return setSelectedCarrierName('');
    }
    setSelectedCarrierName(event.target.value);
  }, []);

  const onSelectePlanType = useCallback((event) => {
    if (!event.target.value) {
      return setPlanType('');
    }
    setPlanType(event.target.value);
  }, []);

  const onSelectePlanLevel = useCallback((event) => {
    if (!event.target.value) {
      return setPlanLevel('');
    }
    setPlanLevel(event.target.value);
  }, []);

  const onCloseVirginCoverage = useCallback(() => {
    setIsVirginCoverage(false);
    setQuoteName('');
    setEffectiveDate(moment(new Date()).format('YYYY-MM-DD'));
    setEmployeeValue(50);
    setDependentValue(0);
    setCarrier();
    setPlanType('');
    setPlanLevel('');
    dispatch(setQuotesRates([]));
    dispatch(setSelectedQuotes([]));
    dispatch(setSelectedMedicalPlans({}));
    dispatch(setSelectedEditQuote({}));
  }, [dispatch, setIsVirginCoverage]);

  const onSubmitPlans = useCallback(
    (event) => {
      event.preventDefault();
      setCurrentPage(1);

      const modalCloseIcon = document.getElementById('modalCloseIcon');

      modalCloseIcon.scrollIntoView({ behavior: 'smooth' });

      let payload = {
        filters: {
          hios_issuer_id: carrier?.ids ?? [],
          effective_date: effectiveDate,
          level: normalizePlanLevel(planLevel),
          plan_type: normalizePlanType(planType),
        },
        page: currentPage !== 1 ? 1 : currentPage,
        per_page: 20,
      };

      for (const item in payload.filters) {
        if (_.isEmpty(payload.filters[item])) {
          delete payload.filters[item];
        }
      }

      if (quoteName.length > 25) {
        return toast('Quote name cannot be more than 25 characters', { type: 'warning' });
      }

      const quote = {
        quote_name: quoteName,
        contribution_ee: employeeValue ? Number(employeeValue) : 0,
        contribution_dep: dependentValue ? Number(dependentValue) : 0,
        contribution_type: contributionType,
        contribution_source: contributionPayer,

        effective_date: effectiveDate,
        quote_type: quoteType,
      };

      if (quoteType === 'advanced') {
        const currentEnrolledPlans = {};

        quote.mapping = {};

        for (const member of members) {
          quote.mapping[member.current_medical_plan_name] = {};

          if (currentEnrolledPlans[member.current_medical_plan_name]) {
            currentEnrolledPlans[member.current_medical_plan_name]++;
          } else {
            currentEnrolledPlans[member.current_medical_plan_name] = 1;
          }
        }

        const quoteMappingKeys = Object.keys(quote.mapping);

        for (const key of quoteMappingKeys) {
          if (!key) {
            delete quote.mapping[key];
          }

          if (key.toLowerCase() === 'null') {
            delete quote.mapping[key];
          }

          if (key.toLowerCase() === 'waive') {
            delete quote.mapping[key];
          }

          if (key.toLowerCase() === 'waived') {
            delete quote.mapping[key];
          }
        }

        setMetaData(quote.mapping);
      }

      const metaData = {
        payload,
        quote,
      };

      setQuotesMetaData(metaData);
      if (isEmpty(carrier)) {
        toast('Carrier is required field and cannot be empty', { type: 'warning' });
        return;
      }
      dispatch(fetchQuotesRates({ accountId, payload, quote, planToMap: selectedPlan }));
    },
    [
      dispatch,
      contributionPayer,
      setCurrentPage,
      setQuotesMetaData,
      accountId,
      effectiveDate,
      contributionType,
      planLevel,
      planType,
      carrier,
      employeeValue,
      dependentValue,
      currentPage,
      quoteName,
      quoteType,
      members,
      selectedPlan,
    ],
  );

  const onClickSelectedPlan = useCallback(
    (plan) => () => {
      setSelectedPlan(plan);
      setCurrentPage(1);
      dispatch(setMedicalPlanType(plan));

      const updatedPlans = {
        ...selectedMedicalPlans,
      };
      delete updatedPlans[plan];

      dispatch(setSelectedMedicalPlans({ ...updatedPlans }));
    },
    [dispatch, selectedMedicalPlans, setCurrentPage],
  );

  const onClickSelectBasePlan = useCallback(
    (plan) => () => {
      if (plan === basePlan) {
        setBasePlan('');
        dispatch(setBasePlanName(''));
        dispatch(setBasePlanQuote({}));
        return;
      }

      if (selectedMedicalPlans[plan]) {
        setBasePlan(plan);
        dispatch(setBasePlanName(plan));
        const updatedPlan = { ...selectedMedicalPlans[plan] };
        delete updatedPlan.total_premium;
        delete updatedPlan.employer_cost;
        delete updatedPlan.employee_cost;

        dispatch(setBasePlanQuote(updatedPlan));
      }
    },
    [dispatch, basePlan, selectedMedicalPlans],
  );

  const renderViewPlans = useCallback(() => {
    return Object.keys(metaData).map((plan) => {
      return (
        <div key={plan} className={classes.ViewPlans}>
          <div className={classes.ViewPlansName}>
            <strong>{plan}</strong>
            <div className={classes.ViewPlansTotal}>
              ({members.filter((item) => item.current_medical_plan_name === plan).length}/
              {members.length})
            </div>
          </div>

          <div className={classes.ViewPlansButton}>
            {contributionType === 'percent' && (
              <div className={classes.ViewPlansButtonBase}>
                <label>Base Plan</label>
                <input
                  type="radio"
                  checked={plan === basePlan}
                  onClick={onClickSelectBasePlan(plan)}
                  onChange={(e) => e.target.value}
                />
              </div>
            )}
            <Button
              title="View Plans"
              type="secondary"
              buttonType="submit"
              onClick={onClickSelectedPlan(plan)}
              isLoading={isLoadingQuotesRates}
            />
            {!isEmpty(selectedMedicalPlans[plan]) && (
              <div className={classes.ViewPlansButtonItem}>
                <i className={`fas fa-check-circle fa-lg`} />
              </div>
            )}
            {isEmpty(selectedMedicalPlans[plan]) && selectedPlan === plan && (
              <div className={classes.ViewPlansButtonItem}>
                Select a plan below to map to {plan}
              </div>
            )}
          </div>
        </div>
      );
    });
  }, [
    onClickSelectBasePlan,
    onClickSelectedPlan,
    metaData,
    members,
    isLoadingQuotesRates,
    selectedMedicalPlans,
    selectedPlan,
    basePlan,
    contributionType,
  ]);

  const onChangeToggle = useCallback((event, value) => {
    setContributonType(value ? value : 'percent');
  }, []);

  const onChangeTogglePayer = useCallback((event, value) => {
    setContributionPayer(value ? value : 'employer');
  }, []);

  const onChangeOfferedBenefit = useCallback(
    (event, value) => {
      setQuoteType(value ? value : 'standard');
    },
    [setQuoteType],
  );

  const advancedQuotingText = `Select a single new plan for each plan from census. Cost calculated from
  correlating group members enrolled in those plans from census. This option
  provides a more accurate cost approximation.`;

  return (
    <form onSubmit={!isDisabled ? onSubmitPlans : (e) => e.preventDefault()}>
      <div className={classes.CoverageFormQuotes}>
        <div className={classes.CoverageFormQuotesItem}>
          <DatePicker
            label="Effective Date"
            format="MM/DD/YYYY"
            value={effectiveDate ? moment(effectiveDate) : null}
            sx={{ width: '100%' }}
            slotProps={{
              textField: {
                size: 'small',
                required: true,
              },
            }}
            onChange={onChangeEffectiveDate}
          />
        </div>
        <div className={classes.CoverageFormQuotesItem}>
          <MUISelect
            label="Carrier"
            value={selectedCarrierName}
            options={carrierIssuers}
            onChange={onSelectCarrier}
            disabled={isDisabled}
            maxWidth="100%"
            required
          />
        </div>
        <div className={classes.CoverageFormQuotesItem}>
          <TextField
            label="Quote Name"
            value={quoteName}
            onChange={onChangeQuoteName}
            disabled={isDisabled}
            sx={{ width: '100%' }}
            size="small"
            required
          />
        </div>
        <div className={classes.CoverageFormQuotesItem}>
          <div className={classes.CoverageFormQuotesItemButtons}>
            {quoteType !== 'advanced' && (
              <Button
                title="View Plans"
                type="secondary"
                buttonType="submit"
                isLoading={isLoadingQuotesRates}
                isDisabled={isDisabled}
              />
            )}
            <Button title="Cancel" type="secondary" onClick={onCloseVirginCoverage} />
          </div>
        </div>
      </div>
      <div className={classes.ToggleWrapper}>
        <div className={classes.CoverageFormContribution}>Contribution</div>
        <div className={classes.CoverageFormToggleWrapper}>
          <ToggleButtonGroup
            color="primary"
            value={contributionType}
            exclusive
            onChange={onChangeToggle}
            aria-label="Platform"
            size="small"
          >
            <ToggleButton value="percent">Percent</ToggleButton>
            <ToggleButton value="dollar">Dollar</ToggleButton>
          </ToggleButtonGroup>
        </div>
        <div className={classes.CoverageFormToggleWrapper}>
          <ToggleButtonGroup
            color="primary"
            value={contributionPayer}
            exclusive
            onChange={onChangeTogglePayer}
            aria-label="Platform"
            size="small"
          >
            <ToggleButton value="employer">Employer</ToggleButton>
            <ToggleButton value="employee">Employee</ToggleButton>
          </ToggleButtonGroup>
        </div>
      </div>
      <div className={classes.ToggleWrapper}>
        <div className={classes.CoverageFormContribution}>Currently Offered Benefit</div>
        <div className={classes.CoverageFormToggleWrapper}>
          <ToggleButtonGroup
            color="primary"
            value={quoteType}
            exclusive
            onChange={onChangeOfferedBenefit}
            aria-label="Platform"
            size="small"
          >
            <ToggleButton value="standard">No</ToggleButton>
            <ToggleButton value="advanced">Yes</ToggleButton>
          </ToggleButtonGroup>
          <Tooltip title={advancedQuotingText} className={classes.CoverageFormToggleIcon}>
            <i className="fas fa-info-circle" />
          </Tooltip>
        </div>
      </div>
      <div className={classes.CoverageFormQuotes}>
        <div className={classes.CoverageFormQuotesItem}>
          {contributionType === 'percent' && (
            <RangeInput
              id="employee"
              label="Employee"
              value={employeeValue}
              setValue={setEmployeeValue}
              onChange={onChangeEmployeeValue}
              isDisabled={isDisabled}
            />
          )}

          {contributionType === 'dollar' && (
            <TextField
              label="Employee"
              value={employeeValue}
              onChange={onChangeEmployeeValue}
              InputProps={{ startAdornment: <InputAdornment position="start">$</InputAdornment> }}
              disabled={isDisabled}
              size="small"
            />
          )}
        </div>
        <div className={classes.CoverageFormQuotesItem}>
          {contributionType === 'percent' && (
            <RangeInput
              id="dependents"
              label="Dependents"
              value={dependentValue}
              setValue={setDependentValue}
              onChange={onChangeDependentValue}
              isDisabled={isDisabled}
            />
          )}

          {contributionType === 'dollar' && (
            <TextField
              label="Dependents"
              value={dependentValue}
              onChange={onChangeDependentValue}
              InputProps={{ startAdornment: <InputAdornment position="start">$</InputAdornment> }}
              disabled={isDisabled}
              size="small"
            />
          )}
        </div>
        <div className={classes.CoverageFormQuotesItem}>
          <MUISelect
            label="Plan Type"
            value={planType}
            options={planTypeData}
            onChange={onSelectePlanType}
            disabled={isDisabled}
          />
        </div>
        <div className={classes.CoverageFormQuotesItem}>
          <MUISelect
            label="Plan Level"
            value={planLevel}
            options={planLevelData}
            onChange={onSelectePlanLevel}
            disabled={isDisabled}
          />
        </div>
      </div>
      {quoteType === 'advanced' && renderViewPlans()}
    </form>
  );
};
