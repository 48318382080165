import { configureStore } from '@reduxjs/toolkit';

import appReducer from 'src/store/app';
import brokerReducer from 'src/store/broker';
import proposalReducer from 'src/store/proposals';
import planConfigurationReducer from 'src/store/planConfiguration';
import medicalReducer from 'src/store/medicalConfiguration';
import dentalReducer from 'src/store/dentalConfiguration';
import visionReducer from 'src/store/visionConfiguration';
import lifeReducer from 'src/store/lifeConfiguration';
import disabilityReducer from 'src/store/disabilityConfiguration';
import accidentReducer from 'src/store/accidentCnfiguration';
import hospitalReducer from 'src/store/hospitalConfiguration';
import criticalReducer from 'src/store/criticalConfiguration';
import issuerReducer from 'src/store/issuer';
import agencyReducer from 'src/store/agency';
import contactsReducer from 'src/store/contacts';
import brokerLicensesReducer from 'src/store/brokerLicenses';
import browseBrokersReducer from 'src/store/browseBrokers';
import accountContactsReducer from 'src/store/accountContacts';
import groupMembersReducer from 'src/store/groupMembers';
import adminReducer from 'src/store/admin';
import employeeReducer from 'src/store/employee';
import coverageBuilderReducer from 'src/store/coverageBuilder';
import issuerCoverageBuilderReducer from 'src/store/issuerCoverageBuilder';
import chatReducer from 'src/store/chat';
import petReducer from 'src/store/pet';
import decisionSupportReducer from 'src/store/decisionSupport';

const actionSanitizer = (action) => {
  switch (action.type) {
    case 'groupMembers/setGroupData':
      return {
        ...action,
        payload: {
          ...action.payload,
          members: '<<LONG_DATA>>',
        },
      };
    case 'groupMembers/setGroupMembers':
      return { ...action, payload: '<<LONG_DATA>>' };

    default:
      return action;
  }
};

const stateSanitizer = (state) => {
  return {
    ...state,
    groupMembers: {
      ...state.groupMembers,
      groupMembers: '<<LONG_DATA>>',
    },
    proposal: {
      ...state.proposal,
      accountProposal: '<<LONG_DATA>>',
      responseQuotes: '<<LONG_DATA>>',
    },
  };
};

export const store = configureStore({
  reducer: {
    app: appReducer,
    admin: adminReducer,
    broker: brokerReducer,
    proposal: proposalReducer,
    planConfiguration: planConfigurationReducer,
    medical: medicalReducer,
    dental: dentalReducer,
    vision: visionReducer,
    life: lifeReducer,
    disability: disabilityReducer,
    accident: accidentReducer,
    hospital: hospitalReducer,
    critical: criticalReducer,
    issuer: issuerReducer,
    agency: agencyReducer,
    contacts: contactsReducer,
    brokerLicenses: brokerLicensesReducer,
    browseBrokers: browseBrokersReducer,
    accountContacts: accountContactsReducer,
    groupMembers: groupMembersReducer,
    chat: chatReducer,
    employee: employeeReducer,
    pet: petReducer,
    decisionSupport: decisionSupportReducer,
    // NEW LOGIC
    coverageBuilder: coverageBuilderReducer,
    issuerCoverageBuilder: issuerCoverageBuilderReducer,
  },
  devTools: {
    actionSanitizer,
    stateSanitizer,
  },
});
