export const computeHousehold4TierType = (roles) => {
  if (roles.length === 1 && roles[0] === 'employee') {
    return { type: 'EE' };
  }

  let spCount = 0;
  let chCount = 0;

  for (const role of roles) {
    if (role === 'spouse' || role === 'life_partner') {
      spCount++;
    } else if (role === 'child') {
      chCount++;
    }
  }

  if (spCount > 0 && chCount > 0) {
    return { type: 'FAM' };
  } else if (spCount > 0) {
    return { type: 'EE + SP' };
  } else if (chCount > 0) {
    return { type: 'EE + CH' };
  } else {
    return { type: 'FAM' };
  }
};
