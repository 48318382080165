import React, { useCallback, useEffect, useState } from 'react';
import {
  Paper,
  TextField,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import moment from 'moment';
import { toast } from 'react-toastify';
import _ from 'lodash';

import {
  getAccountProposalSelector,
  getCarrierIssuers,
  getCarrierIssuersSelector,
} from 'src/store/proposals';
import { getAccountData } from 'src/store/broker';
import {
  createPetQuote,
  fetchPetInsurancePlans,
  isLoadingCreateQuoteSelector,
  isLoadingPetInsurancePlansSelector,
  petInsurancePlansSelector,
  setPetInsurancePlans,
  updatePetQuote,
} from 'src/store/pet';

import { petHeaderRows } from './table';

import { PetPlansTable } from '../petPlansTable/PetPlansTable';
import { Button, EmptyField, Loader, LoaderWrapper, MUISelect } from 'src/components';

import PurpleCircle from 'src/assets/app/purpleCircle.svg';
import CheckedPurpleCircle from 'src/assets/app/checkedPurpleCircle.svg';
import { ExpandMoreOutlined } from '@mui/icons-material';
import classes from './instantPetQuote.module.scss';

export const InstantPetQuote = (props) => {
  const { onClose, selectedEditQuote, selectedEditPlans } = props;

  const dispatch = useDispatch();

  const params = useParams();
  const accountId = params?.id;
  const proposalId = params?.proposalId;

  const accountProposalData = useSelector(getAccountProposalSelector);
  const accountData = useSelector(getAccountData);
  const carrierIssuers = useSelector(getCarrierIssuersSelector);
  const petInsurancePlans = useSelector(petInsurancePlansSelector);
  const isLoadingPetInsurancePlans = useSelector(isLoadingPetInsurancePlansSelector);
  const isLoadingCreateQuote = useSelector(isLoadingCreateQuoteSelector);

  const [searchData, setSearchData] = useState({
    effective_date: '', // 2025-05-03
    issuer_id: [], // '20523', '40733'
  });

  const [selectedPlans, setSelectedPlans] = useState([]);

  const [quoteName, setQuoteName] = useState('');

  const [selectedCarrier, setSelectedCarrier] = useState({});

  const isDisabled = carrierIssuers?.length === 0;

  useEffect(() => {
    return () => {
      dispatch(setPetInsurancePlans([]));
    };
  }, [dispatch]);

  useEffect(() => {
    // if (isEmpty(selectedEditQuote)) {
    let accountRenewalDate = accountProposalData?.effective_date;
    let currentDate = moment().format('YYYY-MM-DD');
    const yearsDiff = moment(currentDate).diff(accountRenewalDate, 'Y');

    for (let i = 0; i < yearsDiff + 1; i++) {
      if (moment(accountRenewalDate).isBefore(currentDate)) {
        accountRenewalDate = moment(accountRenewalDate).add(1, 'Y').format('YYYY-MM-DD');
      }
    }

    setSearchData((prev) => ({ ...prev, effective_date: accountRenewalDate }));
    // }
  }, [accountProposalData]);

  useEffect(() => {
    if (!_.isEmpty(selectedEditQuote) && !_.isEmpty(selectedEditPlans)) {
      setSearchData((prev) => ({ ...prev, effective_date: selectedEditQuote.effective_date }));
      setQuoteName(selectedEditQuote.name);
      setSelectedPlans(selectedEditPlans);
    }
  }, [selectedEditPlans, selectedEditQuote]);

  useEffect(() => {
    if (searchData.effective_date && accountData?.address_zip_code) {
      const payload = {
        effective_date: searchData.effective_date,
        account_zip_code: accountData?.address_zip_code,
        coverage_type: 'pet',
      };

      dispatch(getCarrierIssuers(payload));
    }
  }, [dispatch, accountData, searchData]);

  const onChangeEffectiveDate = useCallback((value) => {
    setSearchData((prev) => ({ ...prev, effective_date: moment(value).format('YYYY-MM-DD') }));
  }, []);

  const onSelectCarrier = useCallback(
    (event) => {
      if (!event.target.value) {
        return setSearchData((prev) => ({ ...prev, issuer_id: [] }));
      }
      const carrier = carrierIssuers.find((item) => item.value === event.target.value);

      setSelectedCarrier(carrier);

      setSearchData((prev) => ({ ...prev, issuer_id: carrier?.ids }));
    },
    [carrierIssuers],
  );

  const onChangeQuoteName = useCallback((event) => {
    setQuoteName(event.target.value);
  }, []);

  const onClickViewPlans = useCallback(
    (event) => {
      event.preventDefault();

      if (!isDisabled) {
        console.log('searchData', searchData);

        dispatch(fetchPetInsurancePlans({ accountId, payload: { filters: searchData } }));
      }
    },
    [dispatch, isDisabled, accountId, searchData],
  );

  const onSelectPlan = useCallback(
    (item) => () => {
      const findedPlan = selectedPlans.find((plan) => plan.id === item.id);

      if (findedPlan) {
        const payload = _.reject(selectedPlans, (row) => row.id === item.id);

        return setSelectedPlans(payload);
      }

      setSelectedPlans((prev) => [...prev, item]);
    },
    [selectedPlans],
  );

  const onSubmitPetQuote = useCallback(async () => {
    if (!quoteName) {
      return toast('Quote name cannot be empty', { type: 'warning' });
    }

    let plans = [];

    for (const selectedPlan of selectedPlans) {
      plans.push(selectedPlan.id);
    }

    const payload = {
      effective_date: searchData.effective_date,
      name: quoteName,
      coverage_type: 'pet',
      quote_type: 'standard',
      plans,
    };

    const isEditMode = !_.isEmpty(selectedEditPlans) && !_.isEmpty(selectedEditQuote);

    if (!isEditMode) {
      await dispatch(createPetQuote({ accountId, proposalId, payload }));
    }

    if (isEditMode) {
      await dispatch(
        updatePetQuote({ accountId, proposalId, quoteId: selectedEditQuote.id, payload }),
      );
    }

    onClose();
  }, [
    dispatch,
    onClose,
    quoteName,
    searchData,
    selectedEditPlans,
    selectedEditQuote,
    selectedPlans,
    accountId,
    proposalId,
  ]);

  const isLoading = isLoadingPetInsurancePlans || isLoadingCreateQuote;

  return (
    <div className={classes.InstantPetQuote}>
      <div className={classes.InstantPetQuoteTitle}>Create Pet Quote</div>
      <form className={classes.InstantPetQuoteForm} onSubmit={onClickViewPlans}>
        <div className={classes.InstantPetQuoteFormRow}>
          <DatePicker
            label="Effective Date"
            format="MM/DD/YYYY"
            value={searchData.effective_date ? moment(searchData.effective_date) : null}
            sx={{ width: '100%' }}
            slotProps={{
              textField: {
                size: 'small',
                required: true,
              },
            }}
            onChange={onChangeEffectiveDate}
          />
          <MUISelect
            label="Carrier"
            value={selectedCarrier?.value}
            options={carrierIssuers}
            onChange={onSelectCarrier}
            disabled={isDisabled}
            maxWidth="100%"
            required
          />
          <TextField
            label="Quote Name"
            value={quoteName}
            onChange={onChangeQuoteName}
            disabled={isDisabled}
            sx={{ width: '100%' }}
            size="small"
            required
          />
          <div className={classes.InstantPetQuoteFormItem}>
            <Button
              title="View Plans"
              type="secondary"
              buttonType="submit"
              isLoading={isLoading}
              isDisabled={isDisabled}
            />

            <Button title="Cancel" type="secondary" onClick={onClose} />
          </div>
        </div>
      </form>

      {isLoadingPetInsurancePlans || isLoadingCreateQuote ? (
        <LoaderWrapper>
          <Loader />
        </LoaderWrapper>
      ) : (
        <>
          {typeof petInsurancePlans === 'string' && <EmptyField title={petInsurancePlans} />}
          {_.isArray(petInsurancePlans) && !_.isEmpty(petInsurancePlans) && (
            <div className={classes.InstantPetQuoteTable}>
              <Accordion defaultExpanded>
                <AccordionSummary
                  expandIcon={<ExpandMoreOutlined />}
                  aria-controls="panel1-content"
                  id="panel1-header"
                  className={classes.InstantPetQuoteTitle}
                >
                  Plans
                </AccordionSummary>
                <AccordionDetails>
                  <Paper sx={{ margin: '15px 0 15px 0' }}>
                    <TableContainer>
                      <Table sx={{ minWidth: 650 }} size="small">
                        <TableHead>
                          <TableRow>
                            {petHeaderRows?.map((item) => (
                              <TableCell key={item.id}>{item.name}</TableCell>
                            ))}
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {petInsurancePlans.map((item) => {
                            const isSelected = selectedPlans.find((plan) => plan.id === item.id);

                            return (
                              <TableRow
                                key={item.id}
                                sx={{
                                  '&:last-child td, &:last-child th': { border: 0 },
                                  cursor: 'pointer',
                                }}
                                onClick={onSelectPlan(item)}
                                hover
                              >
                                <TableCell>
                                  {isSelected ? (
                                    <img src={CheckedPurpleCircle} alt="CheckedPurpleCircle" />
                                  ) : (
                                    <img src={PurpleCircle} alt="PurpleCircle" />
                                  )}
                                </TableCell>
                                <TableCell>{item.eligible_pets}</TableCell>
                                <TableCell>{item.employee_payment_method}</TableCell>
                                <TableCell>{item.group_rate_discount}</TableCell>
                                <TableCell>${item.pet_1}</TableCell>
                                <TableCell>${item.pet_2}</TableCell>
                                <TableCell>${item.pet_3}</TableCell>
                                <TableCell>${item.pet_4}</TableCell>
                                <TableCell>${item.pet_5}</TableCell>
                                <TableCell>${item.pet_n}</TableCell>
                              </TableRow>
                            );
                          })}
                        </TableBody>
                      </Table>
                    </TableContainer>
                    {/* <TablePagination
                      rowsPerPageOptions={[10, 20, 30, 40, 50]}
                      component="div"
                      count={membersData.length}
                      rowsPerPage={selectedPageSize}
                      page={currentPage - 1}
                      onPageChange={onPageChange}
                      onRowsPerPageChange={onChangePageSize}
                    /> */}
                  </Paper>
                </AccordionDetails>
              </Accordion>
            </div>
          )}
          <div className={classes.InstantPetQuoteTable}>
            {!_.isEmpty(selectedPlans) && (
              <Accordion defaultExpanded>
                <AccordionSummary
                  expandIcon={<ExpandMoreOutlined />}
                  aria-controls="panel1-content"
                  id="panel1-header"
                  className={classes.InstantPetQuoteTitle}
                >
                  Selected Plans
                </AccordionSummary>
                <AccordionDetails>
                  <PetPlansTable selectedPlans={selectedPlans} />
                </AccordionDetails>
              </Accordion>
            )}

            {!_.isEmpty(selectedPlans) && (
              <Button
                type="primary"
                title={_.isEmpty(selectedEditPlans) ? 'Create Quote' : 'Update Quote'}
                onClick={onSubmitPetQuote}
                isLoading={isLoading}
              />
            )}
          </div>
        </>
      )}
    </div>
  );
};
