import { createSlice } from '@reduxjs/toolkit';
import {
  createDecisionSupportQuote,
  deleteDecisionSupportQuote,
  fetchDecisionSupportPlans,
  getDecisionSupportQuotes,
  updateDecisionSupportQuote,
} from './decisionSupport.actions';

const initialState = {
  decisionSupportPlans: [],
  isLoadingDecisionSupportPlans: false,

  isLoadingCreateQuote: false,

  decisionSupportData: {},
  isLoadingDecisionSupportData: false,
};

const decisionSupportSlice = createSlice({
  name: 'decisionSupport',
  initialState,
  reducers: {
    setDecisionSupportPlans: (state, action) => {
      state.decisionSupportPlans = action.payload;
    },

    clearDecisionSupportState: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(fetchDecisionSupportPlans.pending, (state) => {
      state.isLoadingDecisionSupportPlans = true;
    });
    builder.addCase(fetchDecisionSupportPlans.fulfilled, (state, action) => {
      state.isLoadingDecisionSupportPlans = false;
      state.decisionSupportPlans = action.payload;
    });
    builder.addCase(fetchDecisionSupportPlans.rejected, (state) => {
      state.isLoadingDecisionSupportPlans = false;
    });

    builder.addCase(createDecisionSupportQuote.pending, (state) => {
      state.isLoadingCreateQuote = true;
    });
    builder.addCase(createDecisionSupportQuote.fulfilled, (state) => {
      state.isLoadingCreateQuote = false;
    });
    builder.addCase(createDecisionSupportQuote.rejected, (state) => {
      state.isLoadingCreateQuote = false;
    });

    builder.addCase(updateDecisionSupportQuote.pending, (state) => {
      state.isLoadingCreateQuote = true;
    });
    builder.addCase(updateDecisionSupportQuote.fulfilled, (state) => {
      state.isLoadingCreateQuote = false;
    });
    builder.addCase(updateDecisionSupportQuote.rejected, (state) => {
      state.isLoadingCreateQuote = false;
    });

    builder.addCase(getDecisionSupportQuotes.pending, (state) => {
      state.isLoadingDecisionSupportData = true;
    });
    builder.addCase(getDecisionSupportQuotes.fulfilled, (state, action) => {
      state.isLoadingDecisionSupportData = false;
      state.decisionSupportData = action.payload;
    });
    builder.addCase(getDecisionSupportQuotes.rejected, (state) => {
      state.isLoadingDecisionSupportData = false;
    });

    builder.addCase(deleteDecisionSupportQuote.pending, (state) => {
      state.isLoadingDecisionSupportData = true;
    });
    builder.addCase(deleteDecisionSupportQuote.fulfilled, (state) => {
      state.isLoadingDecisionSupportData = false;
    });
    builder.addCase(deleteDecisionSupportQuote.rejected, (state) => {
      state.isLoadingDecisionSupportData = false;
    });
  },
});

export const { setDecisionSupportPlans, clearDecisionSupportState } = decisionSupportSlice.actions;

export default decisionSupportSlice.reducer;
