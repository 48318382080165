import React from 'react';
import { useSelector } from 'react-redux';

import { brokerUsersMetaSelector, repUsersMetaSelector } from 'src/store/admin';

import { normalizeBigNumber } from 'src/utils/exportXls/proposal/normalizeBigNumbers';

import classes from './dashboardStatistic.module.scss';

export const DashboardStatistic = () => {
  const adminBrokersMeta = useSelector(brokerUsersMetaSelector);
  const repUsersMeta = useSelector(repUsersMetaSelector);

  return (
    <div className={classes.DashboardStatistic}>
      <div className={classes.DashboardStatisticItem}>
        <div className={classes.DashboardStatisticItemTitle}>
          {adminBrokersMeta?.total ? normalizeBigNumber(adminBrokersMeta?.total, 1) : '--'}
        </div>
        <div className={classes.DashboardStatisticItemSubTitle}>Brokers</div>
      </div>
      <div className={classes.DashboardStatisticItem}>
        <div className={classes.DashboardStatisticItemTitle}>
          {repUsersMeta?.total ? normalizeBigNumber(repUsersMeta?.total, 1) : '--'}
        </div>
        <div className={classes.DashboardStatisticItemSubTitle}>Reps</div>
      </div>
      <div className={classes.DashboardStatisticItem}>
        <div className={classes.DashboardStatisticItemTitle}>
          {false ? normalizeBigNumber(64, 1) : '--'}
        </div>
        <div className={classes.DashboardStatisticItemSubTitle}>Unresponsive RFPs</div>
      </div>
      <div className={classes.DashboardStatisticItem}>
        <div className={classes.DashboardStatisticItemTitle}>
          {false ? `$${normalizeBigNumber(312000, 1)}` : '--'}
        </div>
        <div className={classes.DashboardStatisticItemSubTitle}>Premiums YTD</div>
      </div>
    </div>
  );
};
