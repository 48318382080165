import _ from 'lodash';
import {
  compMethodOptions,
  disabilityCompanyContributionOptions,
  disabilityPlanTypeOptions,
  ltdBenefitPeriodOptions,
  ltdEliminationPeriodOptions,
  pctIncomeRequestOptions,
  rateBasisOptions,
  stdBenefitPeriodOptions,
  stdEliminationPeriodOptions,
} from 'src/constants/coverage';
import {
  normalizeDisabilityPlanType,
  normalizeLtdBenefitPeriod,
  normalizeStdBenefitPeriod,
} from 'src/constants/requestProposal';

export const useDisabilityIssuerBuild = () => {
  const build = {
    coverage: 'disability',
    title: 'Disability',
    form_options: {
      // contribution: true,
    },
    card_options: {
      plan: [
        {
          title: 'Contribution',
          key: 'contribution_type',
        },
        {
          title: '% of Income',
          key: 'income_pct',
          format: (value) => `${value} %`,
        },
        {
          title: 'Plan Type',
          key: 'plan_type',
          format: (value) => value.toUpperCase(),
          render: (value) =>
            value === 'std'
              ? [
                  { title: 'Elimination Period', key: 'std_elimination_period' },
                  {
                    title: 'Benefit Period',
                    key: 'std_benefit_period',
                    format: (value) => normalizeStdBenefitPeriod(value),
                  },
                ]
              : [
                  {
                    title: 'Elimination Period',
                    key: 'ltd_elimination_period',
                    format: (value) => `${value} Days`,
                  },
                  {
                    title: 'Benefit Period',
                    key: 'ltd_benefit_period',
                    format: (value) => normalizeLtdBenefitPeriod(value),
                  },
                ],
        },
        {
          title: 'Current Benefit',
          key: 'is_current_rate',
          format: (value) => (value ? 'Yes' : 'No'),
        },
      ],
    },
    prepare_payload: (offer) => {
      if (offer?.plan_type === 'std') {
        offer.ltd_monthly_max = null;
        offer.ltd_elimination_period = null;
        offer.ltd_benefit_period = null;
      }

      if (offer?.plan_type === 'ltd') {
        offer.std_weekly_max = null;
        offer.std_elimination_period = null;
        offer.std_benefit_period = null;
      }

      return offer;
    },
    offer_details: (offers, selectedOffer) => {
      const std = offers?.find((item) => item?.plan_type === 'std');
      const ltd = offers?.find((item) => item?.plan_type === 'ltd');

      const isStd = !_.isEmpty(std) || selectedOffer?.plan_type === 'std';
      const isLtd = !_.isEmpty(ltd) || selectedOffer?.plan_type === 'ltd';

      return [
        {
          key: 'income_pct',
          format: (value) => `${value} %`,
          options: {
            render_type: 'select',
            render_data: pctIncomeRequestOptions,
            type: 'other',
            symbol: '%',
            symbolPosition: 'end',
            inputType: 'number',
            maxValue: 100,
            minValue: 0,
          },
        },
        {
          key: 'contribution_type',
          options: {
            render_type: 'select',
            render_data: disabilityCompanyContributionOptions,
          },
        },
        {
          key: 'plan_type',
          format: (value) => normalizeDisabilityPlanType(value),
          options: {
            render_type: 'select',
            render_data: disabilityPlanTypeOptions,
          },
        },
        ...(isStd
          ? [
              {
                key: 'std_weekly_max',
                format: (value) => (value ? `$${value}` : ''),
                options: {
                  render_type: 'input',
                  symbol: '$',
                  inputType: 'number',
                  isVisible: selectedOffer?.plan_type === 'std',
                },
              },
              {
                key: 'std_elimination_period',
                options: {
                  render_type: 'select',
                  render_data: stdEliminationPeriodOptions,
                  type: 'other',
                  isVisible: selectedOffer?.plan_type === 'std',
                },
              },
              {
                key: 'std_benefit_period',
                format: (value) => normalizeStdBenefitPeriod(value),
                options: {
                  render_type: 'select',
                  render_data: stdBenefitPeriodOptions,
                  type: 'other',
                  inputType: 'number',
                  isVisible: selectedOffer?.plan_type === 'std',
                },
              },
            ]
          : []),
        ...(isLtd
          ? [
              {
                key: 'ltd_monthly_max',
                format: (value) => (value ? `$${value}` : ''),
                options: {
                  render_type: 'input',
                  symbol: '$',
                  inputType: 'number',
                  isVisible: selectedOffer?.plan_type === 'ltd',
                },
              },
              {
                key: 'ltd_elimination_period',
                format: (value) => (value ? `${value} Days` : ''),
                options: {
                  render_type: 'select',
                  render_data: ltdEliminationPeriodOptions,
                  symbol: 'Days',
                  symbolPosition: 'end',
                  type: 'other',
                  isVisible: selectedOffer?.plan_type === 'ltd',
                },
              },
              {
                key: 'ltd_benefit_period',
                format: (value) => normalizeLtdBenefitPeriod(value),
                options: {
                  render_type: 'select',
                  render_data: ltdBenefitPeriodOptions,
                  isVisible: selectedOffer?.plan_type === 'ltd',
                },
              },
            ]
          : []),
        {
          key: 'rate_per_unit',
          format: (value) => `$${value}`,
          options: {
            render_type: 'input',
            symbol: '$',
            inputType: 'number',
          },
        },
        {
          key: 'rate_basis',
          options: {
            render_type: 'select',
            render_data: rateBasisOptions,
          },
        },
        {
          key: 'comp_method',
          options: { render_type: 'select', render_data: compMethodOptions },
        },
      ];
    },
  };

  return { build };
};
