import _ from 'lodash';

import { currencyFormatter } from 'src/constants/currency';
import { normalizeFundingType } from 'src/constants/proposalFormField';

import { normalizeCompensationAmount, normalizeRateTierType } from 'src/constants/requestProposal';

import { prepareCustomFields } from '../../prepareCustomFields';

import { capitalize } from '../other';

export const normalizeVisionExport = (ExcelJSWorkbook, visionQuotes, groupMembers, type) => {
  let visionCounter = 1;

  for (const quote in visionQuotes) {
    const visionQuoteWorkSheet = ExcelJSWorkbook.addWorksheet(
      type === 'all'
        ? `Vision RFP Quote ${visionCounter++}`
        : `Sold Vision Quote ${visionCounter++}`,
      {
        properties: { defaultColWidth: 40 },
      },
    );

    const preparedCustomFields = prepareCustomFields(visionQuotes[quote]);

    const isOnly3Tier = _.isEmpty(
      visionQuotes[quote].find((item) => item.rate_tier_type === '4tier'),
    );
    const isOnly4Tier = _.isEmpty(
      visionQuotes[quote].find((item) => item.rate_tier_type === '3tier'),
    );
    const isTierTypes = !isOnly3Tier && !isOnly4Tier;

    let offerRow = [''];
    let carrierNameRow = ['Carrier'];
    let contributionEeRow = ['Contribution EE'];
    let contributionDepRow = ['Contribution DEP'];
    let fundingTypeRow = ['Funding Type'];
    let frequencyRow = ['Frequency'];
    let examCopayRow = ['Exam Copay'];
    let materialsCopayRow = ['Materials Copay'];
    let frameAllowanceRow = ['Frame Allowance'];
    let compMethodRow = ['Compensation Method'];
    let compAmountRow = ['Compensation Amount'];
    let currentlyOfferedRow = ['Currently Offered Benefit'];
    let selectedPlanRow = ['Selected Plan Name'];

    for (const visionQuote of visionQuotes[quote]) {
      if (visionQuote.issuer_name === 'broker') {
        offerRow.push('Current Offer');
      } else {
        offerRow.push('Carrier Offer');
      }
    }

    let quoteRows = [
      offerRow,
      carrierNameRow,
      contributionEeRow,
      contributionDepRow,
      fundingTypeRow,
      frequencyRow,
      examCopayRow,
      materialsCopayRow,
      frameAllowanceRow,
      compMethodRow,
      compAmountRow,
      currentlyOfferedRow,
      selectedPlanRow,
      [],
    ];

    let rateTierTypeRow = ['Rate Tier Type'];
    let eeRow = ['EE'];
    let eeOneRow = ['EE + 1'];
    let eeSpRow = ['EE + SP'];
    let eeChRow = ['EE + CH'];
    let famRow = ['FAM'];

    let rateRows = [];

    for (const visionQuote of visionQuotes[quote]) {
      carrierNameRow.push(capitalize(visionQuote.issuer_name));
      contributionEeRow.push(
        visionQuote.contribution_type === 'percent'
          ? `${visionQuote.contribution_ee}%`
          : `${currencyFormatter(visionQuote.contribution_ee)}`,
      );
      contributionDepRow.push(
        visionQuote.contribution_type === 'percent'
          ? `${visionQuote.contribution_dep}%`
          : `${currencyFormatter(visionQuote.contribution_dep)}`,
      );
      fundingTypeRow.push(normalizeFundingType(visionQuote?.funding_type));
      frequencyRow.push(visionQuote.frequency);
      examCopayRow.push(currencyFormatter(visionQuote.exam_copay));
      materialsCopayRow.push(currencyFormatter(visionQuote.materials_copay));
      frameAllowanceRow.push(currencyFormatter(visionQuote.frame_allowance));
      compMethodRow.push(capitalize(visionQuote.comp_method));
      compAmountRow.push(normalizeCompensationAmount(visionQuote));

      currentlyOfferedRow.push(visionQuote.is_current_rate ? 'Yes' : 'No');
      selectedPlanRow.push(visionQuote.selected_plan_name);
      rateTierTypeRow.push(normalizeRateTierType(visionQuote.rate_tier_type));
      eeRow.push(visionQuote.rate_ee ? currencyFormatter(visionQuote.rate_ee) : '');

      if (visionQuote.rate_tier_type === '3tier') {
        eeOneRow.push(visionQuote.rate_ee_1 ? currencyFormatter(visionQuote.rate_ee_1) : '');
        eeSpRow.push('');
        eeChRow.push('');
      }

      if (visionQuote.rate_tier_type === '4tier') {
        eeSpRow.push(visionQuote.rate_ee_sp ? currencyFormatter(visionQuote.rate_ee_sp) : '');
        eeChRow.push(visionQuote.rate_ee_ch ? currencyFormatter(visionQuote.rate_ee_ch) : '');
        eeOneRow.push('');
      }

      famRow.push(visionQuote.rate_fam ? currencyFormatter(visionQuote.rate_fam) : '');

      if (isOnly3Tier && visionQuote.rate_tier_type === '3tier') {
        rateRows = [rateTierTypeRow, eeRow, eeOneRow, famRow];
      }

      if (isOnly4Tier && visionQuote.rate_tier_type === '4tier') {
        rateRows = [rateTierTypeRow, eeRow, eeSpRow, eeChRow, famRow];
      }

      if (isTierTypes) {
        rateRows = [rateTierTypeRow, eeRow, eeOneRow, eeSpRow, eeChRow, famRow];
      }
    }

    for (const row of quoteRows) {
      if (row.find((item) => item === 'Carrier')) {
        visionQuoteWorkSheet.addRow(row).font = { bold: true };
      } else {
        visionQuoteWorkSheet.addRow(row).getCell(1).font = { bold: true };
      }
    }

    const customFields = ['Custom Fields'];
    visionQuoteWorkSheet.addRow(customFields).getCell(1).font = { bold: true };

    let customRows = [];
    for (const visionQuote of preparedCustomFields) {
      for (let [index, field] of visionQuote?.custom_fields.entries()) {
        if (!customRows[index]) {
          customRows[index] = [field.title, field.value];
        } else {
          customRows[index].push(field.value || '');
        }
      }
    }

    customRows.push([]);

    for (const row of customRows) {
      visionQuoteWorkSheet.addRow(row).font = { bold: true };
    }

    for (const row of rateRows) {
      visionQuoteWorkSheet.addRow(row).getCell(1).font = { bold: true };
    }

    visionQuoteWorkSheet.addRow([]);

    const employee = ['Employee'];

    for (const visionQuote of visionQuotes[quote]) {
      employee.push(currencyFormatter(visionQuote?.calculation?.totalEmployee) || '--');
    }

    const employeeRowStyle = visionQuoteWorkSheet.addRow(employee);
    employeeRowStyle.eachCell(
      (cell) =>
        (cell.fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: {
            argb: 'ffff00',
          },
        }),
    );
    employeeRowStyle.font = { bold: true };

    const employer = ['Employer'];

    for (const visionQuote of visionQuotes[quote]) {
      employer.push(currencyFormatter(visionQuote?.calculation?.totalEmployer) || '--');
    }

    const employerRowStyle = visionQuoteWorkSheet.addRow(employer);
    employerRowStyle.eachCell(
      (cell) =>
        (cell.fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: {
            argb: 'ffff00',
          },
        }),
    );
    employerRowStyle.font = { bold: true };

    const totalRows = ['Total'];

    for (const visionQuote of visionQuotes[quote]) {
      totalRows.push(currencyFormatter(visionQuote?.calculation?.total));
    }

    const totalRowStyle = visionQuoteWorkSheet.addRow(totalRows);
    totalRowStyle.eachCell(
      (cell) =>
        (cell.fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: {
            argb: 'ffff00',
          },
        }),
    );
    totalRowStyle.font = { bold: true };

    for (let rowIndex = 2; rowIndex <= visionQuoteWorkSheet.rowCount; rowIndex++) {
      visionQuoteWorkSheet.getRow(rowIndex).alignment = { horizontal: 'left', wrapText: true };
    }
  }
};
