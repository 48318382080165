import ExcelJs from 'exceljs';
import saveAs from 'file-saver';

import { columnAutoWidth, computeHousehold4TierType } from '../other';

export const onDownloadMembersXlsx = (groupMembers, fileName, setValue) => () => {
  const ExcelJSWorkbook = new ExcelJs.Workbook();
  const groupMembersWorkSheet = ExcelJSWorkbook.addWorksheet('Group Members');

  groupMembersWorkSheet.addRow([
    'ee_id',
    'first_name',
    'last_name',
    'gender',
    'relationship',
    'zip_code',
    'date_of_birth',
    'Medical enrollment',
    'current_medical_plan_name',
    'Dental enrollment',
    'dental_plan_name',
    'Vision enrollment',
    'vision_plan_name',
    'life_benefit_amount',
    'is_tobacco_user',
    'salary_amount',
    'job_title',
  ]).font = { bold: true };

  let householdRoles = {};

  for (const member of groupMembers) {
    if (householdRoles[member.family_id]) {
      householdRoles[member.family_id].push(member.relationship);
    } else {
      householdRoles[member.family_id] = [member.relationship];
    }
  }
  const household4TierTypes = {};

  for (const eid in householdRoles) {
    const roles = householdRoles[eid];
    household4TierTypes[eid] = computeHousehold4TierType(roles);
  }

  for (const member of groupMembers) {
    const payload = [
      member?.family_id,
      member?.first_name,
      member?.last_name,
      member?.gender,
      member?.relationship,
      member?.zip_code,
      member?.date_of_birth,
      member?.relationship === 'employee' && member?.current_medical_plan_name
        ? household4TierTypes[member?.family_id]?.type
        : '',
      member?.current_medical_plan_name,
      member?.relationship === 'employee' && member?.dental_plan_name
        ? household4TierTypes[member?.family_id]?.type
        : '',
      member?.dental_plan_name,
      member?.relationship === 'employee' && member?.vision_plan_name
        ? household4TierTypes[member?.family_id]?.type
        : '',
      member?.vision_plan_name,
      member?.life_benefit_amount,
      member?.is_tobacco_user ? 'yes' : 'no',
      member?.salary_amount,
      member?.job_title,
    ];

    groupMembersWorkSheet.addRow(payload);
  }

  for (let columnIndex = 2; columnIndex <= groupMembersWorkSheet.columnCount; columnIndex++) {
    groupMembersWorkSheet.getColumn(columnIndex).numFmt =
      '[$$-1] #,##########0.00;[$$-1][Red]-#,##########0.00';
  }

  for (let rowIndex = 1; rowIndex <= groupMembersWorkSheet.rowCount; rowIndex++) {
    groupMembersWorkSheet.getRow(rowIndex).alignment = { vertical: 'top', wrapText: true };
  }

  columnAutoWidth(groupMembersWorkSheet);

  ExcelJSWorkbook.xlsx.writeBuffer().then((buffer) => {
    saveAs(new Blob([buffer], { type: 'application/octet-stream' }), `${fileName}.xlsx`);
  });

  if (setValue) {
    setValue(false);
  }
};
