import { createAsyncThunk } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import _, { orderBy, isEmpty } from 'lodash';
import moment from 'moment';

import api from 'src/app/apiSingleton';
import { auth } from 'src/app/database';

import { ROUTE } from 'src/constants/routes';
import { ERROR } from 'src/constants/errorNames';

import { setReadOnlyUser, setSelectedEditQuote } from '.';
import {
  setQuotesRates,
  setQuotesMeta,
  setAccountProposal,
  setReadOnlyPickedQuote,
  setResponseQuotes,
  setProposalsMeta,
} from './proposal.slice';
import { setAccountData, setBrokerData } from '../broker';
import { setGroupData, setGroupMembers } from '../groupMembers';
import { getDentalIssuers, getRequestedProposalsStatus } from '../planConfiguration';
import { setBrokerAgency } from '../agency';
import { getMedicalQuotePlans } from '../medicalConfiguration';
import { getDentalQuotePlans } from '../dentalConfiguration';
import { getVisionQuotePlans } from '../visionConfiguration';
import { getLifeQuotePlans } from '../lifeConfiguration';
import { getDisabilityQuotePlans } from '../disabilityConfiguration';
import { getAccidentQuotePlans } from '../accidentCnfiguration';
import { getHospitalQuotePlans } from '../hospitalConfiguration';
import { getCriticalQuotePlans } from '../criticalConfiguration';
import { getPetQuotes } from '../pet';

export const getProposalsList = createAsyncThunk(
  'proposal/get-proposals',
  async (data, thunkApi) => {
    try {
      const { paramsId, searchValue, page, per_page, sort } = data;
      const token = await auth.currentUser.getIdToken();

      api.apiClient.setToken(token);

      const userId = auth.currentUser.uid;

      const payload = {
        q: searchValue,
        'f[broker_id]': userId,
        page,
        per_page,
        sort,
      };

      for (const item in payload) {
        if (!payload[item]) {
          delete payload[item];
        }
      }

      const response = await api.proposal.getProposalList(paramsId, payload);

      thunkApi.dispatch(setProposalsMeta(response.meta));

      return response.items;
    } catch (err) {
      console.warn('[ERROR]', err);
    }
  },
);

export const createProposal = createAsyncThunk(
  'proposal/create-proposal',
  async (data, thunkApi) => {
    try {
      const { accountId, name, navigate, due_date, effective_date, org_id } = data;
      const token = await auth.currentUser.getIdToken();

      api.apiClient.setToken(token);

      // const state = thunkApi.getState();
      // const groupId = state?.groupMembers?.groupData?.id;

      const payload = {
        accountId,
        name,
        // groupId,
        due_date,
        effective_date,
        org_id,
      };

      const response = await api.proposal.createProposal(payload);

      if (!org_id) {
        navigate(`${ROUTE.BROKER}/${accountId}/${ROUTE.PROPOSAL}/${response.id}/census/new`);
      }

      if (org_id) {
        navigate(`/teams/${org_id}/accounts/${accountId}/proposals/${response.id}/census/new`);
      }

      thunkApi.dispatch(setAccountProposal(response));

      return response;
    } catch (err) {
      console.warn('[ERROR]', err);
    }
  },
);

export const updateProposal = createAsyncThunk(
  'proposal/update-proposal',
  async (data, thunkApi) => {
    const { accountId, proposalId, accountProposalData } = data;

    const token = await auth.currentUser.getIdToken();

    api.apiClient.setToken(token);

    const payload = {
      ...accountProposalData,
    };

    await api.proposal.updateProposal(accountId, proposalId, payload);

    thunkApi.dispatch(getAccountProposal({ accountId, proposalId }));

    try {
    } catch (err) {
      console.warn(err);
    }
  },
);

export const getAccountProposal = createAsyncThunk(
  'proposal/get-account-proposal',
  async (data, thunkApi) => {
    try {
      const { accountId, proposalId } = data;

      const token = await auth.currentUser.getIdToken();

      api.apiClient.setToken(token);

      const proposalPayload = {
        accountId,
        proposalId,
      };

      const response = await api.proposal.getAccountProposal(proposalPayload);

      const rank = {
        employee: 1,
        spouse: 2,
        child: 3,
      };

      const groups = [];
      for (const groupId of response.group_ids) {
        const group = await api.proposal.getGroup(accountId, groupId);

        let quotes = await api.proposal.getGroupQuotes(accountId, groupId);
        // quotes = quotes.filter((item) => item.status === 'active');
        const members = await api.groupMembers.getGroupMembers(accountId, groupId);

        for (const quote of quotes) {
          const plans = await api.proposal.getQuotePlans(accountId, groupId, quote.id);

          const mapping = {};
          for (const rate of plans) {
            if (rate.current_plan_name) {
              mapping[rate.current_plan_name] = rate;
            }
          }

          const updatedPlans = sumPremium(plans, members.items, quote);

          quote.plans = updatedPlans;
        }

        thunkApi.dispatch(setResponseQuotes(quotes));

        const sortedMembers = orderBy(
          members.items,
          [
            'family_id',
            (a) => {
              return rank[a.relationship];
            },
          ],
          [true, true],
        );

        group.members = sortedMembers.sort((a, b) =>
          a.family_id.localeCompare(b.family_id, undefined, { numeric: true }),
        );

        console.log('members', members);

        const employees = members?.items?.filter((item) => item.relationship === 'employee');

        console.log('employees', employees);

        thunkApi.dispatch(setGroupMembers(group.members));
        group.quotes = quotes;
        group.membersMeta = { ...members.meta, totalEmployees: employees?.length };
        groups.push(group);
        response.quotes = quotes;

        thunkApi.dispatch(setGroupData(group));
      }

      thunkApi.dispatch(getDentalIssuers({ read_token: null }));

      response.groups = groups;

      return response;
    } catch (err) {
      console.warn('[ERROR]', err);
    }
  },
);

export const updateMedicalQuotesPlanOrder = createAsyncThunk(
  'proposal/medical-plan-order',
  async (data) => {
    try {
      const { accountId, groupId, payload } = data;

      const token = await auth.currentUser.getIdToken();
      api.apiClient.setToken(token);

      await api.proposal.updateMedicalQuotePlanOrder(accountId, groupId, payload);
    } catch (err) {
      console.warn(err);
    }
  },
);

export const deleteProposal = createAsyncThunk(
  'proposal/delete-proposal',
  async (data, thunkApi) => {
    try {
      const { accountId, proposalId, teamId, navigate } = data;

      const token = await auth.currentUser.getIdToken();
      api.apiClient.setToken(token);

      await api.proposal.deleteProposal(accountId, proposalId);

      if (!teamId) {
        navigate(`/broker/accounts/${accountId}`);
      }

      if (teamId) {
        navigate(`/teams/${teamId}/accounts/${accountId}`);
      }

      toast('Proposal was deleted successfully!', { type: 'success' });
    } catch (err) {
      console.warn(err);
    }
  },
);

export const createNewQuote = createAsyncThunk('proposal/create-quote', async (data, thunkApi) => {
  try {
    const { accountId, proposalId, payload } = data;

    const token = await auth.currentUser.getIdToken();
    const state = thunkApi.getState();
    const groupIds = state?.proposal?.accountProposal?.group_ids;

    api.apiClient.setToken(token);

    for (const groupId of groupIds) {
      await api.proposal.createQuote(accountId, groupId, payload);
    }

    await thunkApi.dispatch(getAccountProposal({ accountId, proposalId }));
    thunkApi.dispatch(setQuotesRates([]));
    await thunkApi.dispatch(getRequestedProposalsStatus({ accountId, proposalId }));

    toast('Your quote was created successfully!', { type: 'success' });
  } catch (err) {
    console.warn(err);
  }
});

export const updateQuote = createAsyncThunk('proposal/update-quote', async (data, thunkApi) => {
  try {
    const { accountId, proposalId, quoteId, payload, proposalData, read_token, valid_token } = data;

    for (const key in payload) {
      if (_.isEmpty(payload[key]) && !_.isNumber(payload[key])) {
        delete payload[key];
      }
    }

    if (!read_token) {
      const token = await auth.currentUser.getIdToken();

      api.apiClient.setToken(token);
    }

    const state = thunkApi.getState();
    const groupIds = state?.proposal?.accountProposal?.group_ids;

    for (const groupId of groupIds) {
      await api.proposal.updateQuote(accountId, groupId, quoteId, payload, read_token);
    }

    if (proposalData) {
      await api.proposal.updateProposal(accountId, proposalId, proposalData, read_token);
    }

    if (valid_token && read_token) {
      await thunkApi.dispatch(
        getReadOnly({ accountId, proposalId, token: read_token, validToken: valid_token }),
      );
    }

    if (!read_token) {
      await thunkApi.dispatch(getAccountProposal({ accountId, proposalId }));
    }

    thunkApi.dispatch(setQuotesRates([]));
    thunkApi.dispatch(setSelectedEditQuote({}));

    toast('Your quote was updated successfully!', { type: 'success' });
  } catch (err) {
    console.warn(err);
  }
});

export const deleteQuote = createAsyncThunk('proposal/create-quote', async (data, thunkApi) => {
  try {
    const { accountId, groupId, quoteId, proposalId } = data;

    await api.proposal.deleteQuote(accountId, groupId, quoteId);

    thunkApi.dispatch(getAccountProposal({ accountId, proposalId }));
    thunkApi.dispatch(getRequestedProposalsStatus({ accountId, proposalId }));
  } catch (err) {
    console.warn(err);
  }
});

export const getCarrierIssuers = createAsyncThunk(
  'proposal/get-issuers',
  async (data, thunkApi) => {
    try {
      const token = await auth.currentUser.getIdToken();

      api.apiClient.setToken(token);

      const isValidDate = moment(data?.effective_date);

      const payload = {
        ...data,
        effective_date: isValidDate?.isValid() ? data?.effective_date : '',
      };

      if (isValidDate?.isValid()) {
        const response = await api.proposal.getCarrierIssuers(payload);

        // thunkApi.dispatch(setCarrierIssuers(response));

        if (response.length) {
          return response.map((item) => ({
            value: item?.name,
            logo: item?.logo_url,
            title: `${item?.name} (${item?.plan_count})`,
            ids: item.ids,
          }));
        }

        return response;
      }
    } catch (err) {
      console.warn('ERR', err);
    }
  },
);

export const fetchQuotesRates = createAsyncThunk('proposal/rates', async (data, thunkApi) => {
  try {
    const { accountId, payload, planToMap, quote } = data;
    const token = await auth.currentUser.getIdToken();

    api.apiClient.setToken(token);

    const state = thunkApi.getState();
    const groupIds = state?.proposal?.accountProposal?.group_ids;
    const groups = state?.proposal?.accountProposal?.groups;

    let members;

    for (const group of groups) {
      members = group?.members;
    }

    let response;
    for (const groupId of groupIds) {
      response = await api.proposal.fetchQuotesRates(accountId, groupId, payload);
    }

    thunkApi.dispatch(setQuotesMeta(response.meta));

    const updatedRates = sumPremium(response.items, members, quote, planToMap);

    thunkApi.dispatch(
      setQuotesRates(_.sortBy(updatedRates, ['plan_type', 'level', 'total_premium'])),
    );

    if (!response.items.length) {
      toast('There are no results available (yet) for your effective date.', { type: 'warning' });
    }

    return response.items;
  } catch (err) {
    console.warn('ERR', err);
  }
});

export function sumPremium(rates, members, quote, planToMap, basePlanRate) {
  let updatedRates = [];
  for (let rate of rates) {
    let sumPremium = 0;
    let employerCost = 0;

    let planMembers = members?.filter(
      (item) => item.current_medical_plan_name === rate.current_plan_name,
    );
    // AGE & CONTRIBUTION CALCULATION
    for (const member of members) {
      if (quote?.quote_type === 'advanced') {
        if (planToMap) {
          if (member.current_medical_plan_name !== planToMap) {
            continue;
          }
        } else if (member.current_medical_plan_name !== rate.current_plan_name) {
          continue;
        }
      }

      let memberAge = moment(quote?.effective_date).diff(moment(member.date_of_birth), 'years');

      if (memberAge > 65) memberAge = 65;
      const memberRate = !isEmpty(basePlanRate)
        ? basePlanRate[`age_${memberAge}`]
        : rate[`age_${memberAge}`];
      const eeContributionPercent =
        quote?.contribution_type === 'percent'
          ? Number.parseInt(quote?.contribution_ee, 10) / 100
          : quote?.contribution_ee;
      const depContributionPercent =
        quote?.contribution_type === 'percent'
          ? Number.parseInt(quote?.contribution_dep, 10) / 100
          : quote?.contribution_dep;

      sumPremium += memberRate;

      // sum employer cost based on role (employee or dependent)
      if (member.relationship === 'employee') {
        if (quote?.contribution_type === 'percent') {
          employerCost +=
            Math.round((memberRate * eeContributionPercent + Number.EPSILON) * 100) / 100;
        }
        if (quote?.contribution_type === 'dollar') {
          if (eeContributionPercent < memberRate) {
            employerCost += memberRate - eeContributionPercent;
          }

          if (eeContributionPercent > memberRate) {
            // employerCost += memberRate;
          }
        }
      } else {
        if (quote?.contribution_type === 'percent') {
          employerCost +=
            Math.round((memberRate * depContributionPercent + Number.EPSILON) * 100) / 100;
        }

        if (quote?.contribution_type === 'dollar') {
          if (depContributionPercent < memberRate) {
            employerCost += memberRate - depContributionPercent;
          }

          if (depContributionPercent > memberRate) {
            // employerCost += memberRate;
          }
        }
      }
    }

    const updatedRate = {
      ...rate,
      employee_cost:
        quote?.contribution_type === 'percent' ? sumPremium - employerCost : employerCost,
      employer_cost:
        quote?.contribution_type === 'percent' ? employerCost : sumPremium - employerCost,
      total_premium: sumPremium,
      members: planMembers,
    };

    updatedRates.push(updatedRate);
  }

  return updatedRates?.sort((a, b) => b.members?.length - a.members?.length);
}

export const recalculate = createAsyncThunk('proposal/recalculate-quote', (data, thunkApi) => {
  try {
    const { baseQuote, quote, planToMap } = data;

    const state = thunkApi.getState();
    const groups = state?.proposal?.accountProposal?.groups;
    let rates = state?.proposal?.quotesRates;

    let members;

    for (const group of groups) {
      members = group?.members;
    }

    if (baseQuote) {
      console.log('recalculate[sumPremium]', sumPremium(baseQuote, members, quote));
      return;
    }

    const updatedRates = sumPremium(rates, members, quote, planToMap);

    thunkApi.dispatch(setQuotesRates(updatedRates));
  } catch (error) {
    console.warn('RATES ERROR', error);
  }
});

export const shareProposal = createAsyncThunk('proposal/share-proposal', async (data, thunkApi) => {
  try {
    const { accountId, proposalId } = data;

    const token = await auth.currentUser.getIdToken();

    api.apiClient.setToken(token);

    const accountProposal = thunkApi.getState().proposal.accountProposal;

    await api.proposal.shareProposal(accountId, proposalId);

    const updatedAccountProposal = {
      ...accountProposal,
      status: 'submitted',
    };

    thunkApi.dispatch(setAccountProposal(updatedAccountProposal));

    toast('Proposal was submitted successfully!', { type: 'success' });
  } catch (err) {
    console.warn(err);
  }
});

export const getReadOnly = createAsyncThunk('proposal/read-only', async (data, thunkApi) => {
  try {
    const { accountId, proposalId, validToken, token } = data;

    const response = await api.proposal.getReadOnlyData(validToken);

    thunkApi.dispatch(setAccountData(response.account));
    thunkApi.dispatch(setBrokerData(response.broker));
    thunkApi.dispatch(setAccountProposal(response.proposal));
    thunkApi.dispatch(setBrokerAgency(response.org));
    thunkApi.dispatch(setGroupMembers(response.proposal.groups[0].members));
    thunkApi.dispatch(setReadOnlyUser(response.user));

    await thunkApi.dispatch(getMedicalQuotePlans({ accountId, proposalId, read_token: token }));
    await thunkApi.dispatch(getDentalQuotePlans({ accountId, proposalId, read_token: token }));
    await thunkApi.dispatch(getVisionQuotePlans({ accountId, proposalId, read_token: token }));
    await thunkApi.dispatch(getLifeQuotePlans({ accountId, proposalId, read_token: token }));
    await thunkApi.dispatch(getDisabilityQuotePlans({ accountId, proposalId, read_token: token }));
    await thunkApi.dispatch(getAccidentQuotePlans({ accountId, proposalId, read_token: token }));
    await thunkApi.dispatch(getHospitalQuotePlans({ accountId, proposalId, read_token: token }));
    await thunkApi.dispatch(getCriticalQuotePlans({ accountId, proposalId, read_token: token }));
    await thunkApi.dispatch(getPetQuotes({ accountId, proposalId, read_token: token }));

    if (response.pick) {
      thunkApi.dispatch(setReadOnlyPickedQuote(response.pick));
    }
  } catch (err) {
    const { navigate } = data;
    navigate(ROUTE.DEFAULT);
    toast(ERROR.SYSTEM_ERROR, { type: 'error' });
    console.warn(err);
  }
});

export const proposalManagement = createAsyncThunk(
  'proposal/proposal-management',
  async (data, thunkApi) => {
    try {
      const { accountId, proposalId, validToken, token, payload } = data;

      await api.proposal.proposalManagement(token, payload);

      await thunkApi.dispatch(getReadOnly({ accountId, proposalId, token, validToken }));
      await thunkApi.dispatch(getMedicalQuotePlans({ accountId, proposalId, read_token: token }));
      await thunkApi.dispatch(getDentalQuotePlans({ accountId, proposalId, read_token: token }));
      await thunkApi.dispatch(getVisionQuotePlans({ accountId, proposalId, read_token: token }));
      await thunkApi.dispatch(getLifeQuotePlans({ accountId, proposalId, read_token: token }));
      await thunkApi.dispatch(
        getDisabilityQuotePlans({ accountId, proposalId, read_token: token }),
      );
      await thunkApi.dispatch(getAccidentQuotePlans({ accountId, proposalId, read_token: token }));
      await thunkApi.dispatch(getHospitalQuotePlans({ accountId, proposalId, read_token: token }));
      await thunkApi.dispatch(getCriticalQuotePlans({ accountId, proposalId, read_token: token }));
      await thunkApi.dispatch(getPetQuotes({ accountId, proposalId, read_token: token }));

      toast('Your quote has been selected!', { type: 'success' });
    } catch (err) {
      console.warn(err);
    }
  },
);

export const getChatMessages = createAsyncThunk('proposal/get-chat-messages', async (data) => {
  try {
    const { accountId, proposalId, payload } = data;

    const response = await api.proposal.getChatMessages(accountId, proposalId, payload);

    return response.items;
  } catch (err) {
    console.warn(err);
  }
});

export const getLastChatMessages = createAsyncThunk(
  'proposal/get-last-chat-messages',
  async (data) => {
    try {
      const { accountId, proposalId, payload } = data;

      const response = await api.proposal.getChatMessages(accountId, proposalId, payload);

      return response;
    } catch (err) {
      console.warn(err);
    }
  },
);

export const sendChatMessage = createAsyncThunk(
  'proposal/send-chat-message',
  async (data, thunkApi) => {
    try {
      const { accountId, proposalId, payload } = data;
      const { contact_id } = payload;

      await api.proposal.sendChatMessage(accountId, proposalId, payload);

      thunkApi.dispatch(getChatMessages({ accountId, proposalId, payload: { contact_id } }));
    } catch (err) {
      console.warn(err);
    }
  },
);

export const getExecutiveSummaryProposals = createAsyncThunk(
  'proposal/get-executive-summary',
  async (data) => {
    try {
      const { accountId, proposalId, read_token } = data;

      if (!read_token) {
        const token = await auth.currentUser.getIdToken();
        api.apiClient.setToken(token);
      }

      let payload = {};

      const medicalQuotes = await api.planConfiguration.getMedicalQuotePlans(
        accountId,
        proposalId,
        read_token,
      );

      if (medicalQuotes?.length) {
        let medicalPayload = [];
        for (const medicalQuote of medicalQuotes) {
          const medicalOffers = await api.planConfiguration.getMedicalOffers(
            accountId,
            proposalId,
            'medical',
            medicalQuote.id,
            read_token,
          );

          for (const medicalOffer of medicalOffers) {
            if (medicalOffer.status === 'approved' || medicalOffer.status === 'selected') {
              medicalPayload.push(medicalOffer);
            }
          }
        }
        payload = {
          ...payload,
          medical: medicalPayload,
        };
      }

      const dentalQuotes = await api.planConfiguration.getDentalQuotePlans(
        accountId,
        proposalId,
        read_token,
      );

      if (dentalQuotes?.length) {
        let dentalPayload = [];
        for (const dentalQuote of dentalQuotes) {
          const dentalOffers = await api.planConfiguration.getMedicalOffers(
            accountId,
            proposalId,
            'dental',
            dentalQuote.id,
            read_token,
          );

          for (const dentalOffer of dentalOffers) {
            if (dentalOffer.status === 'approved' || dentalOffer.status === 'selected') {
              dentalPayload.push(dentalOffer);
            }
          }
        }
        payload = {
          ...payload,
          dental: dentalPayload,
        };
      }

      const visionQuotes = await api.planConfiguration.getVisionQuotePlans(
        accountId,
        proposalId,
        read_token,
      );

      if (visionQuotes?.length) {
        let visionPayload = [];
        for (const visionQuote of visionQuotes) {
          const visionOffers = await api.planConfiguration.getMedicalOffers(
            accountId,
            proposalId,
            'vision',
            visionQuote.id,
            read_token,
          );

          for (const visionOffer of visionOffers) {
            if (visionOffer.status === 'approved' || visionOffer.status === 'selected') {
              visionPayload.push(visionOffer);
            }
          }
        }
        payload = {
          ...payload,
          vision: visionPayload,
        };
      }

      const lifeQuotes = await api.planConfiguration.getLifeQuotePlans(
        accountId,
        proposalId,
        read_token,
      );

      if (lifeQuotes?.length) {
        let lifePayload = [];
        for (const lifeQuote of lifeQuotes) {
          const lifeOffers = await api.planConfiguration.getMedicalOffers(
            accountId,
            proposalId,
            'life',
            lifeQuote.id,
            read_token,
          );

          for (const lifeOffer of lifeOffers) {
            if (lifeOffer.status === 'approved' || lifeOffer.status === 'selected') {
              lifePayload.push(lifeOffer);
            }
          }
        }
        payload = {
          ...payload,
          life: lifePayload,
        };
      }

      const disabilityQuotes = await api.planConfiguration.getDisabilityQuotePlans(
        accountId,
        proposalId,
        read_token,
      );

      if (disabilityQuotes?.length) {
        let disabilityPayload = [];
        for (const disabilityQuote of disabilityQuotes) {
          const disabilityOffers = await api.planConfiguration.getMedicalOffers(
            accountId,
            proposalId,
            'disability',
            disabilityQuote.id,
            read_token,
          );

          for (const disabilityOffer of disabilityOffers) {
            if (disabilityOffer.status === 'approved' || disabilityOffer.status === 'selected') {
              disabilityPayload.push(disabilityOffer);
            }
          }
        }
        payload = {
          ...payload,
          disability: disabilityPayload,
        };
      }

      const accidentQuotes = await api.accidentConfiguration.getAccidentQuotePlans(
        accountId,
        proposalId,
        read_token,
      );

      if (accidentQuotes?.length) {
        let accidentPayload = [];
        for (const accidentQuote of accidentQuotes) {
          const accidentOffers = await api.planConfiguration.getMedicalOffers(
            accountId,
            proposalId,
            'accident',
            accidentQuote.id,
            read_token,
          );

          for (const accidentOffer of accidentOffers) {
            if (accidentOffer.status === 'approved' || accidentOffer.status === 'selected') {
              accidentPayload.push(accidentOffer);
            }
          }
        }
        payload = {
          ...payload,
          accident: accidentPayload,
        };
      }

      const hospitalQuotes = await api.hospitalConfiguration.getHospitalQuotePlans(
        accountId,
        proposalId,
        read_token,
      );

      if (hospitalQuotes?.length) {
        let hospitalPayload = [];
        for (const hospitalQuote of hospitalQuotes) {
          const hospitalOffers = await api.planConfiguration.getMedicalOffers(
            accountId,
            proposalId,
            'hospital',
            hospitalQuote.id,
            read_token,
          );

          for (const hospitalOffer of hospitalOffers) {
            if (hospitalOffer.status === 'approved' || hospitalOffer.status === 'selected') {
              hospitalPayload.push(hospitalOffer);
            }
          }
        }
        payload = {
          ...payload,
          hospital: hospitalPayload,
        };
      }

      const criticalQuotes = await api.criticalConfiguration.getCriticalQuotePlans(
        accountId,
        proposalId,
        read_token,
      );

      if (criticalQuotes?.length) {
        let criticalPayload = [];
        for (const criticalQuote of criticalQuotes) {
          const criticalOffers = await api.planConfiguration.getMedicalOffers(
            accountId,
            proposalId,
            'critical_illness',
            criticalQuote.id,
            read_token,
          );

          for (const criticalOffer of criticalOffers) {
            if (criticalOffer.status === 'approved' || criticalOffer.status === 'selected') {
              criticalPayload.push(criticalOffer);
            }
          }
        }
        payload = {
          ...payload,
          critical_illness: criticalPayload,
        };
      }

      return payload;
    } catch (err) {
      console.warn(err);
    }
  },
);
