import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';
import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material';

import {
  getAccountProposalSelector,
  isExpandedQuotesSelector,
  setIsExpandedQuotes,
} from 'src/store/proposals';
import {
  decisionSupportDataSelector,
  getDecisionSupportQuotes,
  isLoadingDecisionSupportDataSelector,
} from 'src/store/decisionSupport';

import { InstantDecisionQuote } from '../instantDecisionQuote/InstantDecisionQuote';
import { DecisionQuoteCard } from '../decisionQuoteCard/DecisionQuoteCard';
import { Button, EmptyField, Loader, LoaderWrapper, Modal } from 'src/components';

import { ExpandMoreOutlined } from '@mui/icons-material';
import classes from './decisionQuotes.module.scss';
import _ from 'lodash';

export const DecisionQuotes = () => {
  const dispatch = useDispatch();
  const params = useParams();
  const { pathname } = useLocation();

  const isExpandedQuotes = useSelector(isExpandedQuotesSelector);

  const accountProposalData = useSelector(getAccountProposalSelector);
  const decisionData = useSelector(decisionSupportDataSelector);
  const isLoadingDecisionData = useSelector(isLoadingDecisionSupportDataSelector);
  const accountId = params?.id ? params?.id : accountProposalData?.account_id;
  const proposalId = params?.proposalId ? params?.proposalId : accountProposalData?.id;
  const readOnlyToken = params?.token ? params?.token : '';
  const isReadOnlyMode = pathname.includes('/read-only');

  const [isExpandedQuote, setIsExpandedQuote] = useState(true);
  const [isCreateQuote, setIsCreateQuote] = useState(false);

  const [selectedQuote, setSelectedQuote] = useState({});
  const [selectedPlans, setSelectedPlans] = useState([]);

  useEffect(() => {
    if (!_.isEmpty(accountId) && !_.isEmpty(proposalId)) {
      dispatch(getDecisionSupportQuotes({ accountId, proposalId, read_token: readOnlyToken }));
    }
    dispatch(setIsExpandedQuotes(true));
  }, [dispatch, accountId, proposalId, readOnlyToken]);

  useEffect(() => {
    setIsExpandedQuote(isExpandedQuotes);
  }, [isExpandedQuotes]);

  const onExapandQuotes = useCallback(() => {
    setIsExpandedQuote((prev) => !prev);
  }, []);

  const handleShowModal = useCallback(() => {
    setIsCreateQuote((prev) => !prev);
    setSelectedQuote({});
    setSelectedPlans([]);
  }, []);

  const onClickEdit = useCallback(
    (quote, plans) => () => {
      setSelectedQuote(quote);
      setSelectedPlans(plans);
      setIsCreateQuote(true);
    },
    [],
  );

  return (
    <>
      {isCreateQuote && (
        <Modal type="fit" closeButtonType="inside" onClose={handleShowModal}>
          <InstantDecisionQuote
            onClose={handleShowModal}
            selectedEditQuote={selectedQuote}
            selectedEditPlans={selectedPlans}
          />
        </Modal>
      )}

      <div className={classes.DecisionSupport}>
        <Accordion expanded={isExpandedQuote} onChange={onExapandQuotes}>
          <AccordionSummary
            expandIcon={<ExpandMoreOutlined />}
            aria-controls="panel1-content"
            id="panel1-header"
            className={classes.DecisionSupportTitle}
          >
            Decision Support Quotes
          </AccordionSummary>
          <AccordionDetails>
            {isLoadingDecisionData ? (
              <LoaderWrapper>
                <Loader />
              </LoaderWrapper>
            ) : (
              <>
                {decisionData?.quotes?.length ? (
                  decisionData?.quotes?.map((item) => (
                    <DecisionQuoteCard key={item.id} quote={item} onClickEdit={onClickEdit} />
                  ))
                ) : (
                  <EmptyField title="No instant decision support quotes created yet." />
                )}
              </>
            )}

            {!isReadOnlyMode && (
              <div className={classes.DecisionSupportHeaderButtons}>
                <Button title="Instant Quote" type="primary" onClick={handleShowModal} />
              </div>
            )}
          </AccordionDetails>
        </Accordion>
      </div>
    </>
  );
};
