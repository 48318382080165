import ExcelJs from 'exceljs';
import saveAs from 'file-saver';

import { capitalize, computeHousehold4TierType, urlToImageFile } from '../other';

import { normalizeAccountExport } from './normalizeAccountExport';
import { normalizeExecutiveExport } from './normalizeExecutiveExport';

import {
  normalizeMedicalExport,
  normalizeMedicalMemberExport,
  normalizeMedicalQuoteExport,
  normalizeMedicalRatesExport,
} from '../medical';
import { normalizeDentalExport, normalizeDentalMemberExport } from '../dental';
import { normalizeVisionExport, normalizeVisionMemberExport } from '../vision';
import { normalizeLifeExport, normalizeLifeMemberExport } from '../life';
import { normalizeDisabilityExport, normalizeDisabilityMemberExport } from '../disability';
import { normalizeAccidentExport, normalizeAccidentMemberExport } from '../accident';
import { normalizeHospitalExport, normalizeHospitalMemberExport } from '../hospital';
import { normalizeCriticalExport, normalizeCriticalMemberExport } from '../critical';
import { normalizePetMemberExport, normalizePetQuoteExport } from '../pet';
import { normalizeDecisionQuoteExport } from './normalizeDecisionExport';
import { currencyFormatter } from 'src/constants/currency';

export const onDownloadProposalXlsx = async (
  accountData,
  brokerData,
  proposalData,
  rfpQuotes,
  executiveSummary,
) => {
  const groupMembers = proposalData?.groups[0].members;
  const groupQuotes = proposalData?.groups[0].quotes;
  const medicalQuotes = rfpQuotes?.medical;
  const dentalQuotes = rfpQuotes?.dental;
  const visionQuotes = rfpQuotes?.vision;
  const lifeQuotes = rfpQuotes?.life;
  const disabilityQuotes = rfpQuotes?.disability;
  const accidentQuotes = rfpQuotes?.accident;
  const hospitalQuotes = rfpQuotes?.hospital;
  const criticalQuotes = rfpQuotes?.critical;
  const petQuotes = proposalData?.pet_quotes;
  const decisionQuotes = proposalData?.decision;

  const quoteNames = {
    medicalQuotes,
    dentalQuotes,
    visionQuotes,
    lifeQuotes,
    disabilityQuotes,
    accidentQuotes,
    hospitalQuotes,
    criticalQuotes,
  };

  let summaryQuoteNames = {};

  for (const quoteName in quoteNames) {
    const updatedName = capitalize(quoteName.replace('Quotes', ''));

    let counter = 1;
    for (const quoteId in quoteNames[quoteName]) {
      summaryQuoteNames = {
        ...summaryQuoteNames,
        [quoteId]: `${updatedName} RFP Quote ${counter++}`,
      };
    }
  }

  const ExcelJSWorkbook = new ExcelJs.Workbook();
  const accountWorkSheet = ExcelJSWorkbook.addWorksheet('Account', {
    properties: { defaultColWidth: 40 },
  });
  const groupMembersWorkSheet = ExcelJSWorkbook.addWorksheet('Group Members', {
    properties: { defaultColWidth: 20 },
  });

  normalizeAccountExport(accountWorkSheet, accountData, brokerData);

  const image_file = await urlToImageFile(accountData.logo_url);

  const image_id = ExcelJSWorkbook.addImage({
    base64: image_file,
    extension: 'jpeg',
  });

  accountWorkSheet.spliceRows(1, 0, []);

  accountWorkSheet.addImage(image_id, {
    tl: { col: 0, row: 0 },
    ext: { width: 128, height: 128 },
  });

  accountWorkSheet.getRow(1).height = 128;

  let householdRoles = {};

  for (const member of groupMembers) {
    if (householdRoles[member.family_id]) {
      householdRoles[member.family_id].push(member.relationship);
    } else {
      householdRoles[member.family_id] = [member.relationship];
    }
  }
  const household4TierTypes = {};

  for (const eid in householdRoles) {
    const roles = householdRoles[eid];
    household4TierTypes[eid] = computeHousehold4TierType(roles);
  }

  groupMembersWorkSheet.addRow([
    'Employee ID',
    'First Name',
    'Last Name',
    'Gender',
    'Relationship',
    'Zip Code',
    'Date of Birth',
    'Medical enrollment',
    'Current Medical Plan',
    'Dental enrollment',
    'Current Dental',
    'Vision enrollment',
    'Current Vision',
    'Current Life',
    'Tobacco',
    'Salary',
    'Job',
  ]).font = { bold: true };

  for (const member of groupMembers) {
    const payload = [
      member?.family_id,
      member?.first_name,
      member?.last_name,
      member?.gender,
      member?.relationship,
      member?.zip_code,
      member?.date_of_birth,
      member?.relationship === 'employee' && member?.current_medical_plan_name
        ? household4TierTypes[member?.family_id]?.type
        : '',
      member?.current_medical_plan_name,
      member?.relationship === 'employee' && member?.dental_plan_name
        ? household4TierTypes[member?.family_id]?.type
        : '',
      member?.dental_plan_name,
      member?.relationship === 'employee' && member?.vision_plan_name
        ? household4TierTypes[member?.family_id]?.type
        : '',
      member?.vision_plan_name,
      member?.life_benefit_amount,
      member?.is_tobacco_user ? 'yes' : 'no',
      member?.salary_amount,
      member?.job_title,
    ];

    groupMembersWorkSheet.addRow(payload);
  }

  normalizeExecutiveExport(
    ExcelJSWorkbook,
    executiveSummary,
    groupMembers,
    summaryQuoteNames,
    'Sold Quotes',
    'selected',
  );
  normalizeExecutiveExport(
    ExcelJSWorkbook,
    executiveSummary,
    groupMembers,
    summaryQuoteNames,
    'Proposals Summary',
    'approved',
  );

  normalizeMedicalQuoteExport(ExcelJSWorkbook, groupQuotes, groupMembers);
  normalizeMedicalRatesExport(ExcelJSWorkbook, groupQuotes, groupMembers);
  normalizeMedicalExport(ExcelJSWorkbook, medicalQuotes, groupMembers, 'all');
  normalizeDentalExport(ExcelJSWorkbook, dentalQuotes, groupMembers, 'all');
  normalizeVisionExport(ExcelJSWorkbook, visionQuotes, groupMembers, 'all');
  normalizeLifeExport(ExcelJSWorkbook, lifeQuotes, groupMembers, 'all');
  normalizeDisabilityExport(ExcelJSWorkbook, disabilityQuotes, groupMembers, 'all');
  normalizeAccidentExport(ExcelJSWorkbook, accidentQuotes, groupMembers, 'all');
  normalizeHospitalExport(ExcelJSWorkbook, hospitalQuotes, groupMembers, 'all');
  normalizeCriticalExport(ExcelJSWorkbook, criticalQuotes, groupMembers, 'all');
  normalizePetQuoteExport(ExcelJSWorkbook, petQuotes);
  normalizeDecisionQuoteExport(ExcelJSWorkbook, decisionQuotes, groupMembers);

  for (let rowIndex = 1; rowIndex <= accountWorkSheet.rowCount; rowIndex++) {
    accountWorkSheet.getRow(rowIndex).alignment = { vertical: 'top', wrapText: true };
  }

  for (let rowIndex = 1; rowIndex <= groupMembersWorkSheet.rowCount; rowIndex++) {
    groupMembersWorkSheet.getRow(rowIndex).alignment = { vertical: 'top', wrapText: true };
  }

  ExcelJSWorkbook.xlsx.writeBuffer().then((buffer) => {
    saveAs(
      new Blob([buffer], { type: 'application/octet-stream' }),
      `${accountData?.company_name}-${proposalData?.name}-Proposal.xlsx`,
    );
  });
};

export const onDownloadProposalRates = async (
  accountData,
  brokerData,
  proposalData,
  rfpQuotes,
  type,
) => {
  const groupMembers = proposalData?.groups[0].members;
  const medicalQuotes = rfpQuotes?.medical;
  const dentalQuotes = rfpQuotes?.dental;
  const visionQuotes = rfpQuotes?.vision;
  const lifeQuotes = rfpQuotes?.life;
  const disabilityQuotes = rfpQuotes?.disability;
  const accidentQuotes = rfpQuotes?.accident;
  const hospitalQuotes = rfpQuotes?.hospital;
  const criticalQuotes = rfpQuotes?.critical;
  const petQuotes = rfpQuotes?.pet_quotes;

  const ExcelJSWorkbook = new ExcelJs.Workbook();

  for (const member of groupMembers) {
    if (member.relationship === 'employee') {
      const [firsPartId] = member.id.split('-');

      const memberWorkSheet = ExcelJSWorkbook.addWorksheet(
        `${member?.first_name} ${member?.last_name} ( ${member.family_id} | ${firsPartId} )`,
        {
          properties: { defaultColWidth: 20 },
        },
      );

      normalizeMedicalMemberExport(memberWorkSheet, medicalQuotes, member, groupMembers);
      normalizeDentalMemberExport(memberWorkSheet, dentalQuotes, member, groupMembers);
      normalizeVisionMemberExport(memberWorkSheet, visionQuotes, member, groupMembers);
      normalizeLifeMemberExport(memberWorkSheet, lifeQuotes, member, groupMembers);
      normalizeDisabilityMemberExport(memberWorkSheet, disabilityQuotes, member, groupMembers);
      normalizeAccidentMemberExport(memberWorkSheet, accidentQuotes, member, groupMembers);
      normalizeHospitalMemberExport(memberWorkSheet, hospitalQuotes, member, groupMembers);
      normalizeCriticalMemberExport(memberWorkSheet, criticalQuotes, member, groupMembers);
      normalizePetMemberExport(memberWorkSheet, petQuotes, member, groupMembers);
    }
  }

  ExcelJSWorkbook.xlsx.writeBuffer().then((buffer) => {
    saveAs(
      new Blob([buffer], { type: 'application/octet-stream' }),
      `${accountData?.company_name}-${proposalData?.name}-employee worksheet${
        type === 'sold' ? '(sold)' : ''
      }.xlsx`,
    );
  });
};

const addComparisonSheet = (workbook, quotes, name) => {
  const carrierNames = ['Carrier', ''];

  const employeeRates = ['Employee Cost', ''];
  const employerRates = ['Employer Cost', ''];
  const totalRates = ['Total Monthly', ''];
  const quoteWorksheet = workbook.addWorksheet(name, {
    properties: { defaultColWidth: 20 },
  });

  for (const quoteId in quotes) {
    for (const quote of quotes[quoteId]) {
      carrierNames.push(quote.issuer_name);
      if (quote.calculation.totalEmployee) {
        employeeRates.push(currencyFormatter(quote.calculation.totalEmployee));
      }
      if (quote.calculation.totalEmployer) {
        employerRates.push(currencyFormatter(quote.calculation.totalEmployer));
      }
      totalRates.push(currencyFormatter(quote.calculation.total || quote.calculation.totalTobacco));
    }
  }

  quoteWorksheet.addRow(carrierNames);
  if (employeeRates.length !== 2) {
    quoteWorksheet.addRow(employeeRates);
  }
  if (employerRates.length !== 2) {
    quoteWorksheet.addRow(employerRates);
  }
  quoteWorksheet.addRow(totalRates);
};

export const onDownloadRatesComparison = async (
  accountData,
  proposalData,
  rfpQuotes,
  brokerData,
) => {
  const medicalQuotes = rfpQuotes?.medical;
  const dentalQuotes = rfpQuotes?.dental;
  const visionQuotes = rfpQuotes?.vision;
  const lifeQuotes = rfpQuotes?.life;
  const disabilityQuotes = rfpQuotes?.disability;
  const accidentQuotes = rfpQuotes?.accident;
  const hospitalQuotes = rfpQuotes?.hospital;
  const criticalQuotes = rfpQuotes?.critical;

  const ExcelJSWorkbook = new ExcelJs.Workbook();
  const accountWorkSheet = ExcelJSWorkbook.addWorksheet('Account', {
    properties: { defaultColWidth: 40 },
  });

  let longTermQuotes = {};
  let shortTermQuotes = {};

  for (const config_id in disabilityQuotes) {
    if (disabilityQuotes[config_id][0].plan_type === 'ltd') {
      longTermQuotes = {
        ...longTermQuotes,
        [config_id]: disabilityQuotes[config_id],
      };
    }

    if (disabilityQuotes[config_id][0].plan_type === 'std') {
      shortTermQuotes = {
        ...shortTermQuotes,
        [config_id]: disabilityQuotes[config_id],
      };
    }
  }

  normalizeAccountExport(accountWorkSheet, accountData, brokerData);

  const image_file = await urlToImageFile(accountData.logo_url);
  const image_id = ExcelJSWorkbook.addImage({
    base64: image_file,
    extension: 'jpeg',
  });

  accountWorkSheet.spliceRows(1, 0, []);

  accountWorkSheet.addImage(image_id, {
    tl: { col: 0, row: 0 },
    ext: { width: 128, height: 128 },
  });

  accountWorkSheet.getRow(1).height = 128;

  addComparisonSheet(ExcelJSWorkbook, medicalQuotes, 'Medical');
  addComparisonSheet(ExcelJSWorkbook, dentalQuotes, 'Dental');
  addComparisonSheet(ExcelJSWorkbook, visionQuotes, 'Vision');
  addComparisonSheet(ExcelJSWorkbook, lifeQuotes, 'Life');
  addComparisonSheet(ExcelJSWorkbook, shortTermQuotes, 'Short Term Disability');
  addComparisonSheet(ExcelJSWorkbook, longTermQuotes, 'Long Term Disability');
  addComparisonSheet(ExcelJSWorkbook, accidentQuotes, 'Accident');
  addComparisonSheet(ExcelJSWorkbook, hospitalQuotes, 'Hospital');
  addComparisonSheet(ExcelJSWorkbook, criticalQuotes, 'Critical Illness');

  ExcelJSWorkbook.xlsx.writeBuffer().then((buffer) => {
    saveAs(
      new Blob([buffer], { type: 'application/octet-stream' }),
      `${accountData?.company_name}-${proposalData?.name}-Comparison Rates.xlsx`,
    );
  });
};
