import React, { useCallback, useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Tooltip } from '@mui/material';
import moment from 'moment';

import { getAccountProposalSelector, updateProposal } from 'src/store/proposals';
import { getAccountData } from 'src/store/broker';

import { Button, Input, Modal, Status } from 'src/components';

import classes from './proposalHeaderInfo.module.scss';

export const ProposalHeaderInfo = (props) => {
  const { isEdit, setIsEdit } = props;

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();
  const { pathname } = useLocation();

  const teamId = params?.teamId;
  const accountId = params?.id;
  const proposalId = params?.proposalId;

  const isReadOnlyMode = pathname.includes('/read-only');

  const accountData = useSelector(getAccountData);
  const accountProposalData = useSelector(getAccountProposalSelector);

  const [proposalName, setProposalName] = useState(accountProposalData?.name);
  const [isEffectiveModal, setIsEffectiveModal] = useState(false);
  const [effectiveDate, setEffectiveDate] = useState('');
  const [dueDate, setDueDate] = useState('');
  const [isEffectiveDate, setIsEffectiveDate] = useState(false);
  const [isDueDate, setIsDueDate] = useState(false);

  useEffect(() => {
    if (!isEdit) {
      setProposalName(accountProposalData?.name);
      setEffectiveDate(accountProposalData?.effective_date);
      setDueDate(accountProposalData?.due_date);
    }
  }, [accountProposalData, isEdit]);

  const onClickBackButton = useCallback(() => {
    if (!teamId) {
      navigate(`/broker/accounts/${accountId}`);
    }

    if (teamId) {
      navigate(`/teams/${teamId}/accounts/${accountId}`);
    }
  }, [navigate, accountId, teamId]);

  const onSaveUpdatedProposalName = useCallback(async () => {
    const updatedAccountProposal = {
      ...accountProposalData,
    };

    updatedAccountProposal.name = proposalName;
    updatedAccountProposal.effective_date = effectiveDate;
    updatedAccountProposal.due_date = dueDate;

    delete updatedAccountProposal.groups;
    delete updatedAccountProposal.quotes;

    const payload = {
      accountId,
      proposalId,
      accountProposalData: updatedAccountProposal,
    };

    await dispatch(updateProposal(payload));
    setIsEdit(false);
  }, [
    dispatch,
    accountProposalData,
    proposalName,
    effectiveDate,
    dueDate,
    accountId,
    proposalId,
    setIsEdit,
  ]);

  const onCloseEditMode = useCallback(() => {
    setProposalName(accountProposalData?.name);
    setIsEdit(false);
  }, [accountProposalData?.name, setIsEdit]);

  const onChangeCompanyValue = useCallback((event) => {
    setProposalName(event.target.value);
  }, []);

  const onChangeEffectiveDate = useCallback((event) => {
    setEffectiveDate(event.target.value);
  }, []);

  const onChangeDueDate = useCallback((event) => {
    setDueDate(event.target.value);
  }, []);

  const onClickEffectiveDate = useCallback(() => {
    setIsEffectiveDate(true);
  }, []);

  const onFocusEffectiveDateInput = useCallback((event) => {
    event?.target?.showPicker();
  }, []);

  const onBlurEffectiveDateInput = useCallback(() => {
    if (accountProposalData?.effective_date !== effectiveDate) {
      setIsEffectiveModal(true);
    }

    if (accountProposalData?.effective_date === effectiveDate) {
      setIsEffectiveDate(false);
    }
  }, [accountProposalData, effectiveDate]);

  const onClickDueDate = useCallback(() => {
    setIsDueDate(true);
  }, []);

  const onFocusDueDateInput = useCallback((event) => {
    event?.target?.showPicker();
  }, []);

  const onBlurDueDateInput = useCallback(async () => {
    if (accountProposalData.due_date !== dueDate) {
      const updatedAccountProposal = {
        ...accountProposalData,
      };

      updatedAccountProposal.due_date = dueDate;

      delete updatedAccountProposal.groups;

      const payload = {
        accountId,
        proposalId,
        accountProposalData: updatedAccountProposal,
      };

      await dispatch(updateProposal(payload));
    }

    setIsDueDate(false);
  }, [dispatch, accountId, accountProposalData, dueDate, proposalId]);

  const onSubmitEffectiveDate = useCallback(async () => {
    if (accountProposalData.effective_date !== effectiveDate) {
      const updatedAccountProposal = {
        ...accountProposalData,
      };

      updatedAccountProposal.effective_date = effectiveDate;

      delete updatedAccountProposal.groups;

      const payload = {
        accountId,
        proposalId,
        accountProposalData: updatedAccountProposal,
      };

      await dispatch(updateProposal(payload));
    }

    setIsEffectiveDate(false);
    setIsEffectiveModal(false);
  }, [dispatch, accountId, accountProposalData, effectiveDate, proposalId]);

  const onCloseEffectiveModal = useCallback(() => {
    setEffectiveDate(accountProposalData?.effective_date);
    setIsEffectiveModal(false);
    setIsEffectiveDate(false);
  }, [accountProposalData]);

  return (
    <>
      {isEffectiveModal && (
        <Modal type="small" closeButtonType="inside" onClose={onCloseEffectiveModal}>
          <div className={classes.ModalContent}>
            <div className={classes.ModalContentTitle}>
              Changing effective date may invalidate current quotes.
            </div>
            <div className={classes.ModalContentFooter}>
              <Button type="primary" title="Confirm" onClick={onSubmitEffectiveDate} />
              <Button type="secondary" title="Cancel" onClick={onCloseEffectiveModal} />
            </div>
          </div>
        </Modal>
      )}

      <div className={classes.ProposalHeaderInfoWrapper}>
        {!isReadOnlyMode && (
          <i
            className={`fas fa-arrow-left ${classes.ProposalHeaderInfoArrow}`}
            onClick={onClickBackButton}
          />
        )}

        {!isEdit ? (
          <>
            <div className={classes.ProposalHeaderInfoTitle}>{accountProposalData?.name}</div>
          </>
        ) : (
          <>
            <div className={classes.ProposalHeaderInfoInput}>
              <Input value={proposalName} label="Proposal Name" onChange={onChangeCompanyValue} />
            </div>
            <div className={classes.ProposalHeaderInfoInput}>
              <Input
                type="date"
                label="Effective Date"
                value={effectiveDate}
                onChange={onChangeEffectiveDate}
                maxRange="2999-12-31"
              />
            </div>
            <div className={classes.ProposalHeaderInfoInput}>
              <Input type="date" label="Due Date" value={dueDate} onChange={onChangeDueDate} />
            </div>
          </>
        )}
        {!isReadOnlyMode && isEdit && (
          <div className={classes.ProposalHeaderInfoButtons}>
            <Button type="primary" title="Save" onClick={onSaveUpdatedProposalName} />
            <Button type="secondary" title="Cancel" onClick={onCloseEditMode} />
          </div>
        )}
        {!isReadOnlyMode && !isEdit && (
          <div className={classes.ProposalHeaderInfoStatus}>
            <Status status={accountProposalData?.status} />
          </div>
        )}
        {!isEdit && (
          <>
            {!isEffectiveDate && (
              <div className={classes.ProposalHeaderInfoItem} onClick={onClickEffectiveDate}>
                <div className={classes.ProposalHeaderInfoItemTitle}>Effective Date</div>
                <div className={classes.ProposalHeaderInfoItemSubTitle}>
                  {moment(accountProposalData?.effective_date).format('ll')}
                </div>
              </div>
            )}
            {isEffectiveDate && (
              <div className={classes.ProposalHeaderInfoInput}>
                <Input
                  type="date"
                  label="Effective Date"
                  value={effectiveDate}
                  onChange={onChangeEffectiveDate}
                  onFocus={onFocusEffectiveDateInput}
                  onBlur={onBlurEffectiveDateInput}
                  autoFocus={true}
                />
              </div>
            )}
            {!isDueDate && (
              <div className={classes.ProposalHeaderInfoItem} onClick={onClickDueDate}>
                <div className={classes.ProposalHeaderInfoItemTitle}>Due Date</div>
                <div className={classes.ProposalHeaderInfoItemSubTitle}>
                  {moment(accountProposalData?.due_date).format('ll')}
                </div>
              </div>
            )}
            {isDueDate && (
              <div className={classes.ProposalHeaderInfoInput}>
                <Input
                  type="date"
                  label="Due Date"
                  value={dueDate}
                  onChange={onChangeDueDate}
                  onFocus={onFocusDueDateInput}
                  onBlur={onBlurDueDateInput}
                  autoFocus={true}
                />
              </div>
            )}

            <div className={classes.ProposalHeaderInfoItem}>
              <div className={classes.ProposalHeaderInfoItemTitle}>Owner</div>
              <Tooltip title={accountProposalData?.broker?.email}>
                <div className={classes.ProposalHeaderInfoItemSubTitle}>
                  {accountProposalData?.broker?.display_name}
                </div>
              </Tooltip>
            </div>
            <div className={classes.ProposalHeaderInfoItem}>
              <div className={classes.ProposalHeaderInfoItemTitle}>Company Name</div>
              <div className={classes.ProposalHeaderInfoItemSubTitle}>
                {accountData?.company_name}
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
};
