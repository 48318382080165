import _ from 'lodash';
import { currencyFormatter } from 'src/constants/currency';
import {
  normalizeCompensationAmount,
  normalizeDisabilityPlanType,
  normalizeDisabilityRateBasis,
  normalizeLtdBenefitPeriod,
  normalizeRateTierType,
  normalizeStdBenefitPeriod,
} from 'src/constants/requestProposal';
import { capitalize } from '../other';

import {
  COVERAGE_ALL_AGES_NAMES,
  coverageAllAges,
} from 'src/features/proposal/coverageAgeBandedRates/coverageAllAges';
import {
  COVERAGE_FIVE_YEAR_NAMES,
  coverageFiveYearAges,
} from 'src/features/proposal/coverageAgeBandedRates/coverageFiveYearAges';
import {
  COVERAGE_TEN_YEAR_NAMES,
  coverageTenYearAges,
} from 'src/features/proposal/coverageAgeBandedRates/coverageTenYearAges';
import { prepareCustomFields } from 'src/utils/prepareCustomFields';

export const normalizeDisabilityExport = (
  ExcelJSWorkbook,
  disabilityQuotes,
  groupMembers,
  type,
) => {
  let disabilityCounter = 1;
  for (const quote in disabilityQuotes) {
    const disabilityQuoteWorkSheet = ExcelJSWorkbook.addWorksheet(
      type === 'all'
        ? `Disability RFP Quote ${disabilityCounter++}`
        : `Sold Disability Quote ${disabilityCounter++}`,
      {
        properties: { defaultColWidth: 40 },
      },
    );

    const preparedCustomFields = prepareCustomFields(disabilityQuotes[quote]);

    const isAllAgesRate = !_.isEmpty(
      disabilityQuotes[quote].find((item) => item?.rate_tier_type === 'all_ages'),
    );
    const isFiveAgesRate = !_.isEmpty(
      disabilityQuotes[quote].find((item) => item?.rate_tier_type === 'banded_5'),
    );
    const isTenAgesRate = !_.isEmpty(
      disabilityQuotes[quote].find((item) => item?.rate_tier_type === 'banded_10'),
    );

    const isLtdQuote = !_.isEmpty(disabilityQuotes[quote].find((item) => item.plan_type === 'ltd'));
    const isStdQuote = !_.isEmpty(disabilityQuotes[quote].find((item) => item.plan_type === 'std'));
    const isCurrentRateRow = !_.isEmpty(
      disabilityQuotes[quote].find((item) => item.is_current_rate),
    );

    let offerRow = [''];
    let carrierNameRow = ['Carrier'];
    let planTypeRow = ['Plan Type'];
    let companyContributionRow = ['Company Contribution'];
    let pctOfIncomeRequestRow = ['% of Income Requested'];
    let ltdMonthlyMax = ['LTD Monthly Max'];
    let ltdElimPeriod = ['LTD Elimination Period'];
    let ltdBenPeriod = ['LTD Benefit Period'];
    let stdWeeklyMax = ['STD Weekly Max'];
    let stdElimPeriod = ['STD Elimination Period (Accident/Sickness)'];
    let stdBenPeriod = ['STD Benefit Period'];
    let compMethodRow = ['Compensation Method'];
    let compAmountRow = ['Compensation Amount'];
    let currentlyOfferedRow = ['Currently Offered Benefit'];
    let perUnitRow = ['$ Per Unit'];
    let rateBasis = ['Rate Basis'];

    for (const disabilityQuote of disabilityQuotes[quote]) {
      if (disabilityQuote.issuer_name === 'broker') {
        offerRow.push('Current Offer');
      } else {
        offerRow.push('Carrier Offer');
      }
    }

    let quoteRows = [
      offerRow,
      carrierNameRow,
      planTypeRow,
      companyContributionRow,
      pctOfIncomeRequestRow,
    ];

    let rateTierTypeRow = ['Rate Tier Type'];
    let eeRow = ['Employee'];
    // let rateRows = [rateTierTypeRow, eeRow];

    if (isLtdQuote) {
      quoteRows = [...quoteRows, ltdMonthlyMax, ltdElimPeriod, ltdBenPeriod];
    }
    if (isStdQuote) {
      quoteRows = [...quoteRows, stdWeeklyMax, stdElimPeriod, stdBenPeriod];
    }

    quoteRows = [...quoteRows, compMethodRow, compAmountRow, currentlyOfferedRow, []];

    // if (isLtdQuote && isStdQuote) {
    //   quoteRows = [
    //     ...quoteRows,
    //     stdWeeklyMax,
    //     stdElimPeriod,
    //     stdBenPeriod,
    //     ltdMonthlyMax,
    //     ltdElimPeriod,
    //     ltdBenPeriod,
    //     compMethodRow,
    //     compAmountRow,
    //     currentlyOfferedRow,
    //     [],
    //   ];
    // }

    if (isCurrentRateRow) {
      quoteRows = [...quoteRows, perUnitRow, rateBasis, []];
    }

    for (const disabilityQuote of disabilityQuotes[quote]) {
      carrierNameRow.push(capitalize(disabilityQuote.issuer_name));
      planTypeRow.push(normalizeDisabilityPlanType(disabilityQuote.plan_type));
      companyContributionRow.push(capitalize(disabilityQuote.contribution_type));
      pctOfIncomeRequestRow.push(`${disabilityQuote.income_pct}%`);

      ltdMonthlyMax.push(
        disabilityQuote?.ltd_monthly_max ? `$${disabilityQuote?.ltd_monthly_max}` : '',
      );
      ltdElimPeriod.push(
        disabilityQuote.ltd_elimination_period
          ? `${disabilityQuote.ltd_elimination_period} Days`
          : '',
      );
      ltdBenPeriod.push(
        disabilityQuote.ltd_benefit_period
          ? normalizeLtdBenefitPeriod(disabilityQuote.ltd_benefit_period)
          : '',
      );

      stdWeeklyMax.push(
        disabilityQuote?.std_weekly_max ? `$${disabilityQuote?.std_weekly_max}` : '',
      );
      stdElimPeriod.push(
        disabilityQuote.std_elimination_period ? disabilityQuote.std_elimination_period : '',
      );
      stdBenPeriod.push(
        disabilityQuote.std_benefit_period
          ? normalizeStdBenefitPeriod(disabilityQuote.std_benefit_period)
          : '',
      );

      compMethodRow.push(capitalize(disabilityQuote.comp_method));
      compAmountRow.push(normalizeCompensationAmount(disabilityQuote));

      currentlyOfferedRow.push(disabilityQuote.is_current_rate ? 'Yes' : 'No');

      if (!disabilityQuote.is_current_rate) {
        perUnitRow.push('');
        rateBasis.push('');
      }

      if (disabilityQuote.is_current_rate) {
        perUnitRow.push(disabilityQuote.rate_per_unit);
        rateBasis.push(normalizeDisabilityRateBasis(disabilityQuote.rate_basis));
      }

      rateTierTypeRow.push(normalizeRateTierType(disabilityQuote.rate_tier_type));
      eeRow.push(currencyFormatter(disabilityQuote.rate_ee));
    }

    for (const row of quoteRows) {
      if (row.find((item) => item === 'Carrier')) {
        disabilityQuoteWorkSheet.addRow(row).font = { bold: true };
      } else {
        disabilityQuoteWorkSheet.addRow(row).getCell(1).font = { bold: true };
      }
    }

    const customFields = ['Custom Fields'];
    disabilityQuoteWorkSheet.addRow(customFields).getCell(1).font = { bold: true };

    let customRows = [];
    for (const disabilityQuote of preparedCustomFields) {
      for (let [index, field] of disabilityQuote?.custom_fields.entries()) {
        if (!customRows[index]) {
          customRows[index] = [field.title, field.value];
        } else {
          customRows[index].push(field.value || '');
        }
      }
    }

    customRows.push([]);

    for (const row of customRows) {
      disabilityQuoteWorkSheet.addRow(row).font = { bold: true };
    }

    disabilityQuoteWorkSheet.addRow(rateTierTypeRow).getCell(1).font = { bold: true };

    let rateRows = [];

    for (let i = 1; i <= 81; i++) {
      rateRows.push(['']);
    }

    for (const disabilityQuote of disabilityQuotes[quote]) {
      let ageBandedRates = {};

      if (
        disabilityQuote.rate_tier_type === 'all_ages' ||
        disabilityQuote.rate_tier_type === 'banded_5' ||
        disabilityQuote.rate_tier_type === 'banded_10'
      ) {
        for (const item of Object.keys(disabilityQuote)) {
          if (item.includes('age')) {
            ageBandedRates = {
              ...ageBandedRates,
              [item]: disabilityQuote[item],
            };
          }
        }
      }

      let allAgesRatesPayload = coverageAllAges(ageBandedRates);
      let fiveYearsRatesPayload = coverageFiveYearAges(ageBandedRates);
      let tenYearRatesPayload = coverageTenYearAges(ageBandedRates);

      if (disabilityQuote.rate_tier_type === 'employee') {
        rateRows[0].push(`${currencyFormatter(disabilityQuote.rate_ee) || '--'} (Employee)`);
        if (isAllAgesRate) {
          // 66 - because range for all ages is from 0 to 14
          for (let i = 1; i <= 66; i++) {
            rateRows[i]?.push('');
          }
        } else {
          if (isFiveAgesRate && !isTenAgesRate) {
            for (let i = 1; i <= 13; i++) {
              rateRows[i]?.push('');
            }
          }

          if (!isFiveAgesRate && isTenAgesRate) {
            for (let i = 1; i <= 7; i++) {
              rateRows[i]?.push('');
            }
          }

          if (isFiveAgesRate && isTenAgesRate) {
            for (let i = 1; i <= 13; i++) {
              rateRows[i]?.push('');
            }
          }
        }
      }

      if (disabilityQuote?.rate_tier_type === 'all_ages') {
        rateRows[0].push(
          `${currencyFormatter(allAgesRatesPayload.age_0_14) || '--'} (${
            COVERAGE_ALL_AGES_NAMES.age_0_14
          })`,
        );
        for (let i = 1; i < 66; i++) {
          rateRows[i].push(
            `${currencyFormatter(allAgesRatesPayload[`age_${i + 14}`]) || '--'} (${
              COVERAGE_ALL_AGES_NAMES[`age_${i + 14}`]
            })`,
          );
        }
      }

      if (disabilityQuote?.rate_tier_type === 'banded_5') {
        rateRows[0].push(
          `${currencyFormatter(fiveYearsRatesPayload.age_0_17) || '--'} (${
            COVERAGE_FIVE_YEAR_NAMES.age_0_17
          })`,
        );
        rateRows[1].push(
          `${currencyFormatter(fiveYearsRatesPayload.age_18_24) || '--'} (${
            COVERAGE_FIVE_YEAR_NAMES.age_18_24
          })`,
        );
        rateRows[2].push(
          `${currencyFormatter(fiveYearsRatesPayload.age_25_29) || '--'} (${
            COVERAGE_FIVE_YEAR_NAMES.age_25_29
          })`,
        );
        rateRows[3].push(
          `${currencyFormatter(fiveYearsRatesPayload.age_30_34) || '--'} (${
            COVERAGE_FIVE_YEAR_NAMES.age_30_34
          })`,
        );
        rateRows[4].push(
          `${currencyFormatter(fiveYearsRatesPayload.age_35_39) || '--'} (${
            COVERAGE_FIVE_YEAR_NAMES.age_35_39
          })`,
        );
        rateRows[5].push(
          `${currencyFormatter(fiveYearsRatesPayload.age_40_44) || '--'} (${
            COVERAGE_FIVE_YEAR_NAMES.age_40_44
          })`,
        );
        rateRows[6].push(
          `${currencyFormatter(fiveYearsRatesPayload.age_45_49) || '--'} (${
            COVERAGE_FIVE_YEAR_NAMES.age_45_49
          })`,
        );
        rateRows[7].push(
          `${currencyFormatter(fiveYearsRatesPayload.age_50_54) || '--'} (${
            COVERAGE_FIVE_YEAR_NAMES.age_50_54
          })`,
        );
        rateRows[8].push(
          `${currencyFormatter(fiveYearsRatesPayload.age_55_59) || '--'} (${
            COVERAGE_FIVE_YEAR_NAMES.age_55_59
          })`,
        );
        rateRows[9].push(
          `${currencyFormatter(fiveYearsRatesPayload.age_60_64) || '--'} (${
            COVERAGE_FIVE_YEAR_NAMES.age_60_64
          })`,
        );
        rateRows[10].push(
          `${currencyFormatter(fiveYearsRatesPayload.age_65_69) || '--'} (${
            COVERAGE_FIVE_YEAR_NAMES.age_65_69
          })`,
        );
        rateRows[11].push(
          `${currencyFormatter(fiveYearsRatesPayload.age_70_74) || '--'} (${
            COVERAGE_FIVE_YEAR_NAMES.age_70_74
          })`,
        );
        rateRows[12].push(
          `${currencyFormatter(fiveYearsRatesPayload.age_75_79) || '--'} (${
            COVERAGE_FIVE_YEAR_NAMES.age_75_79
          })`,
        );
        rateRows[13].push(
          `${currencyFormatter(fiveYearsRatesPayload.age_80) || '--'} (${
            COVERAGE_FIVE_YEAR_NAMES.age_80
          })`,
        );

        if (isAllAgesRate) {
          for (let i = 14; i <= 66; i++) {
            rateRows[i]?.push('');
          }
        }
      }

      if (disabilityQuote?.rate_tier_type === 'banded_10') {
        rateRows[0].push(
          `${currencyFormatter(tenYearRatesPayload.age_0_17) || '--'} (${
            COVERAGE_TEN_YEAR_NAMES.age_0_17
          })`,
        );
        rateRows[1].push(
          `${currencyFormatter(tenYearRatesPayload.age_18_29) || '--'} (${
            COVERAGE_TEN_YEAR_NAMES.age_18_29
          })`,
        );
        rateRows[2].push(
          `${currencyFormatter(tenYearRatesPayload.age_30_39) || '--'} (${
            COVERAGE_TEN_YEAR_NAMES.age_30_39
          })`,
        );
        rateRows[3].push(
          `${currencyFormatter(tenYearRatesPayload.age_40_49) || '--'} (${
            COVERAGE_TEN_YEAR_NAMES.age_40_49
          })`,
        );
        rateRows[4].push(
          `${currencyFormatter(tenYearRatesPayload.age_50_59) || '--'} (${
            COVERAGE_TEN_YEAR_NAMES.age_50_59
          })`,
        );
        rateRows[5].push(
          `${currencyFormatter(tenYearRatesPayload.age_60_69) || '--'} (${
            COVERAGE_TEN_YEAR_NAMES.age_60_69
          })`,
        );
        rateRows[6].push(
          `${currencyFormatter(tenYearRatesPayload.age_70_79) || '--'} (${
            COVERAGE_TEN_YEAR_NAMES.age_70_79
          })`,
        );
        rateRows[7].push(
          `${currencyFormatter(tenYearRatesPayload.age_80) || '--'} (${
            COVERAGE_TEN_YEAR_NAMES.age_80
          })`,
        );
        if (isAllAgesRate) {
          for (let i = 8; i <= 66; i++) {
            rateRows[i]?.push('');
          }
        } else {
          if (isFiveAgesRate) {
            for (let i = 8; i <= 13; i++) {
              rateRows[i]?.push('');
            }
          }
        }
      }
    }

    for (const row of [...rateRows.filter((e) => e.length !== 1), []]) {
      disabilityQuoteWorkSheet.addRow(row).getCell(1).font = { bold: true };
    }

    const totalRows = ['Total'];

    for (const disabilityQuote of disabilityQuotes[quote]) {
      totalRows.push(currencyFormatter(disabilityQuote?.calculation?.total));
    }

    const totalRowStyle = disabilityQuoteWorkSheet.addRow(totalRows);
    totalRowStyle.eachCell(
      (cell) =>
        (cell.fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: {
            argb: 'ffff00',
          },
        }),
    );
    totalRowStyle.font = { bold: true };

    for (let rowIndex = 2; rowIndex <= disabilityQuoteWorkSheet.rowCount; rowIndex++) {
      disabilityQuoteWorkSheet.getRow(rowIndex).alignment = { horizontal: 'left', wrapText: true };
    }
  }
};
