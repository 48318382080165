import _ from 'lodash';

import {
  annualMaxOptions,
  basicOptions,
  booleanOptions,
  compMethodOptions,
  deductibleOptions,
  dentalPlanTypeOptions,
  fundingTypeOptions,
  majorOptions,
  networkCoverageOptions,
  orthodonticCoverageOptions,
  orthodonticOptions,
  preventativeOptions,
} from 'src/constants/coverage';
import { currencyFormatter } from 'src/constants/currency';
import { normalizeFundingType } from 'src/constants/proposalFormField';

export const useDentalIssuerBuild = () => {
  const formattedValue = (value) => {
    return value ? currencyFormatter(value).replace('.00', '') : '';
  };

  const build = {
    coverage: 'dental',
    title: 'Dental',
    form_options: {
      contribution: true,
    },
    card_options: {
      plan: [
        {
          title: 'Type',
          key: 'plan_type',
        },
        {
          title: 'Orthodontics',
          key: 'orthodontics',
        },
        {
          title: 'Implant Coverage',
          key: 'implant_coverage',
          format: (value) => (value ? 'Yes' : 'No'),
        },
        {
          title: 'Annual Max',
          key: 'dppo_annual_max',
          format: (value) => (value ? value : 'No Max'),
        },
        {
          title: 'Contribution',
          key: 'contribution',
          format: () => 'Employer',
        },
        {
          title: 'Current Benefit',
          key: 'is_current_rate',
          format: (value) => (value ? 'Yes' : 'No'),
        },
      ],
    },
    prepare_payload: (offer) => {
      if (offer?.plan_type === 'HMO') {
        offer.dppo_annual_max = null;
        offer.dppo_basic = null;
        offer.dppo_deductible = null;
        offer.dppo_major = null;
        offer.dppo_out_coverage = null;
        offer.dppo_preventative = null;
      }

      if (offer?.orthodontics === 'none') {
        offer.orthodontics_coverage = null;
      }

      return offer;
    },
    offer_details: (offers, selectedOffer) => {
      const ppo = offers?.find((item) => item?.plan_type === 'PPO');
      const orthodontics = offers?.find((item) => item?.orthodontics !== 'none');

      const isPpo = !_.isEmpty(ppo) || selectedOffer?.plan_type === 'PPO';
      const isOrtho =
        !_.isEmpty(orthodontics) || ['child', 'adult_child'].includes(selectedOffer?.orthodontics);

      return [
        {
          key: 'funding_type',
          format: (value) => normalizeFundingType(value),
          options: { render_type: 'select', render_data: fundingTypeOptions },
        },
        {
          key: 'waiting_period',
          format: (value) => (value ? 'Yes' : 'No'),
          options: { render_type: 'select', render_data: booleanOptions },
        },
        {
          key: 'plan_type',
          render: (value) =>
            isPpo
              ? [
                  {
                    key: 'dppo_out_coverage',
                    options: {
                      render_type: 'select',
                      render_data: networkCoverageOptions,
                      isVisible: selectedOffer?.plan_type === 'PPO',
                    },
                    format: (value) => value,
                  },
                  {
                    key: 'dppo_annual_max',
                    options: {
                      render_type: 'select',
                      render_data: annualMaxOptions,
                      type: 'other',
                      symbol: '$',
                      inputType: 'number',
                      minValue: 0,
                      isVisible: selectedOffer?.plan_type === 'PPO',
                    },
                    format: (value) => formattedValue(value),
                  },
                  {
                    key: 'dppo_deductible',
                    options: {
                      render_type: 'select',
                      render_data: deductibleOptions,
                      isVisible: selectedOffer?.plan_type === 'PPO',
                    },
                    format: (value) => formattedValue(value),
                  },
                  {
                    key: 'dppo_preventative',
                    options: {
                      render_type: 'select',
                      render_data: preventativeOptions,
                      isVisible: selectedOffer?.plan_type === 'PPO',
                    },
                    format: (value) => (value ? `${value}%` : ''),
                  },
                  {
                    key: 'dppo_basic',
                    options: {
                      render_type: 'select',
                      render_data: basicOptions,
                      isVisible: selectedOffer?.plan_type === 'PPO',
                    },
                    format: (value) => (value ? `${value}%` : ''),
                  },
                  {
                    key: 'dppo_major',
                    options: {
                      render_type: 'select',
                      render_data: majorOptions,
                      isVisible: selectedOffer?.plan_type === 'PPO',
                    },
                    format: (value) => (value ? `${value}%` : ''),
                  },
                ]
              : [],
          options: { render_type: 'select', render_data: dentalPlanTypeOptions },
        },
        {
          key: 'orthodontics',
          options: { render_type: 'select', render_data: orthodonticOptions },
          render: (value) =>
            isOrtho
              ? [
                  {
                    key: 'orthodontics_coverage',
                    options: {
                      render_type: 'select',
                      render_data: orthodonticCoverageOptions,
                      isVisible: selectedOffer?.orthodontics !== 'none',
                    },
                    format: (value) => formattedValue(value),
                  },
                ]
              : [],
        },
        {
          key: 'implant_coverage',
          format: (value) => (value ? 'Yes' : 'No'),
          options: { render_type: 'select', render_data: booleanOptions },
        },
        {
          key: 'comp_method',
          options: { render_type: 'select', render_data: compMethodOptions },
        },
      ];
    },
  };

  return { build };
};
