import _ from 'lodash';

import { currencyFormatter } from 'src/constants/currency';

import {
  normalizeCompensationAmount,
  normalizeDeathBenefit,
  normalizeDisabilityRateBasis,
  normalizeRateTierType,
} from 'src/constants/requestProposal';
import { COVERAGE_ALL_AGES_NAMES } from 'src/features/proposal/coverageAgeBandedRates/coverageAllAges';
import { COVERAGE_FIVE_YEAR_NAMES } from 'src/features/proposal/coverageAgeBandedRates/coverageFiveYearAges';
import { COVERAGE_TEN_YEAR_NAMES } from 'src/features/proposal/coverageAgeBandedRates/coverageTenYearAges';

import {
  lifeNonTobaccoAllAges,
  lifeTobaccoAllAges,
  lifeUniAllAges,
} from 'src/features/life/lifeAgeBandedRates/lifeAllAges';
import {
  lifeNonTobaccoFiveYearAges,
  lifeTobaccoFiveYearAges,
  lifeUniFiveYearAges,
} from 'src/features/life/lifeAgeBandedRates/lifeFiveYearAges';
import {
  lifeNonTobaccoTenYearAges,
  lifeTobaccoTenYearAges,
  lifeUniTenYearAges,
} from 'src/features/life/lifeAgeBandedRates/lifeTenYearAges';

import { capitalize } from '../other';
import { prepareCustomFields } from '../../prepareCustomFields';

export const normalizeLifeExport = (ExcelJSWorkbook, lifeQuotes, groupMembers, type) => {
  let lifeCounter = 1;
  for (const quote in lifeQuotes) {
    const lifeQuoteWorkSheet = ExcelJSWorkbook.addWorksheet(
      type === 'all' ? `Life RFP Quote ${lifeCounter++}` : `Sold Life Quote ${lifeCounter++}`,
      {
        properties: { defaultColWidth: 40 },
      },
    );

    const preparedCustomFields = prepareCustomFields(lifeQuotes[quote]);

    const isCurrentRateRow = !_.isEmpty(lifeQuotes[quote].find((item) => item.is_current_rate));

    let offerRow = [''];
    let carrierNameRow = ['Carrier'];
    let employerContributionRow = ['Employer Contribution'];
    let deathBenefitRow = ['Death Benefit'];
    let compMethodRow = ['Compensation Method'];
    let compAmountRow = ['Compensation Amount'];
    let currentlyOfferedRow = ['Currently Offered Benefit'];
    let perUnitRow = ['$ Per Unit'];
    let rateBasis = ['Rate Basis'];

    let rateTierTypeRow = ['Rate Tier Type'];
    let tobaccoRow = ['Rate Type'];

    let quoteRows = [
      offerRow,
      carrierNameRow,
      employerContributionRow,
      deathBenefitRow,
      compMethodRow,
      compAmountRow,
      currentlyOfferedRow,
    ];

    for (const lifeQuote of lifeQuotes[quote]) {
      if (lifeQuote.issuer_name === 'broker') {
        offerRow.push('Current Offer');
      } else {
        offerRow.push('Carrier Offer');
      }
    }

    if (isCurrentRateRow) {
      quoteRows = [...quoteRows, perUnitRow, rateBasis, []];
    }

    quoteRows = [...quoteRows, tobaccoRow, []];

    for (const lifeQuote of lifeQuotes[quote]) {
      carrierNameRow.push(capitalize(lifeQuote.issuer_name));
      employerContributionRow.push(capitalize(lifeQuote.contribution_type));
      deathBenefitRow.push(normalizeDeathBenefit(lifeQuote.death_benefit));
      compMethodRow.push(capitalize(lifeQuote.comp_method));
      compAmountRow.push(normalizeCompensationAmount(lifeQuote));
      currentlyOfferedRow.push(lifeQuote.is_current_rate ? 'Yes' : 'No');

      rateTierTypeRow.push(normalizeRateTierType(lifeQuote.rate_tier_type));

      if (!lifeQuote.is_current_rate) {
        perUnitRow.push('');
        rateBasis.push('');
      }

      if (lifeQuote.is_current_rate) {
        perUnitRow.push(currencyFormatter(lifeQuote.rate_per_unit));
        rateBasis.push(normalizeDisabilityRateBasis(lifeQuote.rate_basis));
      }

      if (!lifeQuote.is_uni_tobacco) {
        tobaccoRow.push('Tobacco / Non-Tobacco');
      }

      if (lifeQuote.is_uni_tobacco) {
        tobaccoRow.push('Uni-Tobacco');
      }
    }

    for (const row of quoteRows) {
      if (row.find((item) => item === 'Carrier')) {
        lifeQuoteWorkSheet.addRow(row).font = { bold: true };
      } else {
        lifeQuoteWorkSheet.addRow(row).getCell(1).font = { bold: true };
      }
    }

    const customFields = ['Custom Fields'];
    lifeQuoteWorkSheet.addRow(customFields).getCell(1).font = { bold: true };

    let customRows = [];
    for (const lifeQuote of preparedCustomFields) {
      for (let [index, field] of lifeQuote?.custom_fields.entries()) {
        if (!customRows[index]) {
          customRows[index] = [field.title, field.value];
        } else {
          customRows[index].push(field.value || '');
        }
      }
    }

    customRows.push([]);

    for (const row of customRows) {
      lifeQuoteWorkSheet.addRow(row).font = { bold: true };
    }

    lifeQuoteWorkSheet.addRow(rateTierTypeRow).getCell(1).font = { bold: true };

    let rateRows = [];

    for (let i = 1; i <= 81; i++) {
      rateRows.push(['']);
    }

    for (const lifeQuote of lifeQuotes[quote]) {
      const offerAllAgesRates = lifeUniAllAges(lifeQuote);
      const offerFiveBandedRates = lifeUniFiveYearAges(lifeQuote);
      const offerTenBandedRates = lifeUniTenYearAges(lifeQuote);

      const offerTobaccoAllAgeRates = lifeTobaccoAllAges(lifeQuote);
      const offerNonTobaccoAllAgeRates = lifeNonTobaccoAllAges(lifeQuote);

      const offerTobaccoFiveBandedRates = lifeTobaccoFiveYearAges(lifeQuote);
      const offerNonTobaccoFiveBandedRates = lifeNonTobaccoFiveYearAges(lifeQuote);
      const offerTobaccoTenBandedRates = lifeTobaccoTenYearAges(lifeQuote);
      const offerNonTobaccoTenBandedRates = lifeNonTobaccoTenYearAges(lifeQuote);

      if (!lifeQuote.is_uni_tobacco) {
        if (lifeQuote.rate_tier_type === '4tier') {
          rateRows[0].push(
            lifeQuote.rate_ee_non_tobacco && lifeQuote.rate_ee_tobacco
              ? `${currencyFormatter(lifeQuote.rate_ee_tobacco)} / ${currencyFormatter(
                  lifeQuote.rate_ee_non_tobacco,
                )} (Employee)`
              : '',
          );
          rateRows[1].push(
            lifeQuote.rate_sp_non_tobacco && lifeQuote.rate_sp_tobacco
              ? `${currencyFormatter(lifeQuote.rate_sp_tobacco)} / ${currencyFormatter(
                  lifeQuote.rate_sp_non_tobacco,
                )} (Spouse)`
              : '',
          );
          rateRows[2].push(
            lifeQuote.rate_ch_non_tobacco && lifeQuote.rate_ch_tobacco
              ? `${currencyFormatter(lifeQuote.rate_ch_tobacco)} / ${currencyFormatter(
                  lifeQuote.rate_ch_non_tobacco,
                )} (Child)`
              : '',
          );
          rateRows[3].push(
            lifeQuote.rate_fam_non_tobacco && lifeQuote.rate_fam_tobacco
              ? `${currencyFormatter(lifeQuote.rate_fam_tobacco)} / ${currencyFormatter(
                  lifeQuote.rate_fam_non_tobacco,
                )} (Family)`
              : '',
          );
          for (let i = 4; i <= 66; i++) {
            rateRows[i]?.push('');
          }
        }
        if (lifeQuote.rate_tier_type === 'all_ages') {
          rateRows[0].push(
            `${currencyFormatter(offerTobaccoAllAgeRates.age_0_14_tobacco) || '--'} / ${
              currencyFormatter(offerNonTobaccoAllAgeRates.age_0_14_non_tobacco) || '--'
            } (${COVERAGE_ALL_AGES_NAMES.age_0_14})`,
          );
          for (let i = 15; i <= 80; i++) {
            rateRows[i - 14].push(
              `${currencyFormatter(offerTobaccoAllAgeRates[`age_${i}_tobacco`]) || '--'} / ${
                currencyFormatter(offerNonTobaccoAllAgeRates[`age_${i}_non_tobacco`]) || '--'
              } (${COVERAGE_ALL_AGES_NAMES[`age_${i}`]})`,
            );
          }
        }
        if (lifeQuote.rate_tier_type === 'banded_5') {
          rateRows[0].push(
            `${currencyFormatter(offerTobaccoFiveBandedRates.age_0_17_tobacco) || '--'} / ${
              currencyFormatter(offerNonTobaccoFiveBandedRates.age_0_17_non_tobacco) || '--'
            } (${COVERAGE_FIVE_YEAR_NAMES.age_0_17})`,
          );
          rateRows[1].push(
            `${currencyFormatter(offerTobaccoFiveBandedRates.age_18_24_tobacco) || '--'} / ${
              currencyFormatter(offerNonTobaccoFiveBandedRates.age_18_24_non_tobacco) || '--'
            } (${COVERAGE_FIVE_YEAR_NAMES.age_18_24})`,
          );
          rateRows[2].push(
            `${currencyFormatter(offerTobaccoFiveBandedRates.age_25_29_tobacco) || '--'} / ${
              currencyFormatter(offerNonTobaccoFiveBandedRates.age_25_29_non_tobacco) || '--'
            } (${COVERAGE_FIVE_YEAR_NAMES.age_25_29})`,
          );
          rateRows[3].push(
            `${currencyFormatter(offerTobaccoFiveBandedRates.age_30_34_tobacco) || '--'} / ${
              currencyFormatter(offerNonTobaccoFiveBandedRates.age_30_34_non_tobacco) || '--'
            } (${COVERAGE_FIVE_YEAR_NAMES.age_30_34})`,
          );
          rateRows[4].push(
            `${currencyFormatter(offerTobaccoFiveBandedRates.age_35_39_tobacco) || '--'} / ${
              currencyFormatter(offerNonTobaccoFiveBandedRates.age_35_39_non_tobacco) || '--'
            } (${COVERAGE_FIVE_YEAR_NAMES.age_35_39})`,
          );
          rateRows[5].push(
            `${currencyFormatter(offerTobaccoFiveBandedRates.age_40_44_tobacco) || '--'} / ${
              currencyFormatter(offerNonTobaccoFiveBandedRates.age_40_44_non_tobacco) || '--'
            } (${COVERAGE_FIVE_YEAR_NAMES.age_40_44})`,
          );
          rateRows[6].push(
            `${currencyFormatter(offerTobaccoFiveBandedRates.age_45_49_tobacco) || '--'} / ${
              currencyFormatter(offerNonTobaccoFiveBandedRates.age_45_49_non_tobacco) || '--'
            } (${COVERAGE_FIVE_YEAR_NAMES.age_45_49})`,
          );
          rateRows[7].push(
            `${currencyFormatter(offerTobaccoFiveBandedRates.age_50_54_tobacco) || '--'} / ${
              currencyFormatter(offerNonTobaccoFiveBandedRates.age_50_54_non_tobacco) || '--'
            } (${COVERAGE_FIVE_YEAR_NAMES.age_50_54})`,
          );
          rateRows[8].push(
            `${currencyFormatter(offerTobaccoFiveBandedRates.age_55_59_tobacco) || '--'} / ${
              currencyFormatter(offerNonTobaccoFiveBandedRates.age_55_59_non_tobacco) || '--'
            } (${COVERAGE_FIVE_YEAR_NAMES.age_55_59})`,
          );
          rateRows[9].push(
            `${currencyFormatter(offerTobaccoFiveBandedRates.age_60_64_tobacco) || '--'} / ${
              currencyFormatter(offerNonTobaccoFiveBandedRates.age_60_64_non_tobacco) || '--'
            } (${COVERAGE_FIVE_YEAR_NAMES.age_60_64})`,
          );
          rateRows[10].push(
            `${currencyFormatter(offerTobaccoFiveBandedRates.age_65_69_tobacco) || '--'} / ${
              currencyFormatter(offerNonTobaccoFiveBandedRates.age_65_69_non_tobacco) || '--'
            } (${COVERAGE_FIVE_YEAR_NAMES.age_65_69})`,
          );
          rateRows[11].push(
            `${currencyFormatter(offerTobaccoFiveBandedRates.age_70_74_tobacco) || '--'} / ${
              currencyFormatter(offerNonTobaccoFiveBandedRates.age_70_74_non_tobacco) || '--'
            } (${COVERAGE_FIVE_YEAR_NAMES.age_70_74})`,
          );
          rateRows[12].push(
            `${currencyFormatter(offerTobaccoFiveBandedRates.age_75_79_tobacco) || '--'} / ${
              currencyFormatter(offerNonTobaccoFiveBandedRates.age_75_79_non_tobacco) || '--'
            } (${COVERAGE_FIVE_YEAR_NAMES.age_75_79})`,
          );
          rateRows[13].push(
            `${currencyFormatter(offerTobaccoFiveBandedRates.age_80_tobacco) || '--'} / ${
              currencyFormatter(offerNonTobaccoFiveBandedRates.age_80_non_tobacco) || '--'
            } (${COVERAGE_FIVE_YEAR_NAMES.age_80})`,
          );
          for (let i = 14; i <= 66; i++) {
            rateRows[i]?.push('');
          }
        }
        if (lifeQuote.rate_tier_type === 'banded_10') {
          rateRows[0].push(
            `${currencyFormatter(offerTobaccoTenBandedRates.age_0_17_tobacco) || '--'} / ${
              currencyFormatter(offerNonTobaccoTenBandedRates.age_0_17_non_tobacco) || '--'
            } (${COVERAGE_TEN_YEAR_NAMES.age_0_17})`,
          );
          rateRows[1].push(
            `${currencyFormatter(offerTobaccoTenBandedRates.age_18_29_tobacco) || '--'} / ${
              currencyFormatter(offerNonTobaccoTenBandedRates.age_18_29_non_tobacco) || '--'
            } (${COVERAGE_TEN_YEAR_NAMES.age_18_29})`,
          );
          rateRows[2].push(
            `${currencyFormatter(offerTobaccoTenBandedRates.age_30_39_tobacco) || '--'} / ${
              currencyFormatter(offerNonTobaccoTenBandedRates.age_30_39_non_tobacco) || '--'
            } (${COVERAGE_TEN_YEAR_NAMES.age_30_39})`,
          );
          rateRows[3].push(
            `${currencyFormatter(offerTobaccoTenBandedRates.age_40_49_tobacco) || '--'} / ${
              currencyFormatter(offerNonTobaccoTenBandedRates.age_40_49_non_tobacco) || '--'
            } (${COVERAGE_TEN_YEAR_NAMES.age_40_49})`,
          );
          rateRows[4].push(
            `${currencyFormatter(offerTobaccoTenBandedRates.age_50_59_tobacco) || '--'} / ${
              currencyFormatter(offerNonTobaccoTenBandedRates.age_50_59_non_tobacco) || '--'
            } (${COVERAGE_TEN_YEAR_NAMES.age_50_59})`,
          );
          rateRows[5].push(
            `${currencyFormatter(offerTobaccoTenBandedRates.age_60_69_tobacco) || '--'} / ${
              currencyFormatter(offerNonTobaccoTenBandedRates.age_60_69_non_tobacco) || '--'
            } (${COVERAGE_TEN_YEAR_NAMES.age_60_69})`,
          );
          rateRows[6].push(
            `${currencyFormatter(offerTobaccoTenBandedRates.age_70_79_tobacco) || '--'} / ${
              currencyFormatter(offerNonTobaccoTenBandedRates.age_70_79_non_tobacco) || '--'
            } (${COVERAGE_TEN_YEAR_NAMES.age_70_79})`,
          );
          rateRows[7].push(
            `${currencyFormatter(offerTobaccoTenBandedRates.age_80_tobacco) || '--'} / ${
              currencyFormatter(offerNonTobaccoTenBandedRates.age_80_non_tobacco) || '--'
            } (${COVERAGE_TEN_YEAR_NAMES.age_80})`,
          );
        }
        for (let i = 8; i <= 66; i++) {
          rateRows[i]?.push('');
        }
      }

      if (lifeQuote.is_uni_tobacco) {
        tobaccoRow.push('Uni-Tobacco');
        if (lifeQuote.rate_tier_type === '4tier') {
          rateRows[0].push(
            lifeQuote.rate_ee_uni_tobacco
              ? `${currencyFormatter(lifeQuote?.rate_ee_uni_tobacco)} (Employee)`
              : '',
          );
          rateRows[1].push(
            lifeQuote.rate_sp_uni_tobacco
              ? `${currencyFormatter(lifeQuote?.rate_sp_uni_tobacco)} (Spouse)`
              : '',
          );
          rateRows[2].push(
            lifeQuote.rate_ch_uni_tobacco
              ? `${currencyFormatter(lifeQuote?.rate_ch_uni_tobacco)} (Child)`
              : '',
          );
          rateRows[3].push(
            lifeQuote.rate_fam_uni_tobacco
              ? `${currencyFormatter(lifeQuote?.rate_fam_uni_tobacco)} (Family)`
              : '',
          );
          for (let i = 4; i <= 66; i++) {
            rateRows[i]?.push('');
          }
        }
        if (lifeQuote.rate_tier_type === 'all_ages') {
          rateRows[0].push(
            `${currencyFormatter(offerAllAgesRates.age_0_14_uni_tobacco) || '--'}} (${
              COVERAGE_ALL_AGES_NAMES.age_0_14
            })`,
          );
          for (let i = 15; i <= 80; i++) {
            rateRows[i - 14].push(
              `${currencyFormatter(offerAllAgesRates[`age_${i}_uni_tobacco`]) || '--'} (${
                COVERAGE_ALL_AGES_NAMES[`age_${i}`]
              })`,
            );
          }
        }
        if (lifeQuote.rate_tier_type === 'banded_5') {
          rateRows[0].push(
            `${currencyFormatter(offerFiveBandedRates.age_0_17_uni_tobacco) || '--'} (${
              COVERAGE_FIVE_YEAR_NAMES.age_0_17
            })`,
          );
          rateRows[1].push(
            `${currencyFormatter(offerFiveBandedRates.age_18_24_uni_tobacco) || '--'} (${
              COVERAGE_FIVE_YEAR_NAMES.age_18_24
            })`,
          );
          rateRows[2].push(
            `${currencyFormatter(offerFiveBandedRates.age_25_29_uni_tobacco) || '--'} (${
              COVERAGE_FIVE_YEAR_NAMES.age_25_29
            })`,
          );
          rateRows[3].push(
            `${currencyFormatter(offerFiveBandedRates.age_30_34_uni_tobacco) || '--'} (${
              COVERAGE_FIVE_YEAR_NAMES.age_30_34
            })`,
          );
          rateRows[4].push(
            `${currencyFormatter(offerFiveBandedRates.age_35_39_uni_tobacco) || '--'} (${
              COVERAGE_FIVE_YEAR_NAMES.age_35_39
            })`,
          );
          rateRows[5].push(
            `${currencyFormatter(offerFiveBandedRates.age_40_44_uni_tobacco) || '--'} (${
              COVERAGE_FIVE_YEAR_NAMES.age_40_44
            })`,
          );
          rateRows[6].push(
            `${currencyFormatter(offerFiveBandedRates.age_45_49_uni_tobacco) || '--'} (${
              COVERAGE_FIVE_YEAR_NAMES.age_45_49
            })`,
          );
          rateRows[7].push(
            `${currencyFormatter(offerFiveBandedRates.age_50_54_uni_tobacco) || '--'} (${
              COVERAGE_FIVE_YEAR_NAMES.age_50_54
            })`,
          );
          rateRows[8].push(
            `${currencyFormatter(offerFiveBandedRates.age_55_59_uni_tobacco) || '--'} (${
              COVERAGE_FIVE_YEAR_NAMES.age_55_59
            })`,
          );
          rateRows[9].push(
            `${currencyFormatter(offerFiveBandedRates.age_60_64_uni_tobacco) || '--'} (${
              COVERAGE_FIVE_YEAR_NAMES.age_60_64
            })`,
          );
          rateRows[10].push(
            `${currencyFormatter(offerFiveBandedRates.age_65_69_uni_tobacco) || '--'} (${
              COVERAGE_FIVE_YEAR_NAMES.age_65_69
            })`,
          );
          rateRows[11].push(
            `${currencyFormatter(offerFiveBandedRates.age_70_74_uni_tobacco) || '--'} (${
              COVERAGE_FIVE_YEAR_NAMES.age_70_74
            })`,
          );
          rateRows[12].push(
            `${currencyFormatter(offerFiveBandedRates.age_75_79_uni_tobacco) || '--'} (${
              COVERAGE_FIVE_YEAR_NAMES.age_75_79
            })`,
          );
          rateRows[13].push(
            `${currencyFormatter(offerFiveBandedRates.age_80_uni_tobacco) || '--'} (${
              COVERAGE_FIVE_YEAR_NAMES.age_80
            })`,
          );
          for (let i = 14; i <= 66; i++) {
            rateRows[i]?.push('');
          }
        }
        if (lifeQuote.rate_tier_type === 'banded_10') {
          rateRows[0].push(
            `${currencyFormatter(offerTenBandedRates.age_0_17_uni_tobacco) || '--'} (${
              COVERAGE_TEN_YEAR_NAMES.age_0_17
            })`,
          );
          rateRows[1].push(
            `${currencyFormatter(offerTenBandedRates.age_18_29_uni_tobacco) || '--'} (${
              COVERAGE_TEN_YEAR_NAMES.age_18_29
            })`,
          );
          rateRows[2].push(
            `${currencyFormatter(offerTenBandedRates.age_30_39_uni_tobacco) || '--'} (${
              COVERAGE_TEN_YEAR_NAMES.age_30_39
            })`,
          );
          rateRows[3].push(
            `${currencyFormatter(offerTenBandedRates.age_40_49_uni_tobacco) || '--'} (${
              COVERAGE_TEN_YEAR_NAMES.age_40_49
            })`,
          );
          rateRows[4].push(
            `${currencyFormatter(offerTenBandedRates.age_50_59_uni_tobacco) || '--'} (${
              COVERAGE_TEN_YEAR_NAMES.age_50_59
            })`,
          );
          rateRows[5].push(
            `${currencyFormatter(offerTenBandedRates.age_60_69_uni_tobacco) || '--'} (${
              COVERAGE_TEN_YEAR_NAMES.age_60_69
            })`,
          );
          rateRows[6].push(
            `${currencyFormatter(offerTenBandedRates.age_70_79_uni_tobacco) || '--'} (${
              COVERAGE_TEN_YEAR_NAMES.age_70_79
            })`,
          );
          rateRows[7].push(
            `${currencyFormatter(offerTenBandedRates.age_80_uni_tobacco) || '--'} (${
              COVERAGE_TEN_YEAR_NAMES.age_80
            })`,
          );
          for (let i = 8; i <= 66; i++) {
            rateRows[i]?.push('');
          }
        }
      }
    }

    for (const row of [...rateRows.filter((e) => e.find((item) => item))]) {
      lifeQuoteWorkSheet.addRow(row).getCell(1).font = { bold: true };
    }

    let totalRows = ['Total'];

    for (const lifeQuote of lifeQuotes[quote]) {
      totalRows.push(currencyFormatter(lifeQuote?.calculation?.totalTobacco));
    }

    const totalRowStyle = lifeQuoteWorkSheet.addRow(totalRows);
    totalRowStyle.eachCell(
      (cell) =>
        (cell.fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: {
            argb: 'ffff00',
          },
        }),
    );
    totalRowStyle.font = { bold: true };

    for (let rowIndex = 2; rowIndex <= lifeQuoteWorkSheet.rowCount; rowIndex++) {
      lifeQuoteWorkSheet.getRow(rowIndex).alignment = { horizontal: 'left', wrapText: true };
    }
  }
};
