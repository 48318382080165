import { worksheetNameRegex } from 'src/constants/regularExpression';

export const normalizeDecisionQuoteExport = (ExcelJSWorkbook, groupQuotes, groupMembers) => {
  const totalEmployees = groupMembers?.filter((item) => item.relationship === 'employee')?.length;

  for (const quote of groupQuotes) {
    const quoteWorkSheet = ExcelJSWorkbook.addWorksheet(
      quote.name.length > 25
        ? quote.name.slice(0, 25).replace(worksheetNameRegex, '')
        : quote.name.replace(worksheetNameRegex, ''),
      {
        properties: { defaultColWidth: 40 },
      },
    );

    quoteWorkSheet.addRow(['Quote Name', quote?.name]).font = { bold: true };
    quoteWorkSheet.addRow(['Type', quote?.quote_type]).getCell(1).font = { bold: true };
    quoteWorkSheet.addRow(['Effective Date', quote?.effective_date]).getCell(1).font = {
      bold: true,
    };

    quoteWorkSheet.addRow(['Contribution Source', quote?.contribution_source]).getCell(1).font = {
      bold: true,
    };

    quoteWorkSheet.addRow([]);

    let corporate_dashboard = ['Corporate dashboard'];
    let personal_finance_scorecard = ['Personal finance scorecard'];
    let embedded_or_standalone = ['Embedded or standalone'];
    let portal_for_financial_health_journey = ['Portal for financial health journey'];
    let admin_portal_for_customization = ['Admin portal for customization'];
    let plan_types = ['Plan types'];
    let contribution_type = ['Contribution type'];
    let total_out_of_pocket = ['Total out of pocket'];
    let coverage_details = ['Coverage details'];
    let preventative_care = ['Preventative care'];
    let out_of_pocket_coverage = ['Out of pocket coverage'];
    let dependent_coverage = ['Dependent coverage'];
    let data_insights = ['Data insights'];
    let behavioral_data_insights = ['Behavioral data insights'];
    let predictive_analytics = ['Predictive analytics'];
    let personalized_financial_scoring = ['Personalized financial scoring'];
    let aggregate_financial_stability_scoring = ['Aggregate financial stability scoring'];
    let hipaa_compliance = ['HIPAA compliance'];
    let data_security = ['Data security'];
    let gdpr_compliance = ['GDPR compliance'];
    let onboarding_process = ['Onboarding process'];
    let customer_service = ['Customer service'];
    let compliance_and_regulatory = ['Compliance and regulatory'];
    let third_party_integration = ['Third party integration'];
    let estimated_enrollment_life_in_participation = ['Estimated enrollment life in participation'];
    let pricing = ['Pricing'];
    let total = ['Total'];

    let proposalRows = [
      corporate_dashboard,
      personal_finance_scorecard,
      embedded_or_standalone,
      portal_for_financial_health_journey,
      admin_portal_for_customization,
      plan_types,
      contribution_type,
      total_out_of_pocket,
      coverage_details,
      preventative_care,
      out_of_pocket_coverage,
      dependent_coverage,
      data_insights,
      behavioral_data_insights,
      predictive_analytics,
      personalized_financial_scoring,
      aggregate_financial_stability_scoring,
      hipaa_compliance,
      data_security,
      gdpr_compliance,
      onboarding_process,
      customer_service,
      compliance_and_regulatory,
      third_party_integration,
      estimated_enrollment_life_in_participation,
      pricing,
      total,
    ];

    for (const plan of quote?.plans) {
      corporate_dashboard.push(plan.corporate_dashboard);
      personal_finance_scorecard.push(plan.personal_finance_scorecard);
      embedded_or_standalone.push(plan.embedded_or_standalone);
      portal_for_financial_health_journey.push(plan.portal_for_financial_health_journey);
      admin_portal_for_customization.push(plan.admin_portal_for_customization);
      plan_types.push(plan.plan_types);
      contribution_type.push(plan.contribution_type);
      total_out_of_pocket.push(plan.total_out_of_pocket);
      coverage_details.push(plan.coverage_details);
      preventative_care.push(plan.preventative_care);
      out_of_pocket_coverage.push(plan.out_of_pocket_coverage);
      dependent_coverage.push(plan.dependent_coverage);
      data_insights.push(plan.data_insights);
      behavioral_data_insights.push(plan.behavioral_data_insights);
      predictive_analytics.push(plan.predictive_analytics);
      personalized_financial_scoring.push(plan.personalized_financial_scoring);
      aggregate_financial_stability_scoring.push(plan.aggregate_financial_stability_scoring);
      hipaa_compliance.push(plan.hipaa_compliance);
      data_security.push(plan.data_security);
      gdpr_compliance.push(plan.gdpr_compliance);
      onboarding_process.push(plan.onboarding_process);
      customer_service.push(plan.customer_service);
      compliance_and_regulatory.push(plan.compliance_and_regulatory);
      third_party_integration.push(plan.third_party_integration);
      estimated_enrollment_life_in_participation.push(
        plan.estimated_enrollment_life_in_participation,
      );
      pricing.push(
        plan.cost_unit === 'dollar'
          ? `$ ${plan.cost} ${plan.cost_type}`
          : `${plan.cost}% of new comission sold`,
      );
      total.push(
        plan.cost_unit === 'dollar'
          ? `$ ${plan.cost * totalEmployees}`
          : `${plan.cost}% of new comission sold`,
      );
    }

    for (const row of proposalRows) {
      if (row[0] === 'Plan Name' || row[0] === 'Carrier') {
        quoteWorkSheet.addRow(row).font = { bold: true };
        continue;
      }
      quoteWorkSheet.addRow(row).getCell(1).font = { bold: true };
    }

    for (let columnIndex = 2; columnIndex <= quoteWorkSheet.columnCount; columnIndex++) {
      quoteWorkSheet.getColumn(columnIndex).numFmt =
        '[$$-1] #,##########0.00;[$$-1][Red]-#,##########0.00';
      quoteWorkSheet.getColumn(columnIndex).width = 40;
      quoteWorkSheet.getColumn(columnIndex).textAlign = 'left';
    }

    for (let rowIndex = 1; rowIndex <= quoteWorkSheet.rowCount; rowIndex++) {
      quoteWorkSheet.getRow(rowIndex).alignment = {
        vertical: 'top',
        horizontal: 'left',
        wrapText: true,
      };
    }
  }
};
