import { Page, Text, View } from '@react-pdf/renderer';

import { sumPremium } from 'src/store/proposals';

import { currencyFormatter } from 'src/constants/currency';

import { renderTableRow, renderTableValue } from './renderRow';

import {
  calculateEmployeeTotal,
  calculateEmployerTotal,
  calculatePlanTotal,
} from 'src/utils/exportXls/medical/normalizeMedicalQuoteExport';

import { styles } from '../styles';

export const renderMedicalQuotesPage = (groupQuotes, groupMembers) => {
  return groupQuotes?.map((quote) => {
    const selectedBasePlanQuote = quote?.plans.find((item) => item.id === quote?.base_plan_id);
    const selectedPayer = quote.contribution_source;
    const updatedQuotes = sumPremium(
      quote?.plans,
      groupMembers,
      quote,
      null,
      selectedBasePlanQuote,
    );

    const updatedData = updatedQuotes.reduce((acc, value, index, array) => {
      if (index % 2 === 0) {
        acc.push(array.slice(index, index + 2));
      }
      return acc;
    }, []);

    return updatedData?.map((section, index) => {
      return (
        <Page size="A4" style={styles.page} key={index}>
          <Text style={styles.title}>{quote.name}</Text>
          <View style={styles.table}>
            {renderTableRow('Carrier', section, 'carrier_name')}
            <View style={styles.tableRow}>
              <View style={styles.tableCol}>
                <Text style={[styles.tableCell, styles.tableTitle]}>Contribution EE</Text>
              </View>
              {section?.map((item, index) => {
                return (
                  <View
                    style={[
                      styles.tableCol,
                      index === section.length - 1 ? styles.lastTableCol : {},
                    ]}
                    key={item.id}
                  >
                    <Text style={styles.tableCell}>
                      {quote?.contribution_type === 'dollar' ? '$' : ''}
                      {quote.contribution_ee}
                      {quote?.contribution_type === 'percent' ? '%' : ''}
                    </Text>
                  </View>
                );
              })}
            </View>
            <View style={styles.tableRow}>
              <View style={styles.tableCol}>
                <Text style={[styles.tableCell, styles.tableTitle]}>Contribution DEP</Text>
              </View>
              {section?.map((item, index) => {
                return (
                  <View
                    style={[
                      styles.tableCol,
                      index === section.length - 1 ? styles.lastTableCol : {},
                    ]}
                    key={item.id}
                  >
                    <Text style={styles.tableCell}>
                      {quote?.contribution_type === 'dollar' ? '$' : ''}
                      {quote.contribution_dep}
                      {quote?.contribution_type === 'percent' ? '%' : ''}
                    </Text>
                  </View>
                );
              })}
            </View>
            {renderTableRow('Plan Name', section, 'display_name')}
            {renderTableRow('Plan Type', section, 'plan_type')}
            {renderTableRow('Network Size', section, 'network_size')}
            {renderTableRow('Tier', section, 'level')}
            {renderTableRow(
              'Primary Care Physician Copay',
              section,
              'primary_care_physician',
              true,
            )}
            {renderTableRow('Specialist Copay', section, 'specialist')}
            {renderTableRow(
              'Individual Deductible',
              section,
              'individual_medical_deductible',
              true,
            )}
            {renderTableRow('Family Deductible', section, 'family_medical_deductible', true)}
            {renderTableRow('Inpatient Facility', section, 'inpatient_facility', true)}
            {renderTableRow('Coinsurance', section, 'plan_coinsurance', false)}
            {renderTableRow('Max Out-of-Pocket', section, 'individual_medical_moop', true)}
            {renderTableRow('Emergency Room', section, 'emergency_room')}
            {renderTableRow('Urgent Care', section, 'urgent_care')}
            {renderTableRow('Benefits Summary', section, 'benefits_summary_url')}

            <View style={styles.tableRow}>
              <View style={styles.tableCol}>
                <Text style={[styles.tableCell, styles.tableTitle]}>
                  {selectedPayer === 'employee' ? 'Employee' : 'Employer'}
                </Text>
              </View>
              {section?.map((item, index) => {
                const renderEmployeeTotal = () => {
                  if (selectedPayer === 'employee') {
                    if (quote.quote_type === 'advanced') {
                      return renderTableValue(
                        item,
                        index,
                        section,
                        calculateEmployeeTotal(item, quote, groupMembers, selectedBasePlanQuote),
                      );
                    }

                    if (quote.quote_type === 'standard') {
                      return renderTableValue(
                        item,
                        index,
                        section,
                        currencyFormatter(item?.employee_cost),
                      );
                    }
                  }

                  if (selectedPayer === 'employer') {
                    if (quote.quote_type === 'advanced') {
                      return renderTableValue(
                        item,
                        index,
                        section,
                        calculateEmployerTotal(item, quote, groupMembers, selectedBasePlanQuote),
                      );
                    }

                    if (quote.quote_type === 'standard') {
                      return renderTableValue(
                        item,
                        index,
                        section,
                        currencyFormatter(item?.employer_cost),
                      );
                    }
                  }
                };

                return renderEmployeeTotal();
              })}
            </View>
            <View style={styles.tableRow}>
              <View style={styles.tableCol}>
                <Text style={[styles.tableCell, styles.tableTitle]}>
                  {selectedPayer === 'employee' ? 'Employer' : 'Employee'}
                </Text>
              </View>
              {section?.map((item, index) => {
                const renderEmployeeTotal = () => {
                  if (selectedPayer === 'employee') {
                    if (quote.quote_type === 'advanced') {
                      return renderTableValue(
                        item,
                        index,
                        section,
                        calculateEmployerTotal(item, quote, groupMembers, selectedBasePlanQuote),
                      );
                    }

                    if (quote.quote_type === 'standard') {
                      return renderTableValue(
                        item,
                        index,
                        section,
                        currencyFormatter(item?.employer_cost),
                      );
                    }
                  }

                  if (selectedPayer === 'employer') {
                    if (quote.quote_type === 'advanced') {
                      return renderTableValue(
                        item,
                        index,
                        section,
                        calculateEmployeeTotal(item, quote, groupMembers, selectedBasePlanQuote),
                      );
                    }

                    if (quote.quote_type === 'standard') {
                      return renderTableValue(
                        item,
                        index,
                        section,
                        currencyFormatter(item?.employee_cost),
                      );
                    }
                  }
                };

                return renderEmployeeTotal();
              })}
            </View>
            <View style={styles.tableRow}>
              <View style={styles.tableCol}>
                <Text style={[styles.tableCell, styles.tableTitle]}>
                  {selectedPayer === 'employee' ? 'Employer' : 'Employee'}
                </Text>
              </View>
              {section?.map((item, index) => {
                const renderTotal = () => {
                  if (quote.quote_type === 'advanced') {
                    return renderTableValue(
                      item,
                      index,
                      section,
                      calculatePlanTotal(item, quote, groupMembers, selectedBasePlanQuote),
                    );
                  }

                  if (quote.quote_type === 'standard') {
                    return renderTableValue(
                      item,
                      index,
                      section,
                      currencyFormatter(item?.total_premium),
                    );
                  }
                };

                return renderTotal();
              })}
            </View>
          </View>
        </Page>
      );
    });
  });
};
