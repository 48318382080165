import ExcelJs from 'exceljs';
import saveAs from 'file-saver';

import { capitalize, computeHousehold4TierType, urlToImageFile } from '../other';

import { normalizeAccountExport, normalizeExecutiveExport } from '../proposal';

import { normalizeMedicalQuoteExport } from './normalizeMedicalQuoteExport';
import { normalizeMedicalRatesExport } from './normalizeMedicalRatesExport';
import { normalizeMedicalExport } from './normalizeMedicalExport';
import { normalizeDentalExport } from '../dental';
import { normalizeVisionExport } from '../vision';
import { normalizeLifeExport } from '../life';
import { normalizeDisabilityExport } from '../disability';
import { normalizeAccidentExport } from '../accident';
import { normalizeHospitalExport } from '../hospital';
import { normalizeCriticalExport } from '../critical';

export const onDownloadSoldQuotesXlsx = async (
  accountData,
  brokerData,
  proposalData,
  rfpQuotes,
  executiveSummary,
) => {
  const groupMembers = proposalData?.groups[0].members;
  const groupQuotes = proposalData?.groups[0].quotes;
  const medicalQuotes = rfpQuotes?.medical;
  const dentalQuotes = rfpQuotes?.dental;
  const visionQuotes = rfpQuotes?.vision;
  const lifeQuotes = rfpQuotes?.life;
  const disabilityQuotes = rfpQuotes?.disability;
  const accidentQuotes = rfpQuotes?.accident;
  const hospitalQuotes = rfpQuotes?.hospital;
  const criticalQuotes = rfpQuotes?.critical;

  const quoteNames = {
    medicalQuotes,
    dentalQuotes,
    visionQuotes,
    lifeQuotes,
    disabilityQuotes,
    accidentQuotes,
    hospitalQuotes,
    criticalQuotes,
  };

  let summaryQuoteNames = {};

  for (const quoteName in quoteNames) {
    const updatedName = capitalize(quoteName.replace('Quotes', ''));

    let counter = 1;
    for (const quoteId in quoteNames[quoteName]) {
      summaryQuoteNames = {
        ...summaryQuoteNames,
        [quoteId]: `Sold ${updatedName} Quote ${counter++}`,
      };
    }
  }

  const ExcelJSWorkbook = new ExcelJs.Workbook();
  const accountWorkSheet = ExcelJSWorkbook.addWorksheet('Account', {
    properties: { defaultColWidth: 40 },
  });
  const groupMembersWorkSheet = ExcelJSWorkbook.addWorksheet('Group Members', {
    properties: { defaultColWidth: 20 },
  });

  normalizeAccountExport(accountWorkSheet, accountData, brokerData);

  const image_file = await urlToImageFile(accountData.logo_url);
  const image_id = ExcelJSWorkbook.addImage({
    base64: image_file,
    extension: 'jpeg',
  });

  accountWorkSheet.spliceRows(1, 0, []);

  accountWorkSheet.addImage(image_id, {
    tl: { col: 0, row: 0 },
    ext: { width: 128, height: 128 },
  });

  accountWorkSheet.getRow(1).height = 128;

  let householdRoles = {};

  for (const member of groupMembers) {
    if (householdRoles[member.family_id]) {
      householdRoles[member.family_id].push(member.relationship);
    } else {
      householdRoles[member.family_id] = [member.relationship];
    }
  }
  const household4TierTypes = {};

  for (const eid in householdRoles) {
    const roles = householdRoles[eid];
    household4TierTypes[eid] = computeHousehold4TierType(roles);
  }

  groupMembersWorkSheet.addRow([
    'Employee ID',
    'First Name',
    'Last Name',
    'Gender',
    'Relationship',
    'Zip Code',
    'Date of Birth',
    'Medical enrollment',
    'Current Medical Plan',
    'Dental enrollment',
    'Current Dental',
    'Vision enrollment',
    'Current Vision',
    'Current Life',
    'Tobacco',
    'Salary',
    'Job',
  ]).font = { bold: true };

  for (const member of groupMembers) {
    const payload = [
      member?.family_id,
      member?.first_name,
      member?.last_name,
      member?.gender,
      member?.relationship,
      member?.zip_code,
      member?.date_of_birth,
      member?.relationship === 'employee' && member?.current_medical_plan_name
        ? household4TierTypes[member?.family_id]?.type
        : '',
      member?.current_medical_plan_name,
      member?.relationship === 'employee' && member?.dental_plan_name
        ? household4TierTypes[member?.family_id]?.type
        : '',
      member?.dental_plan_name,
      member?.relationship === 'employee' && member?.vision_plan_name
        ? household4TierTypes[member?.family_id]?.type
        : '',
      member?.vision_plan_name,
      member?.life_benefit_amount,
      member?.is_tobacco_user ? 'yes' : 'no',
      member?.salary_amount,
      member?.job_title,
    ];

    groupMembersWorkSheet.addRow(payload);
  }

  normalizeExecutiveExport(
    ExcelJSWorkbook,
    executiveSummary,
    groupMembers,
    summaryQuoteNames,
    'Sold Quotes',
    'selected',
  );

  normalizeMedicalQuoteExport(ExcelJSWorkbook, groupQuotes, groupMembers);
  normalizeMedicalRatesExport(ExcelJSWorkbook, groupQuotes, groupMembers);
  normalizeMedicalExport(ExcelJSWorkbook, medicalQuotes, groupMembers, 'sold');
  normalizeDentalExport(ExcelJSWorkbook, dentalQuotes, groupMembers, 'sold');
  normalizeVisionExport(ExcelJSWorkbook, visionQuotes, groupMembers, 'sold');
  normalizeLifeExport(ExcelJSWorkbook, lifeQuotes, groupMembers, 'sold');
  normalizeDisabilityExport(ExcelJSWorkbook, disabilityQuotes, groupMembers, 'sold');
  normalizeAccidentExport(ExcelJSWorkbook, accidentQuotes, groupMembers, 'sold');
  normalizeHospitalExport(ExcelJSWorkbook, hospitalQuotes, groupMembers, 'sold');
  normalizeCriticalExport(ExcelJSWorkbook, criticalQuotes, groupMembers, 'sold');

  for (let rowIndex = 1; rowIndex <= accountWorkSheet.rowCount; rowIndex++) {
    accountWorkSheet.getRow(rowIndex).alignment = { vertical: 'top', wrapText: true };
  }

  for (let rowIndex = 1; rowIndex <= groupMembersWorkSheet.rowCount; rowIndex++) {
    groupMembersWorkSheet.getRow(rowIndex).alignment = { vertical: 'top', wrapText: true };
  }

  ExcelJSWorkbook.xlsx.writeBuffer().then((buffer) => {
    saveAs(
      new Blob([buffer], { type: 'application/octet-stream' }),
      `${accountData?.company_name}-${proposalData?.name}-Sold Quotes.xlsx`,
    );
  });
};
